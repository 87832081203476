import { db } from "../firebase-config";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
  orderBy,
  startAt,
  endAt,
  addDoc,
  updateDoc,
} from "firebase/firestore";
import { geohashQueryBounds, distanceBetween } from "geofire-common";
import { CustomerType } from "../types/CommonTypes/CommonTypes.types";

const customersColRef = collection(db, "Customers");

const getCustomerByLocation = (
  clientId: string,
  coords: any,
  radiusInMeter: number
) => {
  const center: any = coords;
  const bounds = geohashQueryBounds(center, radiusInMeter);

  const promises = bounds.map(async function (geohashes) {
    return await getDocs(
      query(
        customersColRef,
        orderBy("location.geohash"),
        startAt(geohashes[0]),
        endAt(geohashes[1])
      )
    );
  });
  return Promise.all(promises)
    .then(function (querySnapshots) {
      const customerDocs: any = [];
      querySnapshots.forEach(function (res, index) {
        const result = res.docs.map((doc: any) => {
          const { location } = doc.data();
          const dist = distanceBetween(
            [location.geopoint._lat, location.geopoint._long],
            center
          );
          if (dist <= radiusInMeter / 1000) {
            customerDocs.push({ ...doc.data(), id: doc.id });
          }
        });
      });
      return customerDocs;
    })
    .then((customerDocs: any) => {
      return customerDocs.length > 0
        ? customerDocs.find((customer: any) => {
            return customer.clientId === clientId;
          })
        : null;
    });
};

const getCustomers = (clientId: string) => {
  const q = query(customersColRef, where("clientId", "==", clientId));
  return getDocs(q).then((querySnapShot) => {
    return querySnapShot.docs.map((item) => {
      return { ...item.data(), id: item.id };
    });
  });
};

const getCustomerDetails = async (customerId: string) => {
  const customerDocRef = doc(db, "Customers", customerId);
  return getDoc(customerDocRef).then((snapShot) => {
    if (snapShot.exists()) {
      return {
        ...snapShot.data(),
        id: snapShot.id,
      } as CustomerType;
    }
    return null;
  });
};

const addCustomer = async (data: CustomerType) => {
  return await addDoc(customersColRef, data);
};

const updateCustomerDetails = async (customerId: string, data: any) => {
  const customerDocRef = doc(db, "Customers", customerId);
  return await updateDoc(customerDocRef, data);
};

export {
  getCustomerByLocation,
  getCustomers,
  getCustomerDetails,
  addCustomer,
  updateCustomerDetails,
};
