import React from "react";
import SlideButton from "react-slide-button";
import "./slidingButton.scss";

function SlidingButton({
  mainTextElelment,
  overlayTextElement,
  onCompleteSlide,
}) {
  return (
    <SlideButton
      mainText={mainTextElelment}
      overlayText={overlayTextElement}
      classList="sliding-btn"
      caretClassList="sliding-btn__caret"
      overlayClassList="sliding-btn__overlay"
      onSlideDone={onCompleteSlide}
    />
  );
}

export default SlidingButton;
