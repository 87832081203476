import React from "react";
import "./customerDetails.scss";
import { Text, PrimaryButton } from "@fluentui/react";
import ScreenHeader from "../../../Components/ScreenHeader/ScreenHeader";
import { CustomerType } from "../../../types/CommonTypes/CommonTypes.types";
import { useNavigate } from "react-router-dom";
import { join } from "path";

export default function CustomerDetails({
  customer,
}: {
  customer: CustomerType;
}) {
  const navigate = useNavigate();
  const handleEditBtnClick = () => {
    navigate(`/customers/${customer?.id}/edit`);
  };

  return (
    <div className="customer-details">
      <ScreenHeader title="CUSTOMER DETAILS" />
      <section className="customer-details-section">
        <div className="customer-details-field-container">
          <Text variant="mediumPlus">Company Name :</Text>
          <Text variant="medium" className="field-value">
            {customer?.companyName}
          </Text>
        </div>
        <div className="customer-details-field-container">
          <Text variant="mediumPlus">Contact Name :</Text>
          <Text variant="medium" className="field-value">
            {customer?.contactName}
          </Text>
        </div>
        <div className="customer-details-field-container">
          <Text variant="mediumPlus">Contact Number :</Text>
          <Text variant="medium" className="field-value">
            {customer?.contactNumber}
          </Text>
        </div>
        <div className="customer-details-field-container">
          <Text variant="mediumPlus">Contact Email :</Text>
          <Text variant="medium" className="field-value">
            {customer?.contactEmail.join(", ")}
          </Text>
        </div>
        <div className="customer-details-field-container">
          <Text variant="mediumPlus">Company Address :</Text>
          <Text variant="medium" className="field-value">
            {customer?.address}
          </Text>
        </div>
        <div className="customer-details-field-container">
          <Text variant="mediumPlus">Company Geopoint :</Text>
          <div className="geopoint-details-container">
            <div className="lat-long-container" style={{ marginRight: "8px" }}>
              <Text variant="mediumPlus">Lat :</Text>
              <Text variant="medium" className="field-value">
                {customer?.location?.geopoint?.latitude}
              </Text>
            </div>
            <div className="lat-long-container">
              <Text variant="mediumPlus">Long :</Text>
              <Text variant="medium" className="field-value">
                {customer?.location?.geopoint?.longitude}
              </Text>
            </div>
          </div>
        </div>
      </section>
      <section className="action-button-section">
        <PrimaryButton
          iconProps={{ iconName: "Edit" }}
          styles={{
            textContainer: {
              marginLeft: "5px",
              flexGrow: "auto",
            },
          }}
          onClick={handleEditBtnClick}
        >
          Edit
        </PrimaryButton>
      </section>
    </div>
  );
}
