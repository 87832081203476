import React, { useState, useEffect, useContext } from "react";
import "./securityGuardForm.scss";
import ScreenHeader from "../../../Components/ScreenHeader/ScreenHeader";
import {
  Text,
  TextField,
  PrimaryButton,
  Spinner,
  Toggle,
} from "@fluentui/react";
import { UserContext } from "../../../context/UserContextProvider";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import {
  getUser,
  updateUserDetails,
  checkSecurityGuardIsActive,
} from "../../../services/userService";
import { UserType } from "../../../types/CommonTypes/CommonTypes.types";
import {
  createInvite,
  getRecentInvitesByPhoneNumber,
} from "../../../services/inviteService";
import { serverTimestamp } from "firebase/firestore";

export default function SecurityGuardForm({
  setSelectedTab,
}: {
  setSelectedTab?: any;
}) {
  const { securityGuardId } = useParams();
  const routeLocation = useLocation();
  const navigate = useNavigate();
  const isEditSecurityRoute = routeLocation.pathname.endsWith("/edit");
  const [editMode, setEditMode] = useState(false);
  const { user, isLoggedIn, loadingUser } = useContext(UserContext);
  const [loadingSecurityData, setLoadingSecurityData] = useState(false);
  const [ShowEditOrInviteLoader, setShowEditOrInviteLoader] = useState(false);
  const [securityGuardExistsError, setSecurityGuardExistsError] = useState("");
  const [textToDisplay, setTextToDisplay] = useState("");
  const phoneNumberRegex = /^[0-9]{10}$/g;
  const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  const [securityGuardOldData, setSecurityGuardOldData] = useState(
    {} as UserType
  );
  const [securityGuardData, setSecurityGuardData] = useState({
    name: "",
    phoneNumber: "",
    email: "",
    isActive: true,
  });

  const [securityGuardDataError, setSecurityGuardDataError] = useState({
    name: "",
    phoneNumber: "",
    email: "",
  });

  useEffect(() => {
    if (!loadingUser) {
      if (user && isLoggedIn && user?.role?.includes("admin")) {
        setTextToDisplay("");
        if (securityGuardId) {
          getSecurityGuardDetails(securityGuardId);
        }
      } else {
        setTextToDisplay("This page can be accessed only by admin");
      }
    }
  }, [user, isLoggedIn, loadingUser, securityGuardId]);

  const getSecurityGuardDetails = async (securityGuardId: string) => {
    setLoadingSecurityData(true);
    getUser(securityGuardId)
      .then((res: any) => {
        if (res?.role?.includes("security_guard")) {
          setEditMode(true);
          setSecurityGuardOldData(res);
          setSecurityGuardData({
            name: res?.name,
            email: res?.email,
            phoneNumber: res?.phoneNumber?.substring(3),
            isActive: res?.isActive,
          });
        } else {
          setTextToDisplay("Security guard doesn't exists");
          setEditMode(false);
        }
      })
      .finally(() => {
        setLoadingSecurityData(false);
      });
  };

  const handleNameInputChange = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string | undefined
  ) => {
    const { name } = event.target as HTMLInputElement;
    const data: any = { ...securityGuardData };
    const error: any = { ...securityGuardDataError };

    data.name = newValue as string;
    error.name = "";

    setSecurityGuardData({ ...data });
    setSecurityGuardDataError({ ...error });

    if (newValue) {
      if (newValue.length > 50) {
        error.name = "Maximum 50 characters allowed";
        setSecurityGuardDataError({ ...error });
      }
      const res = /^([A-Za-z\s])*$/g.test(newValue as string);
      if (!res) {
        error[name] = "Only alphabets and spaces are allowed";
        setSecurityGuardDataError({ ...error });
      }
    }
  };
  const handlePhoneNumberChange = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string | undefined
  ) => {
    setSecurityGuardExistsError("");
    setSecurityGuardDataError({
      ...securityGuardDataError,
      phoneNumber: "",
    });
    setSecurityGuardData({
      ...securityGuardData,
      phoneNumber: newValue as string,
    });

    const { length } = newValue as string;

    if (length === 0) return;

    if (newValue && newValue.length >= 10) {
      const res = phoneNumberRegex.test(newValue);
      if (!res) {
        setSecurityGuardDataError({
          ...securityGuardDataError,
          phoneNumber: "Enter valid phone number",
        });
      }
      return;
    }
    const res = /^([0-9]*)$/g.test(newValue as string);
    if (
      !res ||
      newValue?.includes("+") ||
      newValue?.includes("e") ||
      newValue?.includes("E")
    ) {
      setSecurityGuardDataError({
        ...securityGuardDataError,
        phoneNumber: "Enter valid phone number",
      });
    }
  };
  const handleEmailChange = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string | undefined
  ) => {
    const data: any = { ...securityGuardData };
    const error: any = { ...securityGuardDataError };
    error.email = "";
    data.email = newValue as string;
    setSecurityGuardDataError({
      ...error,
    });
    setSecurityGuardData({
      ...data,
    });
    if (newValue) {
      if (newValue.length > 50) {
        error.email = "Maximum 50 characters allowed";
        setSecurityGuardDataError({ ...error });
      }
    }
  };

  const handleSecurityGuardStatusChange = (
    ev: React.MouseEvent<HTMLElement>,
    checked?: boolean
  ) => {
    setSecurityGuardData({
      ...securityGuardData,
      isActive: checked as boolean,
    });
  };

  const isThereErrorInData = (securityDataError: any) => {
    const securityError: any = securityDataError;

    const res1 = Object.keys(securityError).every((key) => !securityError[key]);

    return res1;
  };

  const isSecurityDataValid = () => {
    const { securityErrorLocal } = validateSecurityGuardData();
    const res = isThereErrorInData(securityErrorLocal);
    return res;
  };
  const validateSecurityGuardData = () => {
    const securityDataLocal = { ...securityGuardData } as any;
    const securityErrorLocal = { ...securityGuardDataError } as any;

    const mandatorySecurityGuardFields = Object.keys(securityDataLocal);

    mandatorySecurityGuardFields
      .filter((field) => field !== "email" && field !== "isActive")
      .forEach((field: any) => {
        securityDataLocal[field] = securityDataLocal[field].trim();

        if (securityDataLocal[field] === "") {
          securityErrorLocal[field] = "This field is required";
          return;
        }

        if (field === "phoneNumber") {
          const res = phoneNumberRegex.test(securityDataLocal.phoneNumber);
          if (!res) {
            securityErrorLocal.phoneNumber = "Enter valid phone number";
          }
        }
      });

    if (securityDataLocal?.email !== "") {
      securityDataLocal.email = securityDataLocal.email.trim();
      const res = emailRegex.test(securityDataLocal?.email);
      if (!res) {
        securityErrorLocal.email = "Enter valid email";
      }
    }

    setSecurityGuardData({ ...securityDataLocal });
    setSecurityGuardDataError({ ...securityErrorLocal });

    return {
      securityErrorLocal,
    };
  };

  const handleUpdateSecurityGuardDetails = async () => {
    const data = {
      ...securityGuardOldData,
      name: securityGuardData?.name.trim(),
      email: securityGuardData?.email.trim(),
      isActive: securityGuardData?.isActive,
    };
    delete data.id;

    try {
      await updateUserDetails(securityGuardOldData?.id as string, data);
    } finally {
      setShowEditOrInviteLoader(false);
      navigate("/security-guards/manage");
    }
  };
  const handleInviteSecurityGuard = async () => {
    const data = {
      name: securityGuardData?.name.trim(),
      email: securityGuardData?.email.trim(),
      invitedDate: serverTimestamp(),
      phoneNumber: "+91".concat(securityGuardData?.phoneNumber),
      clientId: user?.clientId,
      role: ["security_guard"],
    } as any;
    delete data.isActive;

    const response = await checkSecurityGuardIsActive(
      "+91".concat(securityGuardData?.phoneNumber)
    );

    if (response) {
      setSecurityGuardExistsError(
        "Security guard already exists, or may be linked with other client, need to deactivate this account before inviting."
      );
      setShowEditOrInviteLoader(false);
      return;
    }

    const recentInviteRes = await getRecentInvitesByPhoneNumber(
      "+91".concat(securityGuardData?.phoneNumber)
    );

    if (recentInviteRes?.length) {
      const res = recentInviteRes.filter(
        (invite) => invite?.clientId === user?.clientId
      );
      if (res?.length) {
        setSecurityGuardExistsError("This user has already been invited.");
        setShowEditOrInviteLoader(false);
        return;
      }
    }

    try {
      const res = await createInvite({ ...data });
    } finally {
      setShowEditOrInviteLoader(false);
      setSelectedTab(1);
    }
  };

  const handleCreateOrEditSecurityGuard = () => {
    if (
      !ShowEditOrInviteLoader &&
      isSecurityDataValid() &&
      user &&
      isLoggedIn &&
      user?.role?.includes("admin")
    ) {
      setShowEditOrInviteLoader(true);
      if (isEditSecurityRoute && editMode) {
        handleUpdateSecurityGuardDetails();
      } else {
        handleInviteSecurityGuard();
      }
    }
  };

  return (
    <>
      {!textToDisplay && (
        <div
          className="security-guard-form"
          style={{
            padding:
              isEditSecurityRoute && editMode
                ? "32px 16px 0px"
                : "20px 0px 0px",
          }}
        >
          <ScreenHeader
            title={
              isEditSecurityRoute
                ? "EDIT SECURITY GUARD / RECEPTIONIST"
                : "INVITE SECURITY GUARD / RECEPTIONIST"
            }
          />
          {(loadingSecurityData || loadingUser) && (
            <div
              style={{
                padding: "20px 0px 0px",
              }}
            >
              <Spinner />
            </div>
          )}
          <section
            className="security-form-input-section"
            style={{
              padding:
                isEditSecurityRoute && editMode
                  ? "10px 10px 20px"
                  : "10px 0px 20px",
            }}
          >
            <div className="input-field">
              <Text variant="mediumPlus"> Name:</Text>
              <TextField
                name="name"
                value={securityGuardData?.name}
                errorMessage={securityGuardDataError?.name}
                invalid={!!securityGuardDataError?.name}
                onChange={handleNameInputChange}
                className={securityGuardDataError?.name ? "input-error" : ""}
                placeholder={"Enter name"}
              />
            </div>
            <div className="input-field">
              <Text variant="mediumPlus"> Mobile Number:</Text>
              <TextField
                name="phoneNumber"
                prefix="+91"
                value={securityGuardData?.phoneNumber}
                errorMessage={securityGuardDataError?.phoneNumber}
                invalid={!!securityGuardDataError?.phoneNumber}
                disabled={isEditSecurityRoute && editMode}
                onChange={handlePhoneNumberChange}
                className={`${
                  securityGuardDataError?.phoneNumber
                    ? "input-error input-prefix"
                    : "input-prefix"
                } ${isEditSecurityRoute && editMode ? "disabled-input" : ""}`}
                placeholder={"Enter phone number"}
              />
            </div>
            <div className="input-field">
              <Text variant="mediumPlus"> Email:</Text>
              <TextField
                name="email"
                value={securityGuardData?.email}
                errorMessage={securityGuardDataError?.email}
                invalid={!!securityGuardDataError?.email}
                onChange={handleEmailChange}
                className={securityGuardDataError?.email ? "input-error" : ""}
                placeholder={"Enter email"}
              />
            </div>
            {editMode && (
              <div className="input-field">
                <Text variant="mediumPlus"> Active:</Text>
                <div style={{ width: "200px" }}>
                  <Toggle
                    checked={securityGuardData?.isActive}
                    onChange={handleSecurityGuardStatusChange}
                  />
                </div>
              </div>
            )}
          </section>
          <section className="action-button-section">
            {securityGuardExistsError && (
              <Text
                variant="medium"
                style={{
                  color: "#fd0118",
                  paddingTop: "5px",
                  display: "block",
                  textAlign: "center",
                }}
              >
                {securityGuardExistsError}
              </Text>
            )}
            {!securityGuardExistsError && ShowEditOrInviteLoader && (
              <div
                style={{
                  padding: "20px 0px 0px",
                }}
              >
                <Spinner />
              </div>
            )}
            <PrimaryButton
              iconProps={{
                iconName: isEditSecurityRoute ? "Save" : "AddFriend",
              }}
              onClick={handleCreateOrEditSecurityGuard}
              style={{ marginTop: "20px" }}
              styles={{
                textContainer: {
                  marginLeft: "5px",
                  flexGrow: "auto",
                },
              }}
            >
              {isEditSecurityRoute ? "Save" : "Invite"}
            </PrimaryButton>
          </section>
        </div>
      )}
      {textToDisplay && (
        <div
          style={{
            width: "100%",
            height: "200px",
            display: "flex",
            padding: "28px",
            textAlign: "center",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Text variant="large">{textToDisplay}</Text>
        </div>
      )}
    </>
  );
}
