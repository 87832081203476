import { db } from "../firebase-config";
import {
  doc,
  getDoc,
  setDoc,
  query,
  where,
  collection,
  orderBy,
  getDocs,
  updateDoc,
} from "firebase/firestore";
import { UserType } from "../types/CommonTypes/CommonTypes.types";

const usersColRef = collection(db, "Users");

const checkUserExists = (id: string) => {
  const userDocRef = doc(db, "Users", id);
  return getDoc(userDocRef).then((userDocSnap) => userDocSnap.exists());
};

const getUser = (id: string) => {
  const userDocRef = doc(db, "Users", id);
  return getDoc(userDocRef).then((userDocSnap) => {
    if (userDocSnap.exists()) {
      return { ...userDocSnap.data(), id: userDocSnap.id };
    } else return null;
  });
};

const addUser = (id: string, data: UserType) => {
  const userDocRef = doc(db, "Users", id);
  return setDoc(userDocRef, data, { merge: true });
};

const getSecurityGuards = async (clientId: string) => {
  const q = query(
    usersColRef,
    where("clientId", "==", clientId),
    where("role", "array-contains", "security_guard"),
    orderBy("name")
  );

  return getDocs(q).then((querySnapShot) => {
    return querySnapShot.docs.map((item) => ({ ...item.data(), id: item.id }));
  });
};

const updateUserDetails = async (userId: string, data: any) => {
  return updateDoc(doc(db, "Users", userId), data);
};

const checkSecurityGuardIsActive = async (phoneNumber: string) => {
  const q = query(
    usersColRef,
    where("isActive", "==", true),
    where("phoneNumber", "==", phoneNumber),
    where("role", "array-contains", "security_guard")
  );

  return await getDocs(q).then((querySnapshot) => {
    if (querySnapshot.size > 0) return true;
    return false;
  });
};

export {
  checkUserExists,
  getUser,
  addUser,
  getSecurityGuards,
  updateUserDetails,
  checkSecurityGuardIsActive,
};
