import React from "react";
import "./statusBadge.scss";
import CompletedIcon from "../../assets/images/completed-icon.svg";
import ApprovedIcon from "../../assets/images/approved-icon.svg";
import PendingIcon from "../../assets/images/pending-icon.svg";
import RestrictedIcon from "../../assets/images/restricted-icon.svg";
import { Text, ImageIcon } from "@fluentui/react";
import { StatusBadgePropsType } from "./StatusBadge.types";

export default function StatusBadge({ status }: StatusBadgePropsType) {
  const getTextColor = () => {
    if (status === "completed") return "#6C70C5";
    if (status === "approved") return "#4ECB71";
    if (status === "pending") return "#F27F14";
    if (status === "restricted") return "#FD0118";
  };

  const getStatusIcon = () => {
    if (status === "completed") return CompletedIcon;
    if (status === "approved") return ApprovedIcon;
    if (status === "pending") return PendingIcon;
    if (status === "restricted") return RestrictedIcon;
  };

  const formatStatusName = (status: string) => {
    return status[0].toUpperCase() + status.substring(1);
  };

  return (
    <div className={`status-badge`}>
      <ImageIcon
        imageProps={{
          src: getStatusIcon(),
          styles: { image: { width: "20px", height: "20px" } },
        }}
      />
      <Text
        variant="medium"
        style={{
          letterSpacing: "0.7px",
          color: getTextColor(),
          marginTop: "2px",
        }}
      >
        {formatStatusName(status)}
      </Text>
    </div>
  );
}
