import { db } from "../firebase-config";
import { collection, getDoc, doc } from "firebase/firestore";

const ClientsColRef = collection(db, "Clients");

const getClientData = async (clientId: string) => {
  const docRef = doc(db, "Clients", clientId);
  return getDoc(docRef).then((snapShot) => {
    return { ...snapShot.data(), id: snapShot.id };
  });
};

export { getClientData };
