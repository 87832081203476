import React from "react";
import "./inviteCard.scss";
import { Text, Image, FontIcon } from "@fluentui/react";
import UserProfileImage from "../../assets/images/user-profile.svg";
import { InviteType } from "../../types/CommonTypes/CommonTypes.types";

export default function InviteCard({
  invite,
  onClickDelete,
}: {
  invite: InviteType;
  onClickDelete: (invite: InviteType) => void;
}) {
  return (
    <div className="invite-card">
      <div className="invite-image-details-container">
        <div className="image-container">
          <Image
            width={45}
            height={45}
            loading="lazy"
            src={UserProfileImage}
            styles={{
              root: { borderRadius: "50%" },
              image: { borderRadius: "50%" },
            }}
          />
        </div>
        <div className="invite-details-container">
          <Text variant="xLarge">{invite?.name}</Text>
          <Text variant="medium" className="phone-number">
            {invite?.phoneNumber?.substring(3)}
          </Text>
          {invite?.email && (
            <Text variant="medium" className="email">
              {invite?.email}
            </Text>
          )}
        </div>
      </div>
      <div className="delete-invite-btn-container">
        <FontIcon
          iconName="Delete"
          // style={{ color: "#a09c9c"}}
        />
        <Text
          variant="mediumPlus"
          className="delete-invite"
          onClick={() => onClickDelete(invite)}
        >
          Delete
        </Text>
      </div>
    </div>
  );
}
