import React, { useState, useEffect, useContext } from "react";
import "./hostForm.scss";
import ScreenHeader from "../../../Components/ScreenHeader/ScreenHeader";
import {
  Text,
  TextField,
  PrimaryButton,
  Spinner,
  Toggle,
} from "@fluentui/react";
import { UserContext } from "../../../context/UserContextProvider";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import {
  getHostDetailsById,
  updateHostDetails,
  addHost,
  checkHostExistsForCustomer,
} from "../../../services/hostService";
import Select from "react-select";
import {
  CustomerType,
  HostType,
} from "../../../types/CommonTypes/CommonTypes.types";
import { getCustomers } from "../../../services/customerService";

export default function HostForm() {
  const { hostId } = useParams();
  const routeLocation = useLocation();
  const navigate = useNavigate();
  const isEditHostRoute = routeLocation.pathname.endsWith("/edit");
  const [editMode, setEditMode] = useState(false);
  const [loadingHostDetails, setLoadingHostDetails] = useState(false);
  const [loadingCustomers, setLoadingCustomers] = useState(false);
  const [textToDisplay, setTextToDisplay] = useState("");
  const [allCustomers, setAllCustomers] = useState([] as CustomerType[]);
  const [formattedCustomerDetails, setFormattedCustomerDetails] = useState<any>(
    []
  );
  const [hostExistsError, setHostExistsError] = useState("");
  const [hostOldData, setHostOldData] = useState({} as HostType);
  const [editOrCreateLoader, setEditOrCreateLoader] = useState(false);
  const { user, isLoggedIn, loadingUser } = useContext(UserContext);
  const phoneNumberRegex = /^[0-9]{10}$/g;
  const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  const [hostData, setHostData] = useState({
    name: "",
    phoneNumber: "",
    email: "",
    isActive: true,
    customerId: "",
  });

  const [hostDataError, setHostDataError] = useState({
    name: "",
    phoneNumber: "",
    email: "",
    customerId: "",
  });

  useEffect(() => {
    if (!loadingUser) {
      if (user && isLoggedIn && user?.role?.includes("admin")) {
        setTextToDisplay("");
        getAllCustomers(user?.clientId as string);
        if (hostId) {
          getHostDetails(hostId);
        }
      } else {
        setTextToDisplay(
          "Wrong user!, This page can be accessed only by admin"
        );
      }
    }
  }, [user, isLoggedIn, loadingUser, hostId]);

  useEffect(() => {
    if (allCustomers?.length) {
      const res = allCustomers.map((customer) => ({
        value: customer?.id,
        label: customer?.companyName,
      }));
      setFormattedCustomerDetails([...res]);
    }
  }, [allCustomers]);

  const getHostDetails = (hostId: string) => {
    setLoadingHostDetails(true);
    getHostDetailsById(hostId)
      .then((res: any) => {
        if (res) {
          setEditMode(true);
          setHostData({
            name: res?.name,
            phoneNumber: res?.phoneNumber?.substring(3),
            email: res?.email,
            isActive: res?.isActive,
            customerId: res?.customerId,
          });
          setHostOldData(res as HostType);
        } else {
          setTextToDisplay("Host doesn't exists");
          setEditMode(false);
        }
      })
      .finally(() => {
        setLoadingHostDetails(false);
      });
  };
  const getAllCustomers = (clientId: string) => {
    setLoadingCustomers(true);
    getCustomers(clientId)
      .then((res) => {
        if (res) {
          setAllCustomers(res as CustomerType[]);
        }
      })
      .finally(() => {
        setLoadingCustomers(false);
      });
  };

  const reactSelectCustomStyles = {
    option: (baseStyles: any, state: any) => ({
      ...baseStyles,
      cursor: "pointer",
      color: state.isSelected ? "#ffffff" : "#605c5c",
      fontSize: "14px",
      fontWeight: "400",
      overflow: "hidden",
      textOverflow: "ellipsis",
      backgroundColor: state.isSelected ? "#6c70c5" : "#ffffff",
      "&:hover": {
        backgroundColor: "#6c70c50a",
        color: "#6c70c5",
      },
      "&:active": {
        backgroundColor: "#6c70c51a",
        color: "#6c70c5",
      },
    }),
    singleValue: (baseStyles: any, state: any) => ({
      ...baseStyles,
      fontSize: "14px",
      fontWeight: "400",
      letterSpacing: "0.5px",
      color: "#605C5C",
    }),
    valueContainer: (baseStyles: any, state: any) => ({
      ...baseStyles,
      padding: "0px 4px 0px 14px",
    }),
    placeholder: (baseStyles: any) => ({
      ...baseStyles,
      color: "#a09c9c",
      letterSpcing: "0.3px",
      overflow: "hidden",
      whiteSpace: "noWrap",
      textOverflow: "ellipsis",
    }),
    loadingIndicator: (baseStyles: any) => ({
      ...baseStyles,
      color: "#6c70c5",
    }),
  };

  const handleNameInputChange = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string | undefined
  ) => {
    const { name } = event.target as HTMLInputElement;
    const data: any = { ...hostData };
    const error: any = { ...hostDataError };

    data.name = newValue as string;
    error.name = "";

    setHostData({ ...data });
    setHostDataError({ ...error });

    if (newValue) {
      if (newValue.length > 50) {
        error.name = "Maximum 50 characters allowed";
        setHostDataError({ ...error });
      }
      const res = /^([A-Za-z\s])*$/g.test(newValue as string);
      if (!res) {
        error[name] = "Only alphabets and spaces are allowed";
        setHostDataError({ ...error });
      }
    }
  };
  const handlePhoneNumberChange = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string | undefined
  ) => {
    setHostDataError({
      ...hostDataError,
      phoneNumber: "",
    });
    setHostData({
      ...hostData,
      phoneNumber: newValue as string,
    });
    setHostExistsError("");
    const { length } = newValue as string;

    if (length === 0) return;

    if (newValue && newValue.length >= 10) {
      const res = phoneNumberRegex.test(newValue);
      if (!res) {
        setHostDataError({
          ...hostDataError,
          phoneNumber: "Enter valid phone number",
        });
      }
      return;
    }
    const res = /^([0-9]*)$/g.test(newValue as string);
    if (
      !res ||
      newValue?.includes("+") ||
      newValue?.includes("e") ||
      newValue?.includes("E")
    ) {
      setHostDataError({
        ...hostDataError,
        phoneNumber: "Enter valid phone number",
      });
    }
  };

  const handleEmailChange = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string | undefined
  ) => {
    const data: any = { ...hostData };
    const error: any = { ...hostDataError };
    error.email = "";
    data.email = newValue as string;
    setHostDataError({
      ...error,
    });
    setHostData({
      ...data,
    });
    if (newValue) {
      if (newValue.length > 50) {
        error.email = "Maximum 50 characters allowed";
        setHostDataError({ ...error });
      }
    }
  };
  const handleHostStatusChange = (
    ev: React.MouseEvent<HTMLElement>,
    checked?: boolean
  ) => {
    setHostData({
      ...hostData,
      isActive: checked as boolean,
    });
  };

  const handleCustomerChange = (newValue: any) => {
    setHostExistsError("");
    setHostDataError({ ...hostDataError, customerId: "" });
    setHostData({ ...hostData, customerId: newValue?.value });
  };

  const getCustomerDropdownValueById = (customerId: string) => {
    if (allCustomers?.length > 0) {
      const res = allCustomers.find((customer) => customer?.id === customerId);
      if (res) {
        return {
          value: customerId,
          label: res?.companyName,
        };
      }
    }
  };

  const isThereErrorInData = (hostError: any) => {
    const res1 = Object.keys(hostError).every((key) => !hostError[key]);
    return res1;
  };

  const validateHostData = () => {
    const hostDataLocal = { ...hostData } as any;
    const hostErrorLocal = { ...hostDataError } as any;

    const mandatoryHostFields = Object.keys(hostDataLocal);

    mandatoryHostFields
      .filter((field) => field !== "email" && field !== "isActive")
      .forEach((field: any) => {
        hostDataLocal[field] = hostDataLocal[field]?.trim();

        if (!hostDataLocal[field]) {
          hostErrorLocal[field] = "This field is required";
          return;
        }

        if (field === "phoneNumber") {
          const res = phoneNumberRegex.test(hostDataLocal.phoneNumber);
          if (!res) {
            hostErrorLocal.phoneNumber = "Enter valid phone number";
          }
        }
      });

    if (hostDataLocal?.email !== "") {
      hostDataLocal.email = hostDataLocal?.email?.trim();
      const res = emailRegex.test(hostDataLocal?.email);
      if (!res) {
        hostErrorLocal.email = "Enter valid email";
      }
    }

    setHostData({ ...hostDataLocal });
    setHostDataError({ ...hostErrorLocal });

    return {
      hostErrorLocal,
    };
  };

  const isHostExistsForSelectedCustomer = async () => {
    if (hostOldData?.customerId !== hostData.customerId) {
      const res = await checkHostExistsForCustomer(
        user?.clientId as string,
        hostData?.customerId,
        "+91".concat(hostData?.phoneNumber)
      );
      if (res) {
        setHostExistsError("Host already exists for the selected customer");
      } else {
        setHostExistsError("");
      }
      return res;
    }

    return false;
  };

  const isHostDataValid = () => {
    const { hostErrorLocal } = validateHostData();
    const res = isThereErrorInData(hostErrorLocal);
    return res;
  };
  const handleUpdateHostDetails = async () => {
    const data = {
      name: hostData?.name.trim(),
      email: hostData?.email.trim(),
      isActive: hostData?.isActive,
      customerId: hostData?.customerId,
    };
    try {
      await updateHostDetails(hostId as string, data);
    } finally {
      setEditOrCreateLoader(false);
      navigate("/hosts/manage");
    }
  };

  const handleAddHost = async () => {
    const data = {
      name: hostData?.name.trim(),
      email: hostData?.email.trim(),
      phoneNumber: "+91".concat(hostData?.phoneNumber.trim()),
      isActive: true,
      clientId: user?.clientId as string,
      customerId: hostData?.customerId,
    };
    try {
      const res = await addHost(data);
    } finally {
      setEditOrCreateLoader(false);
      navigate("/hosts/manage");
    }
  };

  const handleCreateOrEditHost = async () => {
    if (
      !editOrCreateLoader &&
      isHostDataValid() &&
      user &&
      isLoggedIn &&
      user?.role?.includes("admin") &&
      !(await isHostExistsForSelectedCustomer())
    ) {
      setEditOrCreateLoader(true);
      if (isEditHostRoute && editMode) {
        handleUpdateHostDetails();
      } else {
        handleAddHost();
      }
    }
  };

  return (
    <>
      {!textToDisplay && (
        <div className="host-form">
          <ScreenHeader title={isEditHostRoute ? "EDIT HOST" : "ADD HOST"} />
          {(loadingHostDetails || loadingUser) && (
            <div
              style={{
                padding: "20px 0px 0px",
              }}
            >
              <Spinner />
            </div>
          )}
          <section className="host-form-input-section">
            <div className="input-field">
              <Text variant="mediumPlus"> Name:</Text>
              <TextField
                name="name"
                value={hostData?.name}
                errorMessage={hostDataError?.name}
                invalid={!!hostDataError?.name}
                onChange={handleNameInputChange}
                className={hostDataError?.name ? "input-error" : ""}
                placeholder={"Enter name"}
              />
            </div>
            <div className="input-field">
              <Text variant="mediumPlus"> Mobile Number:</Text>
              <TextField
                name="phoneNumber"
                prefix="+91"
                value={hostData?.phoneNumber}
                errorMessage={hostDataError?.phoneNumber}
                invalid={!!hostDataError?.phoneNumber}
                disabled={isEditHostRoute && editMode}
                onChange={handlePhoneNumberChange}
                className={`${
                  hostDataError?.phoneNumber
                    ? "input-error input-prefix"
                    : "input-prefix"
                } ${isEditHostRoute && editMode ? "disabled-input" : ""}`}
                placeholder={"Enter phone number"}
              />
            </div>
            <div className="input-field">
              <Text variant="mediumPlus"> Email:</Text>
              <TextField
                name="email"
                value={hostData?.email}
                errorMessage={hostDataError?.email}
                invalid={!!hostDataError?.email}
                onChange={handleEmailChange}
                className={hostDataError?.email ? "input-error" : ""}
                placeholder={"Enter email"}
              />
            </div>
            <div className="input-field">
              <Text variant="mediumPlus"> Customer:</Text>
              <div>
                <Select
                  isSearchable
                  isLoading={loadingCustomers}
                  value={getCustomerDropdownValueById(hostData?.customerId)}
                  classNamePrefix="react-select"
                  placeholder="Select customer"
                  options={formattedCustomerDetails}
                  onChange={handleCustomerChange}
                  styles={{
                    ...reactSelectCustomStyles,
                    control: (baseStyles: any, state: any) => {
                      return {
                        ...baseStyles,
                        borderWidth: "0.5px",
                        height: "45px",
                        width: "200px",
                        borderRadius: "8px",
                        borderColor: hostDataError.customerId
                          ? "#fd0118"
                          : "#605c5c80",
                        "&:hover": {
                          cursor: "pointer",
                          borderColor: hostDataError.customerId
                            ? "#fd0118"
                            : "#605c5c80",
                        },
                      };
                    },
                  }}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: hostDataError?.customerId
                        ? "#fd0118"
                        : "#6c70c5",
                    },
                  })}
                />
                {hostDataError?.customerId && (
                  <Text
                    variant="small"
                    style={{
                      color: "#fd0118",
                      paddingTop: "5px",
                      display: "block",
                    }}
                  >
                    {hostDataError?.customerId}
                  </Text>
                )}
              </div>
            </div>
            {editMode && (
              <div className="input-field">
                <Text variant="mediumPlus"> Active:</Text>
                <div style={{ width: "200px" }}>
                  <Toggle
                    checked={hostData?.isActive}
                    onChange={handleHostStatusChange}
                  />
                </div>
              </div>
            )}
          </section>
          <section className="action-button-section">
            {hostExistsError && (
              <Text
                variant="medium"
                style={{
                  color: "#fd0118",
                  paddingTop: "5px",
                  display: "block",
                  textAlign: "center",
                }}
              >
                {hostExistsError}
              </Text>
            )}
            {!hostExistsError && editOrCreateLoader && (
              <div
                style={{
                  padding: "20px 0px 0px",
                }}
              >
                <Spinner />
              </div>
            )}
            <PrimaryButton
              iconProps={{
                iconName: isEditHostRoute ? "Save" : "Add",
              }}
              onClick={handleCreateOrEditHost}
              style={{ marginTop: "20px" }}
              styles={{
                textContainer: {
                  marginLeft: "5px",
                  flexGrow: "auto",
                },
              }}
            >
              {isEditHostRoute ? "Save" : "Add"}
            </PrimaryButton>
          </section>
        </div>
      )}
      {textToDisplay && (
        <div
          style={{
            width: "100%",
            height: "200px",
            display: "flex",
            padding: "28px",
            textAlign: "center",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Text variant="large">{textToDisplay}</Text>
        </div>
      )}
    </>
  );
}
