import { createTheme } from "@fluentui/react";
import { IFontStyles } from "@fluentui/react";

export const theme = createTheme({
  defaultFontStyle: { fontFamily: "Montserrat", letterSpacing: "0.5px" },
  fonts: {
    xxLarge: {
      //for screen-header
      fontSize: "16px",
      fontWeight: 700,
      color: "#6C70C5",
      lineHeight: "20px",
      letterSpacing: "1px",
      textAlign: "center",
    },
    medium: {
      fontSize: "14px",
      fontWeight: 400,
      color: "#605C5C",
      lineHeight: "18px",
    },

    mediumPlus: {
      fontSize: "14px",
      fontWeight: 700,
      color: "#605C5C",
      lineHeight: "18px",
    },
    small: {
      fontSize: "12px",
      fontWeight: 400,
      color: "#605C5C",
    },
    smallPlus: {
      fontSize: "12px",
      fontWeight: 400,
      color: "rgba(96, 92, 92, 0.8)",
      letterSpacing: "1px",
    },
    large: {
      fontSize: "20px",
      fontWeight: "semibold",
    },
    xLarge: {
      fontSize: "16px",
      fontWeight: 700,
      lineHeight: "20px",
    },
  },
  palette: {
    themePrimary: "#6c70c5",
    themeLighterAlt: "#f8f8fd",
    themeLighter: "#e4e5f6",
    themeLight: "#cdcfed",
    themeTertiary: "#a0a3dc",
    themeSecondary: "#7b7fcb",
    themeDarkAlt: "#6165b1",
    themeDark: "#525595",
    themeDarker: "#3c3f6e",
    neutralLighterAlt: "#faf9f8",
    neutralLighter: "#f3f2f1",
    neutralLight: "#edebe9",
    neutralQuaternaryAlt: "#e1dfdd",
    neutralQuaternary: "#d0d0d0",
    neutralTertiaryAlt: "#c8c6c4",
    neutralTertiary: "#d0cdcd",
    neutralSecondary: "#a09c9c",
    neutralSecondaryAlt: "#a09c9c",
    neutralPrimaryAlt: "#747070",
    neutralPrimary: "#605c5c",
    neutralDark: "#4a4747",
    black: "#363434",
    white: "#ffffff",
  },
  components: {
    TextField: {
      styles: {
        root: {
          width: "200px",
        },
        fieldGroup: {
          height: "45px",
          padding: "0 16px",
          borderRadius: "8px",
          border: "0.5px solid rgba(96,92,92,0.5)",
          "&::after": {
            borderRadius: "8px",
          },
        },

        field: {
          fontSize: "14px",
          fontWeight: 400,
          color: "#605c5c",
          padding: 0,
          letterSpacing: "0.5px",
        },
        errorMessage: {
          color: "#fd0118",
        },
      },
    },
    Dropdown: {
      styles: {
        root: {
          width: "160px",
        },
        dropdown: {
          fontSize: "14px",
          fontWeight: 400,
          color: "#605c5c",
          letterSpacing: "0.5px",
          "&:focus::after": {
            borderRadius: "8px",
          },
        },
        title: {
          height: "45px",
          border: "0.5px solid rgba(96,92,92,0.5)",
          borderRadius: "8px",
          padding: "14px 24px 14px 16px",
          lineHeight: "17px",
        },
        caretDownWrapper: {
          top: "8px",
        },
        dropdownItem: {
          letterSpacing: "0.5px",
          transition: "all 0.1s",
          "&:hover": {
            color: "#6c70c5",
            backgroundColor: "#6c70c50a",
          },
          "&:active": {
            color: "#6c70c5",
            backgroundColor: "#6c70c51a",
          },
          "&:active:hover": {
            color: "#6c70c5",
            backgroundColor: "#6c70c51a",
          },
        },
        dropdownItemSelected: {
          letterSpacing: "0.5px",
          transition: "all 0.1s",
          color: "#fff",
          backgroundColor: "#6c70c5",
          "&:hover": {
            color: "#6c70c5",
            backgroundColor: "#6c70c50a",
          },
          "&:active": {
            color: "#6c70c5",
            backgroundColor: "#6c70c51a",
          },
          "&:active:hover": {
            color: "#6c70c5",
            backgroundColor: "#6c70c51a",
          },
        },
        errorMessage: {
          color: "#fd0118",
        },
      },
    },
    PrimaryButton: {
      styles: {
        root: {
          width: "132px",
          height: "44px",
          borderRadius: "4px",
          fontSize: "16px",
          fontWeight: "600",
          lineHeight: "20px",
          letterSpacing: "1px",
          boxShadow: "0px 8px 24px 0px rgba(108,112,197,0.15)",
          backgroundColor: "#6c70c5",
          color: "#ffffff",
        },
        rootHovered: {
          color: "#ffffff",
          border: "1px solid rgb(97, 101, 177)",
          backgroundColor: "rgb(97, 101, 177)",
        },
        rootPressed: {
          border: "1px solid rgb(82, 85, 149)",
          backgroundColor: "rgb(82, 85, 149)",
        },
      },
    },
    DefaultButton: {
      styles: {
        root: {
          backgroundColor: "#ffffff",
          width: "132px",
          height: "44px",
          fontSize: "16px",
          fontWeight: 600,
          lineHeight: "20px",
          letterSpacing: "1px",
          border: "1px solid #6c70c5",
          borderRadius: "4px",
          color: "#6c70c5",
        },
        rootHovered: {
          color: "#6c70c5",
          backgroundColor: "#6c70c50a",
        },
        rootPressed: {
          color: "#6c70c5",
          backgroundColor: "#6c70c51a",
        },
      },
    },
    IconButton: {
      styles: {
        root: {
          "&.is-disabled": {
            backgroundColor: "transparent",
          },
        },
        rootPressed: {
          backgroundColor: "transparent",
        },
        rootHovered: {
          backgroundColor: "transparent",
        },
      },
    },
    SearchBox: {
      styles: {
        root: {
          width: "245px",
          fontSize: "16px",
          backgroundColor: "#fdfdfd",
          borderRadius: "4px",
          padding: "2px 4px",
          border: "none",
        },
        iconContainer: {
          width: "25px",
        },
        field: {
          padding: 0,
          paddingLeft: "8px",
        },
        clearButton: {
          display: "none",
        },
      },
    },
    Spinner: {
      styles: {
        circle: {
          borderWidth: "4px",
        },
      },
    },
  },
});
