import React, { useContext } from "react";
import { UserContext } from "../../context/UserContextProvider";
import { Navigate, useLocation } from "react-router-dom";

type PrivateRoutePropsType = {
  children: React.ReactNode;
};

export default function PrivateRoute({ children }: PrivateRoutePropsType) {
  const { isLoggedIn, isAuthStateChecked } = useContext(UserContext);
  const location = useLocation();

  if (!isLoggedIn && isAuthStateChecked) {
    return <Navigate to={"/login"} state={{ from: location }} />;
  }
  return <>{children}</>;
}
