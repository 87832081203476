import React from "react";
import "./settingsPage.scss";
import { Text, ImageIcon } from "@fluentui/react";
import PlusIcon from "../../assets/images/plus.svg";
import ExportIcon from "../../assets/images/export.svg";
import ReportIcon from "../../assets/images/report.svg";
import PrintIcon from "../../assets/images/print.svg";

export default function SettingsPage() {
  return (
    <div className="settings-page">
      <div className="screen-header">
        <Text variant="xxLarge">SETTINGS</Text>
      </div>
      <section className="settings-menu-section">
        <div className="settings-menu">
          <ImageIcon
            imageProps={{
              src: ExportIcon,
              styles: { image: { width: "20px", height: "20px" } },
            }}
          />
          <Text
            variant="medium"
            style={{ marginLeft: "16px", color: "#0F5132" }}
          >
            Export Report
          </Text>
        </div>
        <div className="settings-menu">
          <ImageIcon
            imageProps={{
              src: ReportIcon,
              styles: { image: { width: "20px", height: "20px" } },
            }}
          />
          <Text
            variant="medium"
            style={{ marginLeft: "16px", color: "#0F5132" }}
          >
            Report View
          </Text>
        </div>
        <div className="settings-menu">
          <ImageIcon
            imageProps={{
              src: PrintIcon,
              styles: { image: { width: "20px", height: "20px" } },
            }}
          />
          <Text
            variant="medium"
            style={{ marginLeft: "16px", color: "#0F5132" }}
          >
            Print Report
          </Text>
        </div>
      </section>
    </div>
  );
}
