import React, { useState, useEffect, useContext } from "react";
import "./manageSecurityGuards.scss";
import ScreenHeader from "../../../Components/ScreenHeader/ScreenHeader";
import SecurityGuardCard from "../../../Components/SecurityGuardCard/SecurityGuardCard";
import { UserContext } from "../../../context/UserContextProvider";
import { Spinner, Text, IconButton } from "@fluentui/react";
import AddVisitorIcon from "../../../assets/images/add-visitor.svg";
import { UserType } from "../../../types/CommonTypes/CommonTypes.types";
import { getSecurityGuards } from "../../../services/userService";
import { BottomSheet } from "react-spring-bottom-sheet";
import SecurityGuardDetails from "../SecurityGuardDetails/SecurityGuardDetails";
import { useNavigate } from "react-router-dom";
import NoDataCard from "../../../Components/NoDataCard/NoDataCard";
import WrongUserCard from "../../../Components/WrongUserCard/WrongUserCard";

export default function ManageSecurityGuards() {
  const navigate = useNavigate();
  const { user, isLoggedIn, loadingUser } = useContext(UserContext);
  const [textToDisplay, setTextToDisplay] = useState("");
  const [isBottomSheetOpen, setIsBottomSheetOpen] = useState(false);
  const [securityGuards, setSecurityGuards] = useState([] as UserType[]);
  const [selectedSecurityGuard, setSelectedSecurityGuard] =
    useState<UserType | null>(null);
  const [loadingSecurityGuards, setLoadingSecurityGuards] = useState(false);

  useEffect(() => {
    if (!loadingUser) {
      if (user && isLoggedIn && user?.role?.includes("admin")) {
        setTextToDisplay("");
        getAllSecurityGuards(user?.clientId as string);
      } else {
        setTextToDisplay("This page can be accessed only by admin");
      }
    }
  }, [user, isLoggedIn, loadingUser]);

  const getAllSecurityGuards = async (clientId: string) => {
    setLoadingSecurityGuards(true);
    getSecurityGuards(clientId)
      .then((res) => {
        setSecurityGuards([...res] as UserType[]);
      })
      .finally(() => {
        setLoadingSecurityGuards(false);
      });
  };

  const onClickSeeMoreBtn = (securityGuard: UserType) => {
    setIsBottomSheetOpen(true);
    setSelectedSecurityGuard(securityGuard);
  };

  const onDismissBottomSheet = () => {
    setIsBottomSheetOpen(false);
    setSelectedSecurityGuard(null);
  };

  const handleAddSecurityGuardBtnClick = () => {
    navigate("/security-guards/invite");
  };

  return (
    <>
      {!textToDisplay && (
        <div className="manage-security-guards">
          <BottomSheet
            open={isBottomSheetOpen}
            onDismiss={onDismissBottomSheet}
            snapPoints={({ minHeight, maxHeight }) => [
              maxHeight * 0.75,
              maxHeight,
            ]}
            expandOnContentDrag
          >
            <SecurityGuardDetails
              securityGuard={selectedSecurityGuard as UserType}
            />
          </BottomSheet>
          <ScreenHeader title="Manage App users at Entry Point ( Guards / Receptionist etc)" />
          <section className="security-guard-cards-list-section">
            {securityGuards?.length > 0 &&
              !loadingSecurityGuards &&
              securityGuards?.map((securityGuard) => (
                <SecurityGuardCard
                  key={securityGuard.id}
                  securityGuard={securityGuard}
                  onClickSeeMore={onClickSeeMoreBtn}
                />
              ))}
            {securityGuards?.length === 0 &&
              !loadingSecurityGuards &&
              !loadingUser && <NoDataCard />}
            {(loadingSecurityGuards || loadingUser) && (
              <div
                style={{
                  padding: "20px 0px",
                }}
              >
                <Spinner />
              </div>
            )}
          </section>
          <div className="add-security-guard-btn">
            <IconButton
              iconProps={{
                imageProps: {
                  src: AddVisitorIcon,
                  styles: {
                    image: {
                      width: "48px",
                      height: "48px",
                    },
                  },
                },
              }}
              styles={{
                root: {
                  width: "48px",
                  height: "48px",
                  borderRadius: "50%",
                  backgroundColor: "#ffffff",
                },
                icon: {
                  width: "48px",
                  height: "48px",
                },
              }}
              onClick={handleAddSecurityGuardBtnClick}
            />
          </div>
        </div>
      )}
      {textToDisplay && <WrongUserCard textToDisplay={textToDisplay} />}
    </>
  );
}
