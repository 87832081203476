import React, { useEffect, useState, useContext } from "react";
import "./appointment.scss";
import MicImage from "../../assets/images/mic-big.svg";
import MicSmallIcon from "../../assets/images/mic-small.svg";
import SoundWaveGif from "../../assets/images/sound-wave.gif";
import RequestSentGif from "../../assets/images/request-sent.gif";
import AudioFileImage from "../../assets/images/audio-file.svg";
import PlayIcon from "../../assets/images/play.svg";
import PauseIcon from "../../assets/images/pause.svg";
import TrashIcon from "../../assets/images/trash.svg";
import KeyBoardIcon from "../../assets/images/keyboard.svg";
import DisabledKeyboardIcon from "../../assets/images/disabled-keyboard.svg";
import useVoiceRecorder from "../../hooks/useVoiceRecorder";
import { UserContext } from "../../context/UserContextProvider";
import { storage } from "../../firebase-config";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import {
  updateVisitData,
  addCommunicationData,
  getVisitData,
} from "../../services/visitsService";
import GreenCheckIcon from "../../assets/images/green-check.svg";
import Select from "react-select";
import { useNavigate, useSearchParams } from "react-router-dom";
import { formatTime } from "../../helpers/formatTime";
import { TimerType, AudioObjType } from "./Appointment.types";
import {
  VisitType,
  CommunicationObjType,
  UserType,
} from "../../types/CommonTypes/CommonTypes.types";
import { DialogType, FontIcon } from "@fluentui/react";
import { serverTimestamp } from "firebase/firestore";
import {
  Text,
  TextField,
  Image,
  PrimaryButton,
  IconButton,
  Dialog,
  ImageIcon,
  Dropdown,
} from "@fluentui/react";

const CustomDropdownIndicator = ({ innerProps, isDisabled }: any) =>
  !isDisabled ? (
    <div {...innerProps} className="react-select__dropdown-indicator">
      <FontIcon
        iconName="ChevronDownSmall"
        style={{ color: "#a09c9c", marginTop: "1.3px" }}
      />
    </div>
  ) : null;

export default function AppointmentVisitor() {
  const { startRecording, stopRecording } = useVoiceRecorder();
  const { user, isLoggedIn } = useContext(UserContext);
  const [allHosts, setAllHosts] = useState<any>([]);
  const [showRequestSentModal, setShowRequestSentModal] = useState(false);
  const [selectedHost, setSelectedHost] = useState<any>(null);
  const [isPurposeInputMicMode, setIsPurposeInputMicMode] = useState(true);
  const [recording, setRecording] = useState(false);
  const [audioPlaying, setAudioPlaying] = useState(false);
  const [audioPaused, setAudioPaused] = useState(false);
  const [notes, setNotes] = useState("");
  const [textToDisplay, setTextToDisplay] = useState("");
  const [loadingHosts, setLoadingHosts] = useState(false);
  const [timerWhileRecording, setTimerWhileRecording] = useState<TimerType>({
    id: null,
    timer: 0,
  });
  const [timerWhilePlaying, setTimerWhilePlaying] = useState<TimerType>({
    id: null,
    timer: 0,
  });
  const [audioObj, setAudioObj] = useState<AudioObjType | null>(null);
  const [requestSent, setRequestSent] = useState(false);
  const [searchParams] = useSearchParams();
  const visitId = searchParams.get("visitId");
  const navigate = useNavigate();
  const phoneNumberRegex = /^[0-9]{10}$/g;
  const vehicleNumberRegex = /^[A-Z]{2}\s[0-9]{2}\s[A-Z]{2}\s[0-9]{4}$/g;

  const [visitData, setVisitData] = useState({} as VisitType);
  const [visitDataError, setVisitDataError] = useState({
    visiting_person_name: "",
    visiting_person_from: "",
    visiting_person_vehicle_number: "",
    organizationError: "",
    hostError: "",
    purposeError: "",
  });

  useEffect(() => {
    if (user && isLoggedIn) {
      if (user.role.includes("visitor")) {
        if (visitId) {
          getVisitData(visitId)
            .then((res: any) => {
              setVisitData({ ...res } as VisitType);
              if (res?.status !== "pending") {
                setTextToDisplay(`This invite has already been ${res?.status}`);
              }
            })
            .catch((err) => {
              setTextToDisplay(
                "You may be wrong user or entered wrong url.This page can be accessed only by the visitor, please click on the link you recieved through sms."
              );
            });

          return;
        }
        setTextToDisplay(
          "Wrong url!, please click on the link that you recieved through sms"
        );
        return;
      }
      setTextToDisplay(
        "Wrong user!, This page can be accessed only by the visitor"
      );
      return;
    }
  }, [visitId, user, isLoggedIn]);

  // useEffect(() => {
  //   if (visitData?.customerId && user) {
  //     getAllHosts(user.clientId, visitData.customerId);
  //   }
  // }, [visitData?.customerId]);

  const getAllHosts = (clientId: string, customerId: string) => {
    // setLoadingHosts(true);
    // getHosts(clientId, customerId)
    //   .then((res) => {
    //     if (res && res.length > 0) {
    //       setAllHosts(res);
    //     }
    //   })
    //   .finally(() => {
    //     setLoadingHosts(false);
    //   });
  };

  const handleStartRecording = () => {
    startRecording()
      .then((res) => {
        if (res) {
          setVisitDataError({ ...visitDataError, purposeError: "" });
          setRecording(true);
          const timerId = setInterval(() => {
            setTimerWhileRecording((prevState) => ({
              id: timerId,
              timer: prevState.timer + 1,
            }));
          }, 1000);
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const handleTextInputsChange = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string | undefined
  ) => {
    const { name } = event.target as HTMLInputElement;
    const error: any = { ...visitDataError };
    const data: any = { ...visitData };
    data[name] = newValue;
    error[name] = "";
    setVisitDataError({ ...error });
    setVisitData({ ...data });
    const { length } = newValue as string;

    if (name === "visiting_person_vehicle_number") {
      if (length === 0) return;

      const res = vehicleNumberRegex.test(newValue as string);
      if (!res) {
        setVisitDataError({
          ...visitDataError,
          visiting_person_vehicle_number: "Accepted format, Eg- KA 11 EJ 55555",
        });
      }
      return;
    }

    if (newValue) {
      if (newValue.length > 50) {
        error[name] = "Maximum 50 characters allowed";
        setVisitDataError({ ...error });
      }
      const res = /^([A-Za-z]+\s?)*$/g.test(newValue as string);
      if (!res) {
        error[name] = "Only alphabets and 1 space allowed";
        setVisitDataError({ ...error });
      }
    }
  };

  const handlePhoneNumberChange = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string | undefined
  ) => {
    setVisitData({
      ...visitData,
      visiting_person_phone_number: newValue as string,
    });

    // const { length } = newValue as string;

    // if (length === 0) return;

    // if (newValue && newValue.length >= 10) {
    //   const res = phoneNumberRegex.test(newValue);
    //   if (!res) {
    //     setVisitDataError({
    //       ...visitDataError,
    //       visiting_person_phone_number: "Enter valid phone number",
    //     });
    //   }
    //   return;
    // }
    // const res = /^([0-9]*)$/g.test(newValue as string);
    // if (
    //   !res ||
    //   newValue?.includes("+") ||
    //   newValue?.includes("e") ||
    //   newValue?.includes("E")|| newValue?.includes(" ")
    // ) {
    //   setVisitDataError({
    //     ...visitDataError,
    //     visiting_person_phone_number: "Enter valid phone number",
    //   });
    // }
  };

  const handleHostChange = (newValue: any) => {
    setVisitDataError({ ...visitDataError, hostError: "" });
    setSelectedHost(newValue);
    setVisitData({
      ...visitData,
      hostId: newValue?.id,
      host_name: newValue?.name,
    });
  };

  const handleStopRecording = () => {
    stopRecording().then((res) => {
      if (res) {
        setRecording(false);
        clearInterval(timerWhileRecording.id);
        setAudioObj({ ...res });
      }
    });
  };

  const getFormattedHostDetailsForDropdown = () => {
    if (allHosts && allHosts.length > 0) {
      return allHosts.map((host: any) => ({
        ...host,
        value: host.id,
        label: `${host.name} ${host.phoneNumber}`,
      }));
    }
  };

  const handlePlayAudio = () => {
    if (audioObj?.audio) {
      setAudioPlaying(true);
      setAudioPaused(false);
      audioObj.audio.play();

      const intervalId = setInterval(() => {
        if (audioObj.audio.currentTime === audioObj.audio.duration) {
          clearInterval(intervalId);
          setAudioPlaying(false);
          setAudioPaused(false);
          setTimerWhilePlaying({ id: null, timer: 0 });
          return;
        }

        setTimerWhilePlaying({
          id: intervalId,
          timer: audioObj.audio?.currentTime,
        });
      }, 1000);
    }
  };

  const handlePauseAudio = () => {
    if (audioPlaying && audioObj?.audio) {
      audioObj.audio.pause();
      setAudioPaused(true);
      setAudioPlaying(false);
      clearInterval(timerWhilePlaying.id);
    }
  };

  const handleDeleteAudio = () => {
    setAudioObj(null);
    setAudioPlaying(false);
    setAudioPaused(false);
    if (audioObj) {
      audioObj.audio.src = "";
    }
    setTimerWhileRecording({
      id: null,
      timer: 0,
    });
  };

  const handleChangePurposeInputMode = () => {
    setIsPurposeInputMicMode(!isPurposeInputMicMode);
    setAudioObj(null);
    setNotes("");
  };

  const validateMandatoryField = () => {
    const data: any = { ...visitData };
    const error: any = { ...visitDataError };
    const mandatoryFields = ["visiting_person_name"];

    mandatoryFields.forEach((field) => {
      if (data[field] === "") {
        error[field] = "This field is required";
        return;
      }
    });

    if (!(audioObj || notes) && !recording) {
      error["purposeError"] = "Please input purpose of visit";
    }

    setVisitDataError({ ...error });
  };

  const isVisitDataError = () => {
    const error: any = { ...visitDataError };
    return !Object.keys(error).every((field) => !error[field]);
  };

  const isVisitDataValid = () => {
    const {
      customerId,
      hostId,
      visiting_person_name,
      visiting_person_phone_number,
    } = visitData;

    validateMandatoryField();
    if (
      customerId &&
      hostId &&
      visiting_person_name &&
      visiting_person_phone_number &&
      (audioObj || notes) &&
      !isVisitDataError()
    ) {
      return true;
    }
    return false;
  };

  // const getCreatedPersonRole = (user: UserType) => {
  //   if (user && user.role.includes("security_guard")) return "security_guard";
  //   if (user && user.role.includes("visitor")) return "visitor";
  // };

  const handleRequest = async () => {
    if (isVisitDataValid() && user && isLoggedIn) {
      const communicationObj = {
        createdOn: serverTimestamp(),
        created_person_id: user?.id,
        created_person_name: visitData.visiting_person_name,
        created_person_role: "visitor",
      } as CommunicationObjType;

      delete visitData?.id;
      updateVisitData(visitId as string, {
        ...visitData,
      });

      if (audioObj && audioObj.audioBlob && !notes) {
        const audioStorageRef = ref(
          storage,
          `audio/${
            visitData.visiting_person_name
          }_${new Date().toISOString()}_${visitId}.webm`
        );
        const uploadResult = await uploadBytes(
          audioStorageRef,
          audioObj.audioBlob
        );
        const url = await getDownloadURL(uploadResult.ref);
        communicationObj["type"] = "audio";
        communicationObj["data"] = url;
      } else {
        communicationObj["type"] = "notes";
        communicationObj["data"] = notes;
      }

      addCommunicationData({ ...communicationObj }, `Visits/${visitId}`).then(
        (addCommunicationResponse) => {
          if (addCommunicationResponse) {
            setShowRequestSentModal(true);
            setTimeout(() => {
              setShowRequestSentModal(false);
              setRequestSent(true);
              setTextToDisplay("You may close this tab now");
            }, 3000);
          }
        }
      );
    }
  };

  const reactSelectCustomStyles = {
    option: (baseStyles: any, state: any) => ({
      ...baseStyles,
      cursor: "pointer",
      color: state.isSelected ? "#ffffff" : "#605c5c",
      fontSize: "14px",
      fontWeight: "400",
      overflow: "hidden",
      textOverflow: "ellipsis",
      backgroundColor: state.isSelected ? "#6c70c5" : "#ffffff",
      "&:hover": {
        backgroundColor: "#6c70c50a",
        color: "#6c70c5",
      },
      "&:active": {
        backgroundColor: "#6c70c51a",
        color: "#6c70c5",
      },
    }),
    singleValue: (baseStyles: any, state: any) => ({
      ...baseStyles,
      fontSize: "14px",
      fontWeight: "400",
      letterSpacing: "0.5px",
      color: "#605C5C",
    }),
    valueContainer: (baseStyles: any, state: any) => ({
      ...baseStyles,
      padding: "0px 4px 0px 14px",
    }),
    placeholder: (baseStyles: any) => ({
      ...baseStyles,
      color: "#a09c9c",
      letterSpcing: "0.3px",
      overflow: "hidden",
      whiteSpace: "noWrap",
      textOverflow: "ellipsis",
    }),
    loadingIndicator: (baseStyles: any) => ({
      ...baseStyles,
      color: "#6c70c5",
    }),
  };

  return (
    <>
      {!requestSent && !textToDisplay && (
        <div className="appointment">
          <Dialog
            hidden={!showRequestSentModal}
            dialogContentProps={{
              type: DialogType.normal,
              showCloseButton: false,
            }}
            modalProps={{ isBlocking: true }}
          >
            <div className="request-sent-modal-container">
              <Image
                src={RequestSentGif}
                style={{ width: "180px", height: "180px" }}
              />
              <Text
                className="request-sent-text"
                // style={{ fontSize: "28px", fontWeight: 700, color: "#6C70C5" }}
              >
                Request Sent
              </Text>
            </div>
          </Dialog>
          <div className="screen-header">
            <Text variant="xxLarge">APPOINTMENT</Text>
          </div>
          <section className="text-input-section">
            <div className="input-field">
              <Text variant="mediumPlus">Name:</Text>
              <TextField
                name="visiting_person_name"
                value={visitData?.visiting_person_name}
                errorMessage={visitDataError?.visiting_person_name}
                invalid={!!visitDataError?.visiting_person_name}
                onChange={handleTextInputsChange}
                className={
                  visitDataError?.visiting_person_name ? "input-error" : ""
                }
                styles={{ root: { width: "200px" } }}
                placeholder={"Enter visitor name"}
              />
            </div>
            <div className="input-field">
              <Text variant="mediumPlus">Mobile Number:</Text>
              <TextField
                name="visiting_person_phone_number"
                className={"disabled-input"}
                disabled
                onRenderPrefix={() => (
                  <div>
                    <ImageIcon
                      imageProps={{
                        src: GreenCheckIcon,
                        width: "20px",
                        height: "20px",
                      }}
                    />
                  </div>
                )}
                value={visitData?.visiting_person_phone_number?.substring(3)}
                onChange={handlePhoneNumberChange}
                styles={{ root: { width: "200px" } }}
                placeholder={"Enter Phone number"}
              />
            </div>
            <div className="input-field">
              <Text variant="mediumPlus">From:</Text>
              <TextField
                name="visiting_person_from"
                value={visitData?.visiting_person_from}
                onChange={handleTextInputsChange}
                styles={{ root: { width: "200px" } }}
                placeholder={"Enter visitor's company"}
              />
            </div>
            <div className="input-field">
              <Text variant="mediumPlus">To Meet:</Text>
              <div>
                <Select
                  isClearable
                  isSearchable
                  value={selectedHost}
                  isLoading={loadingHosts}
                  isDisabled
                  className={`react-select-container ${
                    loadingHosts ? "loadingHosts" : ""
                  }`}
                  classNamePrefix="react-select"
                  components={{ DropdownIndicator: CustomDropdownIndicator }}
                  styles={{
                    ...reactSelectCustomStyles,
                    control: (baseStyles: any, state: any) => {
                      return {
                        ...baseStyles,
                        borderWidth: "0.5px",
                        height: "45px",
                        width: "200px",
                        borderRadius: "8px",
                        borderColor: visitDataError.hostError
                          ? "#fd0118"
                          : "#605c5c80",
                        "&:hover": {
                          cursor: "pointer",
                          borderColor: visitDataError.hostError
                            ? "#fd0118"
                            : "#605c5c80",
                        },
                      };
                    },
                    indicatorSeparator: (baseStyles: any) => ({
                      ...baseStyles,
                      backgroundColor: "#a09c9c",
                      display: loadingHosts ? "none" : "block",
                    }),
                  }}
                  formatOptionLabel={(option, { context }) =>
                    context === "value" ? `${option.name}` : option.label
                  }
                  placeholder={visitData?.host_name}
                  onChange={handleHostChange}
                  options={getFormattedHostDetailsForDropdown()}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: visitDataError?.hostError
                        ? "#fd0118"
                        : "#6c70c5",
                    },
                  })}
                />
                {visitDataError?.hostError && (
                  <Text
                    variant="small"
                    style={{
                      color: "#fd0118",
                      paddingTop: "5px",
                      display: "block",
                    }}
                  >
                    {visitDataError?.hostError}
                  </Text>
                )}
              </div>
            </div>
            <div className="input-field">
              <Text variant="mediumPlus">Vehicle No:</Text>
              <TextField
                name="visiting_person_vehicle_number"
                value={visitData?.visiting_person_vehicle_number}
                onChange={handleTextInputsChange}
                className={
                  visitDataError?.visiting_person_vehicle_number
                    ? "input-error"
                    : ""
                }
                errorMessage={visitDataError?.visiting_person_vehicle_number}
                invalid={!!visitDataError?.visiting_person_vehicle_number}
                styles={{ root: { width: "200px" } }}
                placeholder={"Enter vehicle number"}
              />
            </div>
          </section>
          <section className="purpose-section">
            <Text variant="mediumPlus">Purpose:</Text>
            <div
              className="purpose-input-container"
              style={{ height: isPurposeInputMicMode ? "200px" : "120px" }}
            >
              {!audioObj && isPurposeInputMicMode && (
                <div className="audio-record-mode-container ">
                  <div
                    className="mic-image-container "
                    onClick={
                      recording ? handleStopRecording : handleStartRecording
                    }
                  >
                    <Image
                      src={MicImage}
                      loading="lazy"
                      styles={{
                        root: { width: "62px", height: "62px" },
                      }}
                    />
                  </div>
                  {recording && <span className="recording-animation"></span>}
                </div>
              )}
              {!recording &&
                isPurposeInputMicMode &&
                audioObj &&
                audioObj.audioBlob?.size > 0 && (
                  <div className="audio-recorded-mode-container">
                    <div className="images-container">
                      <div className="top-images-container">
                        <Image width={60} height={60} src={AudioFileImage} />
                      </div>
                      <div className="bottom-images-container">
                        <IconButton
                          iconProps={{
                            imageProps: {
                              src: audioPlaying ? PauseIcon : PlayIcon,
                              styles: {
                                image: {
                                  width: "24px",
                                  height: "24px",
                                },
                              },
                            },
                          }}
                          styles={{
                            root: {
                              width: "24px",
                              height: "24px",
                            },
                            icon: {
                              width: "24px",
                              height: "24px",
                            },
                          }}
                          onClick={
                            audioPlaying ? handlePauseAudio : handlePlayAudio
                          }
                        />

                        <IconButton
                          iconProps={{
                            imageProps: {
                              src: TrashIcon,
                              styles: {
                                image: {
                                  width: "24px",
                                  height: "24px",
                                },
                              },
                            },
                          }}
                          styles={{
                            root: {
                              width: "24px",
                              height: "24px",
                            },
                            icon: {
                              width: "24px",
                              height: "24px",
                            },
                          }}
                          onClick={handleDeleteAudio}
                        />
                      </div>
                    </div>
                  </div>
                )}
              {!isPurposeInputMicMode && (
                <div className="purpose-textarea-container">
                  <TextField
                    multiline
                    autoFocus
                    resizable={false}
                    placeholder="Enter purpose of meeting.."
                    value={notes}
                    onChange={(e) => {
                      setVisitDataError({
                        ...visitDataError,
                        purposeError: "",
                      });
                      setNotes((e.target as HTMLInputElement).value);
                    }}
                    styles={{
                      root: {
                        width: "100%",
                        height: "100%",
                      },
                      fieldGroup: {
                        backgroundColor: "rgba(250, 250, 250, 1)",
                        height: "100%",
                        padding: "12px 16px",
                        border: 0,
                      },
                    }}
                  />
                </div>
              )}
            </div>
          </section>
          <section className="purpose-type-selection-section">
            <div className="audio-play-conatiner">
              {visitDataError?.purposeError && (
                <Text variant="small" style={{ color: "#fd0118" }}>
                  {visitDataError?.purposeError}
                </Text>
              )}
              {recording && <div className="red-dot"></div>}
              <Text variant="medium" className="audio-file-time">
                {recording && formatTime(timerWhileRecording.timer)}
                {!recording &&
                  !(audioPlaying || audioPaused) &&
                  audioObj &&
                  formatTime(Math.round(audioObj.audio?.duration))}
                {(audioPlaying || audioPaused) &&
                  audioObj &&
                  formatTime(Math.round(timerWhilePlaying.timer))}
              </Text>
              {audioPlaying && (
                <Image
                  src={SoundWaveGif}
                  styles={{
                    root: { width: "180px", height: "30px" },
                    image: { width: "180px", height: "30px" },
                  }}
                />
              )}
            </div>
            <div className="purpose-type-selection-conatiner">
              <IconButton
                width={32}
                height={32}
                disabled={!!(recording || audioObj)}
                iconProps={{
                  imageProps: {
                    src: isPurposeInputMicMode
                      ? recording || audioObj
                        ? DisabledKeyboardIcon
                        : KeyBoardIcon
                      : MicSmallIcon,
                    styles: { image: { width: "30px", height: "30px" } },
                  },
                }}
                styles={{
                  root: {
                    width: "32px",
                    height: "32px",
                  },
                  icon: {
                    width: "32px",
                    height: "32px",
                  },
                }}
                onClick={handleChangePurposeInputMode}
              />
            </div>
          </section>
          <section className="action-button">
            <PrimaryButton onClick={handleRequest}>Request</PrimaryButton>
          </section>
        </div>
      )}
      {textToDisplay && (
        <div
          style={{
            width: "100%",
            height: "200px",
            display: "flex",
            padding: "28px",
            textAlign: "center",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Text variant="large">{textToDisplay}</Text>
        </div>
      )}
    </>
  );
}
