import "./App.scss";
import { ThemeProvider } from "@fluentui/react";
import { theme } from "./theme";
import Main from "./main";
import UserContextProvider from "./context/UserContextProvider";

export default function App() {
  return (
    <ThemeProvider theme={theme}>
      <UserContextProvider>
        <Main />
      </UserContextProvider>
    </ThemeProvider>
  );
}
