import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyA0zV_2DIdfzPs7DE55zuhD3KiU-77Co7Y",
  authDomain: "visitor-management-765cd.firebaseapp.com",
  projectId: "visitor-management-765cd",
  storageBucket: "visitor-management-765cd.appspot.com",
  messagingSenderId: "366343869634",
  appId: "1:366343869634:web:39c93db7a0812fda25575b",
  measurementId: "G-3F7R8S8JZT",
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);
const storage = getStorage(app);

export { db, auth, storage };
