import React from "react";
import "./hostDetails.scss";
import ScreenHeader from "../../../Components/ScreenHeader/ScreenHeader";
import { Text, PrimaryButton } from "@fluentui/react";
import { useNavigate } from "react-router-dom";
import {
  CustomerType,
  HostType,
} from "../../../types/CommonTypes/CommonTypes.types";

export default function HostDetails({
  host,
  customerDetails,
}: {
  host: HostType;
  customerDetails: CustomerType;
}) {
  const navigate = useNavigate();

  const handleEditBtnClick = () => {
    navigate(`/hosts/${host?.id}/edit`);
  };
  return (
    <div className="host-details">
      <ScreenHeader title="HOST DETAILS" />
      <section className="host-details-section">
        <div className="host-details-field-container">
          <Text variant="mediumPlus">Name :</Text>
          <Text variant="medium" className="field-value">
            {host?.name}
          </Text>
        </div>
        <div className="host-details-field-container">
          <Text variant="mediumPlus">Contact Number :</Text>
          <Text variant="medium" className="field-value">
            {host?.phoneNumber.substring(3)}
          </Text>
        </div>
        {host?.email && (
          <div className="host-details-field-container">
            <Text variant="mediumPlus"> Email :</Text>
            <Text variant="medium" className="field-value">
              {host?.email}
            </Text>
          </div>
        )}

        <div className="host-details-field-container">
          <Text variant="mediumPlus">Customer :</Text>
          <Text variant="medium" className="field-value">
            {customerDetails?.companyName}
          </Text>
        </div>
        <div className="host-details-field-container">
          <Text variant="mediumPlus">Active :</Text>
          <Text variant="medium" className="field-value">
            {host?.isActive.toString()}
          </Text>
        </div>
      </section>
      <section className="action-button-section">
        <PrimaryButton
          iconProps={{ iconName: "Edit" }}
          styles={{
            textContainer: {
              marginLeft: "5px",
              flexGrow: "auto",
            },
          }}
          onClick={handleEditBtnClick}
        >
          Edit
        </PrimaryButton>
      </section>
    </div>
  );
}
