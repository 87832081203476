import React from "react";
import "./screenHeader.scss";
import { Text } from "@fluentui/react";

export default function ScreenHeader({ title }: { title: string }) {
  return (
    <div className="screen-header">
      <Text variant="xxLarge">{title}</Text>
    </div>
  );
}
