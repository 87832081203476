import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    const el = document.getElementById("main");
    el?.scrollTo(0, 0);
  }, [pathname]);
  return null;
}
