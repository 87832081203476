import {
  collection,
  getDocs,
  query,
  where,
  orderBy,
  setDoc,
  getDoc,
  doc,
  addDoc,
} from "firebase/firestore";
import { db } from "../firebase-config";
import { HostType } from "../types/CommonTypes/CommonTypes.types";

const hostsColRef = collection(db, "Hosts");

const getHosts = (clientId: string) => {
  const q = query(
    hostsColRef,
    where("clientId", "==", clientId),
    orderBy("name")
  );

  return getDocs(q).then((querySnapShot) => {
    return querySnapShot.docs.map((item) => ({ ...item.data(), id: item.id }));
  });
};

const getActiveHostsByClientId = async (clientId: string) => {
  const q = query(
    hostsColRef,
    where("clientId", "==", clientId),
    where("isActive", "==", true),
    orderBy("name")
  );

  return await getDocs(q).then((querySnapShot) => {
    return querySnapShot.docs.map((item) => ({ ...item.data(), id: item.id }));
  });
};

const getHostDetailsById = async (id: string) => {
  const hostDocRef = doc(db, "Hosts", id);
  return getDoc(hostDocRef).then((docSnapshot) => {
    if (docSnapshot.exists()) {
      return { ...docSnapshot.data(), id: docSnapshot.id };
    } else {
      return null;
    }
  });
};

const getHostDetailsByPhoneNumber = async (phoneNumber: string) => {
  const q = query(hostsColRef, where("phoneNumber", "==", phoneNumber));
  return getDocs(q).then((querySnapShot) => {
    return querySnapShot.docs.map((item) => ({
      ...item.data(),
      id: item.id,
    }));
  });
};

const addHost = (data: HostType) => {
  return addDoc(hostsColRef, data);
};

const updateHostDetails = async (hostId: string, data: any) => {
  const hostDocRef = doc(db, "Hosts", hostId);
  return await setDoc(hostDocRef, data, { merge: true });
};

const getHostsByUserId = async (userId: string) => {
  const q = query(hostsColRef, where("userId", "==", userId));

  return await getDocs(q).then((querySnapShot) => {
    return querySnapShot.docs.map((item) => {
      console.log(item.data());
      return {
        ...item.data(),
        id: item.id,
      };
    });
  });
};

const checkHostExistsForCustomer = async (
  clientId: string,
  customerId: string,
  hostPhoneNumber: string
) => {
  const q = query(
    hostsColRef,
    where("clientId", "==", clientId),
    where("customerId", "==", customerId),
    where("phoneNumber", "==", hostPhoneNumber)
  );

  return await getDocs(q).then((querySnapshot) => {
    if (querySnapshot.size > 0) return true;
    return false;
  });
};

export {
  getHosts,
  getActiveHostsByClientId,
  addHost,
  getHostDetailsById,
  getHostsByUserId,
  updateHostDetails,
  getHostDetailsByPhoneNumber,
  checkHostExistsForCustomer,
};
