import React from "react";
import "./securityGuardCard.scss";
import { Text, Image } from "@fluentui/react";
import UserProfileImage from "../../assets/images/user-profile.svg";
import { UserType } from "../../types/CommonTypes/CommonTypes.types";

type SecurityGuardCardPropsType = {
  securityGuard: UserType;
  onClickSeeMore: (securityGuard: UserType) => void;
};

export default function SecurityGuardCard({
  securityGuard,
  onClickSeeMore,
}: SecurityGuardCardPropsType) {
  return (
    <div className="security-guard-card">
      <div className="security-image-details-container">
        <div className="image-container">
          <Image
            width={45}
            height={45}
            loading="lazy"
            src={UserProfileImage}
            styles={{
              root: { borderRadius: "50%" },
              image: { borderRadius: "50%" },
            }}
          />
        </div>
        <div className="security-details-container">
          <Text variant="xLarge" className="security-name">
            {securityGuard?.name}
          </Text>
          <Text variant="medium" className="security-phone-number">
            {securityGuard?.phoneNumber.substring(3)}
          </Text>
        </div>
      </div>
      <div className="see-more-btn-container">
        <Text
          variant="mediumPlus"
          className="see-more-btn"
          onClick={() => onClickSeeMore(securityGuard)}
        >
          View More
        </Text>
      </div>
    </div>
  );
}
