import React from "react";
import "./noDataCard.scss";
import { Image } from "@fluentui/react";
import NoDataImage from "../../assets/images/no-data.svg";

export default function NoDataCard() {
  return (
    <div className="no-data-card">
      <Image src={NoDataImage} styles={{ image: { maxWidth: "100%" } }} />
    </div>
  );
}
