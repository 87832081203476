import React from "react";
import "./hostCard.scss";
import UserProfileImage from "../../assets/images/user-profile.svg";
import { Text, Image } from "@fluentui/react";
import {
  CustomerType,
  HostType,
} from "../../types/CommonTypes/CommonTypes.types";

type HostCardPropsType = {
  host: HostType;
  CustomerDetails: CustomerType;
  onClickSeeMore: (host: HostType) => void;
};

export default function HostCard({
  host,
  CustomerDetails,
  onClickSeeMore,
}: HostCardPropsType) {
  return (
    <div className="host-card">
      <div className="host-image-details-container">
        <div className="image-container">
          <Image
            width={45}
            height={45}
            loading="lazy"
            src={UserProfileImage}
            styles={{
              root: { borderRadius: "50%" },
              image: { borderRadius: "50%" },
            }}
          />
        </div>
        <div className="host-details-container">
          <Text variant="xLarge" className="host-name">
            {host?.name}
          </Text>
          <Text variant="medium" className="host-phone-number">
            {host?.phoneNumber.substring(3)}
          </Text>{" "}
          <Text variant="medium" className="customer-name">
            {CustomerDetails?.companyName}
          </Text>
        </div>
      </div>
      <div className="see-more-btn-container">
        <Text
          variant="mediumPlus"
          className="see-more-btn"
          onClick={() => onClickSeeMore(host)}
        >
          View More
        </Text>
      </div>
    </div>
  );
}
