import React from "react";
import { useLocation, Navigate } from "react-router-dom";

function Invite() {
  const location = useLocation();
  return (
    <Navigate to={`/login?inviteId=${location.hash.substring(1)}`} replace />
  );
}

export default Invite;
