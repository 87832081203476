import React from "react";
import { useLocation, matchPath } from "react-router-dom";
import userRolesRoutesConfig from "../config/user -roles-routes-config.json";
import { UserRolesType } from "../types/CommonTypes/CommonTypes.types";

export default function useUserRoleForScreens() {
  const { pathname } = useLocation();
  const matchedPattern = Object.keys(userRolesRoutesConfig).find((key) => {
    const res = matchPath(key, pathname);
    return res;
  });

  const userRolesRoutes: any = { ...userRolesRoutesConfig };
  if (matchedPattern) {
    const res = userRolesRoutes[matchedPattern];
    if (res === "common_for_all") return "security_guard" as UserRolesType;
    return res as UserRolesType;
  }
  return "security_guard" as UserRolesType;
}
