import React, { useState, useEffect, useContext } from "react";
import "./topNavBar.scss";
import { useLocation, useNavigate, useMatch } from "react-router-dom";
import { Callout, IconButton, Text } from "@fluentui/react";
import NavigateBackIcon from "../../assets/images/navigate-back.svg";
import ProfileIcon from "../../assets/images/profile-icon.svg";
import { getClientData } from "../../services/clientService";
import ConfirmationDialog from "../ConfirmationDialog/ConfirmationDialog";
import { UserContext } from "../../context/UserContextProvider";
import { ClientType } from "../../types/CommonTypes/CommonTypes.types";
import styles from "../../assets/styles/variables.scss";
import useUserRoleForScreens from "../../hooks/useUserRoleForScreens";

export default function TopNavBar() {
  const { pathname } = useLocation();
  const [clientDetails, setClientDetails] = useState({} as ClientType);
  const [showLogoutConfirmationDialog, setShowLogoutConfirmationDialog] =
    useState(false);
  const [isCallOutVisible, setIsCallOutVisible] = useState(false);
  const { user, isLoggedIn, logoutUser } = useContext(UserContext);
  const [selectedColorForVerticalBar, setSelectedColorForVerticalBar] =
    useState("security-tag-color");
  const navigate = useNavigate();
  const userRoleForScreens = useUserRoleForScreens();

  useEffect(() => {
    if (userRoleForScreens === "admin") {
      setSelectedColorForVerticalBar("admin-tag-color");
    } else if (userRoleForScreens === "host") {
      setSelectedColorForVerticalBar("host-tag-color");
    } else if (userRoleForScreens === "visitor") {
      setSelectedColorForVerticalBar("visitor-tag-color");
    } else {
      setSelectedColorForVerticalBar("security-tag-color");
    }
  }, [userRoleForScreens]);

  useEffect(() => {
    if (user && isLoggedIn && user?.clientId) {
      getClientData(user.clientId).then((data) => {
        setClientDetails({ ...data } as ClientType);
      });
    }
  }, [user, isLoggedIn]);

  const toggleCallOut = () => {
    setIsCallOutVisible((prevState) => !prevState);
  };

  const handleLogout = () => {
    if (user && isLoggedIn) {
      logoutUser();
    }
  };

  const handleDialogsClose = () => {
    setShowLogoutConfirmationDialog(false);
  };

  return (
    <header id="header">
      <ConfirmationDialog
        open={showLogoutConfirmationDialog}
        onClose={handleDialogsClose}
        title={"Are you sure, Do you want to logout?"}
        primaryBtnText="Logout"
        onClickPrimaryButton={handleLogout}
      />
      <nav
        id="top-nav"
        className={`top-nav-bar ${
          pathname === "/home" || pathname === "/host"
            ? "nav_fixed"
            : "nav_sticky"
        }`}
      >
        {["/home", "/admin"].includes(pathname) ? (
          <div className="logo">{clientDetails.companyName}</div>
        ) : pathname === "/appointment/visitor" ? (
          <div></div>
        ) : (
          <IconButton
            iconProps={{
              imageProps: { src: NavigateBackIcon },
              // styles: {
              //   image: { width: "32px", height: "32px" },
              // },
            }}
            styles={{
              root: {
                width: "32px",
                height: "32px",
              },
              icon: {
                width: "32px",
                height: "32px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              },
            }}
            onClick={() => navigate(-1)}
          />
        )}

        <div className="profile-container">
          <IconButton
            onClick={toggleCallOut}
            id="profile"
            iconProps={{
              imageProps: { src: ProfileIcon },
              // styles: { image: { width: "32px", height: "32px" } },
            }}
            styles={{
              root: {
                width: "32px",
                height: "32px",
                borderRadius: "50%",
              },
              icon: {
                width: "32px",
                height: "32px",
              },
            }}
          />
          <div
            className="vertical-bar"
            style={{ background: styles[selectedColorForVerticalBar] }}
          ></div>
          {isCallOutVisible && (
            <Callout
              target={document.getElementById("profile")}
              onDismiss={toggleCallOut}
              isBeakVisible={false}
              gapSpace={10}
              styles={{
                root: {
                  width: "100px",
                  borderRadius: "8px",
                  boxShadow: "0 6px 24px rgba(0,0,0,0.15)",
                },
              }}
            >
              <div className="callout-container">
                <div className="callout-menu account">
                  <Text variant="medium">Account</Text>
                </div>
                <div
                  className="callout-menu logout"
                  onClick={() => setShowLogoutConfirmationDialog(true)}
                >
                  <Text variant="medium">Logout</Text>
                </div>
              </div>
            </Callout>
          )}
        </div>
      </nav>
    </header>
  );
}
