import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useRef,
} from "react";
import "./home.scss";
import FilterIcon from "../../assets/images/filter.svg";
import SettingIcon from "../../assets/images/setting-icon.svg";
import CheckedIcon from "../../assets/images/checked.svg";
import { SearchBox, IconButton, DefaultButton, Spinner } from "@fluentui/react";
import SearchIcon from "../../assets/images/search-icon.svg";
import { useNavigate } from "react-router-dom";
import { Text, Callout, Checkbox } from "@fluentui/react";
import { BottomSheet } from "react-spring-bottom-sheet";
import VisitorCard from "../../Components/VisitorCard/VisitorCard";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  collection,
  query,
  where,
  orderBy,
  limit,
  startAfter,
  onSnapshot,
  Query,
} from "firebase/firestore";
import { UserContext } from "../../context/UserContextProvider";
import {
  VisitType,
  VisitStatusType,
} from "../../types/CommonTypes/CommonTypes.types";
import Communication from "../Communication/Communication";
import "react-spring-bottom-sheet/dist/style.css";
import { db } from "../../firebase-config";
import WrongUserCard from "../../Components/WrongUserCard/WrongUserCard";
import NoDataCard from "../../Components/NoDataCard/NoDataCard";

export default function Home() {
  const [isFilterCalloutVisible, setIsFilterCalloutVisible] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [lastVisible, setLastVisible] = useState<any>(null);
  const [hasMore, setHasMore] = useState(true);
  const [filteredVisits, setFilteredVisits] = useState([] as VisitType[]);
  const { user, isLoggedIn, loadingUser } = useContext(UserContext);
  const [filterByStatus, setFilterByStatus] = useState([] as VisitStatusType[]);
  const [isBottomSheetOpen, setIsBottomSheetOpen] = useState(false);
  const [selectedVisit, setSelectedVisit] = useState<VisitType | null>(null);
  const [textToDisplay, setTextToDisplay] = useState("");
  const navigate = useNavigate();
  const pageSize = process.env.REACT_APP_NO_OF_VISITS_TO_LOAD
    ? Number(process.env.REACT_APP_NO_OF_VISITS_TO_LOAD)
    : 10;

  const snapShotfns = useRef<any>([]);

  const toogleFilterCallout = () => {
    setIsFilterCalloutVisible(!isFilterCalloutVisible);
  };

  const main = document.getElementById("main");

  useEffect(() => {
    if (user && isLoggedIn && !loadingUser) {
      if (user?.role?.includes("security_guard")) {
        const q = query(
          collection(db, "Visits"),
          where("security_guard_id", "==", user?.id),
          orderBy("visit_start_time", "desc"),
          limit(pageSize)
        );

        const unsubscribe = onSnapshot(q, (querySnapshot) => {
          const lastVisibleLocal =
            querySnapshot.docs[querySnapshot.docs.length - 1];
          const data = querySnapshot.docs.map((item) => ({
            ...item.data(),
            id: item.id,
          }));
          setLastVisible(lastVisibleLocal);
          setFilteredVisits([...data] as VisitType[]);
        });

        return () => {
          unsubscribe();
        };
      }
      setTextToDisplay(
        "This page can be accessed only by security guard or receptionist"
      );
    }
  }, [user, isLoggedIn, loadingUser]);

  const updateInitialQueryData = (q: Query) => {
    const unsubscribe = onSnapshot(
      q,
      (querySnapshot) => {
        const lastVisibleLocal =
          querySnapshot.docs[querySnapshot.docs.length - 1];
        const data = querySnapshot.docs.map((item) => ({
          ...item.data(),
          id: item.id,
        }));
        snapShotfns.current = [...snapShotfns.current, unsubscribe];

        if (data.length === 0) {
          setHasMore(false);
          return;
        }
        setHasMore(true);
        setLastVisible(lastVisibleLocal);
        setFilteredVisits([...data] as VisitType[]);
      },
      (err) => {
        console.log(err);
      }
    );
  };

  useEffect(() => {
    if (
      main?.scrollTop === 0 &&
      user &&
      isLoggedIn &&
      !loadingUser &&
      user?.role?.includes("security_guard")
    ) {
      if (searchKeyword === "" && filterByStatus.length === 0) {
        const q = query(
          collection(db, "Visits"),
          where("security_guard_id", "==", user?.id),
          orderBy("visit_start_time", "desc"),
          limit(pageSize)
        );
        updateInitialQueryData(q);
        return;
      }

      if (filterByStatus.length > 0) {
        const q = query(
          collection(db, "Visits"),
          where("security_guard_id", "==", user?.id),
          where("status", "in", filterByStatus),
          orderBy("visit_start_time", "desc"),
          limit(pageSize)
        );
        updateInitialQueryData(q);
        return;
      }

      const q = query(
        collection(db, "Visits"),
        where("security_guard_id", "==", user?.id),
        where(
          "visiting_person_phone_number",
          "==",
          getFormattedSearchKeyword(searchKeyword)
        ),
        orderBy("visit_start_time", "desc"),
        limit(pageSize)
      );
      updateInitialQueryData(q);
    }
  }, [
    main?.scrollTop,
    user,
    isLoggedIn,
    loadingUser,
    searchKeyword,
    filterByStatus,
  ]);

  useEffect(() => {
    return () => {
      if (snapShotfns?.current?.length > 0) {
        snapShotfns.current.forEach((fn: any) => {
          fn();
        });
      }
    };
  }, []);

  const updateVisitsByQuery = (query: Query) => {
    const unsubscribe = onSnapshot(query, (querySnapshot) => {
      const lastVisibleLocal =
        querySnapshot.docs[querySnapshot.docs.length - 1];
      const data = querySnapshot.docs.map((item) => ({
        ...item.data(),
        id: item.id,
      }));
      snapShotfns.current = [...snapShotfns.current, unsubscribe];

      if (data.length === 0) {
        setHasMore(false);
        return;
      }
      setHasMore(true);
      setLastVisible(lastVisibleLocal);
      setFilteredVisits([...filteredVisits, ...data] as VisitType[]);
    });
  };

  const fetchMoreVisits = useCallback(() => {
    if (user && isLoggedIn && !loadingUser) {
      if (searchKeyword === "" && filterByStatus.length === 0) {
        const q = query(
          collection(db, "Visits"),
          where("security_guard_id", "==", user?.id),
          orderBy("visit_start_time", "desc"),
          startAfter(lastVisible),
          limit(pageSize)
        );
        updateVisitsByQuery(q);
        return;
      }

      if (filterByStatus.length > 0 && lastVisible) {
        const q = query(
          collection(db, "Visits"),
          where("security_guard_id", "==", user?.id),
          where("status", "in", filterByStatus),
          orderBy("visit_start_time", "desc"),
          startAfter(lastVisible),
          limit(pageSize)
        );
        updateVisitsByQuery(q);
        return;
      }

      if (searchKeyword && lastVisible) {
        const q = query(
          collection(db, "Visits"),
          where("security_guard_id", "==", user?.id),
          where(
            "visiting_person_phone_number",
            "==",
            getFormattedSearchKeyword(searchKeyword)
          ),
          orderBy("visit_start_time", "desc"),
          startAfter(lastVisible),
          limit(pageSize)
        );
        updateVisitsByQuery(q);
        return;
      }
    }
  }, [
    user,
    isLoggedIn,
    loadingUser,
    lastVisible,
    searchKeyword,
    filterByStatus,
  ]);

  const getFormattedSearchKeyword = (searchKeyword: string) => {
    if (searchKeyword.trim().startsWith("+91")) {
      return searchKeyword;
    } else {
      return `+91${searchKeyword.trim()}`;
    }
  };

  const getFormattedVisitData = (visits: VisitType[]) => {
    if (visits && visits.length) {
      return visits
        .sort(
          (a, b) =>
            b.visit_start_time.toDate().getTime() -
            a.visit_start_time.toDate().getTime()
        )
        .reduce((obj: any, visit: VisitType) => {
          const visitDateString = new Date(
            visit.visit_start_time.toDate()
          ).toDateString();
          if (obj[visitDateString]) {
            obj[visitDateString].push(visit);
            return obj;
          }

          obj[visitDateString] = [{ ...visit }];
          return obj;
        }, {});
    }
  };

  const formatDayDifference = (dateString: string) => {
    const now = new Date();
    const date = new Date(dateString);

    const diffInDays = Math.floor(
      (now.getTime() - date.getTime()) / (1000 * 60 * 60 * 24)
    );
    if (diffInDays === 0) return "Today:";
    if (diffInDays === 1) return "Yesterday:";
    return null;
  };

  const formatDate = (dateString: string) => {
    const dateObj = new Date(dateString);
    const days = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];

    const day = days[dateObj.getDay()];
    const date = dateObj.getDate();
    const month = dateObj.getMonth() + 1;
    const year = dateObj.getFullYear();

    return `${date}-${month}-${year} | ${day}`;
  };

  const handleSearchInputChange = (
    event?: React.ChangeEvent<HTMLInputElement> | undefined,
    newValue?: string | undefined
  ) => {
    main?.scrollTo(0, 0);
    setFilteredVisits([]);
    setLastVisible(null);
    handleResetFilterByStatus();
    setSearchKeyword(newValue as string);
  };

  const handleCheckboxesChange = (
    ev?: React.FormEvent<HTMLInputElement | HTMLElement> | undefined,
    checked?: boolean | undefined
  ) => {
    main?.scrollTo(0, 0);
    setFilteredVisits([]);
    setSearchKeyword("");
    setLastVisible(null);
    const { name } = ev?.target as HTMLInputElement;
    if (checked) {
      setFilterByStatus([...filterByStatus, name as VisitStatusType]);
      return;
    }

    if (!checked) {
      if (filterByStatus.length) {
        const arr = filterByStatus.filter((status) => status !== name);
        setFilterByStatus([...arr]);
      }
    }
  };

  const handleResetFilterByStatus = () => {
    setFilterByStatus([]);
  };

  const onClickSeeMore = (visit: VisitType) => {
    setIsBottomSheetOpen(true);
    setSelectedVisit(visit);
  };

  const onDismissBottomSheet = () => {
    setIsBottomSheetOpen(false);
    setSelectedVisit(null);
  };

  return (
    <>
      {!textToDisplay && (
        <div className="home">
          <BottomSheet
            open={isBottomSheetOpen}
            onDismiss={onDismissBottomSheet}
            snapPoints={({ minHeight, maxHeight }) => [
              maxHeight * 0.75,
              maxHeight,
            ]}
            expandOnContentDrag
          >
            <Communication
              visit={selectedVisit as VisitType}
              onDismissBottomSheet={onDismissBottomSheet}
            />
          </BottomSheet>
          <section className="search-bar-section">
            <div className="search-bar-container">
              <SearchBox
                showIcon
                placeholder="Search by contact number"
                value={searchKeyword}
                onChange={handleSearchInputChange}
                iconProps={{ imageProps: { src: SearchIcon } }}
              />
              <div className="icons-container">
                <IconButton
                  id="filter-icon"
                  style={{ marginRight: "24px" }}
                  iconProps={{
                    imageProps: {
                      src: FilterIcon,
                      styles: {
                        image: {
                          width: "20px",
                          height: "20px",
                        },
                      },
                    },
                  }}
                  styles={{
                    root: {
                      width: "20px",
                      height: "20px",
                    },
                    icon: {
                      width: "20px",
                      height: "20px",
                    },
                  }}
                  onClick={toogleFilterCallout}
                />
                <IconButton
                  iconProps={{
                    imageProps: {
                      src: SettingIcon,
                      styles: {
                        image: {
                          width: "20px",
                          height: "20px",
                        },
                      },
                    },
                  }}
                  styles={{
                    root: {
                      width: "20px",
                      height: "20px",
                    },
                    icon: {
                      width: "20px",
                      height: "20px",
                    },
                  }}
                  onClick={() => {
                    navigate(`/settings`);
                  }}
                />
                {isFilterCalloutVisible && (
                  <Callout
                    target={document.getElementById("filter-icon")}
                    onDismiss={toogleFilterCallout}
                    directionalHint={8}
                    styles={{
                      root: {
                        width: "125px",
                        borderRadius: "8px",
                        boxShadow: "0 6px 24px rgba(0,0,0,0.15)",
                      },
                    }}
                  >
                    <div className="filter-callout-container">
                      <div className="filter-menu-item">
                        <Checkbox
                          label="Completed"
                          name="completed"
                          onChange={handleCheckboxesChange}
                          checked={filterByStatus.includes("completed")}
                          checkmarkIconProps={{
                            imageProps: { src: CheckedIcon },
                          }}
                        />
                      </div>
                      <div className="filter-menu-item">
                        <Checkbox
                          label="Approved"
                          name="approved"
                          onChange={handleCheckboxesChange}
                          checked={filterByStatus.includes("approved")}
                          checkmarkIconProps={{
                            imageProps: { src: CheckedIcon },
                          }}
                        />
                      </div>
                      <div className="filter-menu-item">
                        <Checkbox
                          label="Pending"
                          name="pending"
                          onChange={handleCheckboxesChange}
                          checked={filterByStatus.includes("pending")}
                          checkmarkIconProps={{
                            imageProps: { src: CheckedIcon },
                          }}
                        />
                      </div>
                      <div className="filter-menu-item">
                        <Checkbox
                          label="Restricted"
                          name="restricted"
                          onChange={handleCheckboxesChange}
                          checked={filterByStatus.includes("restricted")}
                          checkmarkIconProps={{
                            imageProps: { src: CheckedIcon },
                          }}
                        />
                      </div>
                      <div className="filter-callout-action">
                        <DefaultButton
                          onClick={handleResetFilterByStatus}
                          style={{
                            width: "100%",
                            height: "35px",
                            fontSize: "14px",
                            fontWeight: 400,
                            padding: "0px 8px",
                          }}
                        >
                          Reset all
                        </DefaultButton>
                      </div>
                    </div>
                  </Callout>
                )}
              </div>
            </div>
          </section>
          <section className="visitors-section">
            <div className="screen-header">
              <Text variant="xxLarge">VISITORS</Text>
            </div>
            <InfiniteScroll
              hasMore={hasMore}
              endMessage={
                filteredVisits?.length == 0 && !hasMore && <NoDataCard />
              }
              loader={
                <div
                  style={{
                    padding: "20px 0px",
                  }}
                >
                  <Spinner />
                </div>
              }
              next={fetchMoreVisits}
              scrollableTarget="main"
              dataLength={filteredVisits.length}
            >
              {filteredVisits?.length > 0 &&
                getFormattedVisitData(filteredVisits) &&
                Object.keys(getFormattedVisitData(filteredVisits)).length > 0 &&
                Object.keys(getFormattedVisitData(filteredVisits)).map(
                  (key) => (
                    <div className="day-wise-visitor-container" key={key}>
                      <div className="date">
                        <Text variant="mediumPlus">
                          {formatDayDifference(key)}
                          <Text variant="medium" style={{ marginLeft: "5px" }}>
                            {formatDate(key)}
                          </Text>
                        </Text>
                      </div>

                      {getFormattedVisitData(filteredVisits)[key].length > 0 &&
                        getFormattedVisitData(filteredVisits)[key].map(
                          (visit: VisitType) => (
                            <VisitorCard
                              key={visit.id}
                              visit={visit}
                              onClickSeeMore={onClickSeeMore}
                            />
                          )
                        )}
                    </div>
                  )
                )}
            </InfiniteScroll>
          </section>
          <section className="pagination-section"></section>
        </div>
      )}
      {textToDisplay && <WrongUserCard textToDisplay={textToDisplay} />}
    </>
  );
}
