import React from "react";
import "./visitorCard.scss";
import { Text, ActionButton } from "@fluentui/react";
import StatusBadge from "../StatusBadge/StatusBadge";
import { VisitType } from "../../types/CommonTypes/CommonTypes.types";

type VisitorCardPropsType = {
  visit: VisitType;
  onClickSeeMore: (visit: VisitType) => void;
  style?: React.CSSProperties;
};

export default function VisitorCard({
  visit,
  onClickSeeMore,
  style,
}: VisitorCardPropsType) {
  return (
    <div className="visitor-card" style={style}>
      <div className="left-container">
        <div className="visitor-name-id-container">
          <Text
            variant="medium"
            style={{ fontWeight: 600 }}
            className="visitor-name"
          >
            {visit?.visiting_person_name}
          </Text>
        </div>
        <div className="fields-container">
          <Text variant="mediumPlus">
            Contact:
            <Text variant="medium" style={{ marginLeft: "5px" }}>
              {visit?.visiting_person_phone_number.substring(3)}
            </Text>
          </Text>
        </div>
        {visit?.visiting_person_from && (
          <div className="fields-container">
            <Text variant="mediumPlus">
              From:
              <Text variant="medium" style={{ marginLeft: "5px" }}>
                {visit?.visiting_person_from}
              </Text>
            </Text>
          </div>
        )}
        <div className="fields-container">
          <Text variant="mediumPlus">
            To Meet:
            <Text variant="medium" style={{ marginLeft: "5px" }}>
              {visit?.host_name}
            </Text>
          </Text>
        </div>
        {visit?.visiting_person_vehicle_number && (
          <div className="fields-container">
            <Text variant="mediumPlus">
              Vehicle:
              <Text variant="medium" style={{ marginLeft: "5px" }}>
                {visit?.visiting_person_vehicle_number}
              </Text>
            </Text>
          </div>
        )}
      </div>
      <div className="right-container">
        <StatusBadge status={visit?.status} />
        <div className="see-more-btn-container">
          <Text
            variant="mediumPlus"
            className="see-more-btn"
            onClick={() => onClickSeeMore(visit as VisitType)}
          >
            See more
          </Text>
        </div>
      </div>
    </div>
  );
}
