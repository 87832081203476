import React, { useContext } from "react";
import "./notFound.scss";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../context/UserContextProvider";
import { Image, PrimaryButton } from "@fluentui/react";
import NotFoundImage from "../../assets/images/not-found.svg";

export default function NotFound() {
  const { isLoggedIn } = useContext(UserContext);
  const navigate = useNavigate();

  const handleGoBack = () => {
    if (isLoggedIn) {
      navigate(-1);
      return;
    }

    navigate("/login");
  };
  return (
    <div className="not-found">
      <div className="image-container">
        <Image
          width={300}
          height={300}
          src={NotFoundImage}
          loading="lazy"
          style={{ maxWidth: "300px" }}
        />
        <PrimaryButton
          text="Go Back"
          style={{ marginTop: "36px" }}
          onClick={handleGoBack}
        />
      </div>
    </div>
  );
}
