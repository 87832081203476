import {
  collection,
  addDoc,
  getDoc,
  getDocs,
  where,
  query,
  doc,
  setDoc,
  orderBy,
  Query,
  getCountFromServer,
} from "firebase/firestore";
import { db } from "../firebase-config";
import {
  VisitType,
  CommunicationObjType,
} from "../types/CommonTypes/CommonTypes.types";

const visitsColRef = collection(db, "Visits");

const addVisit = (data: VisitType) => {
  return addDoc(visitsColRef, data);
};

const addCommunicationData = (
  data: CommunicationObjType,
  documentPath: string
) => {
  return addDoc(collection(db, `${documentPath}/CommunicationData`), data);
};

const getAllVisitsQuery = (security_guard_id: string) => {
  return query(
    visitsColRef,
    where("security_guard_id", "==", security_guard_id),
    orderBy("visit_start_time", "desc")
  );
};

const getCommunicationData = (visitId: string) => {
  const communicationColRef = collection(
    db,
    `Visits/${visitId}/CommunicationData`
  );
  const q = query(communicationColRef, orderBy("createdOn", "asc"));
  return getDocs(q).then((querySnapShot) => {
    return querySnapShot.docs.map((item) => ({ ...item.data(), id: item.id }));
  });
};

const getVisitData = (visitId: string) => {
  return getDoc(doc(db, `Visits/${visitId}`)).then((snapShot) => {
    return { ...snapShot.data(), id: snapShot.id };
  });
};

const updateVisitData = (visitId: string, data: any) => {
  return setDoc(doc(db, `Visits/${visitId}`), data, { merge: true });
};

const getTotalVisitsCount = (security_guard_id: string) => {
  const q = query(
    visitsColRef,
    where("security_guard_id", "==", security_guard_id),
    orderBy("visit_start_time", "desc")
  );
  return getCountFromServer(q).then((snapShot) => snapShot.data().count);
};

const getVisitsByQuery = (query: Query) => {
  return getDocs(query).then((querySnapShot) => {
    const lastVisible = querySnapShot.docs[querySnapShot.docs.length - 1];
    const data = querySnapShot.docs.map((item) => ({
      ...item.data(),
      id: item.id,
    }));
    console.log(data);
    return { lastVisible, data };
  });
};

const getVisitsCountByQuery = (query: Query) => {
  return getCountFromServer(query).then((snapShot) => snapShot.data().count);
};

export {
  addVisit,
  updateVisitData,
  getVisitData,
  addCommunicationData,
  getAllVisitsQuery,
  getCommunicationData,
  getTotalVisitsCount,
  getVisitsByQuery,
  getVisitsCountByQuery,
};
