import React, { useState } from "react";
import "./inviteSecurityGuard.scss";
import { Pivot, PivotItem } from "@fluentui/react";
import SecurityGuardForm from "../SecurityGuardForm/SecurityGuardForm";
import ManageInvites from "../ManageInvites/ManageInvites";

export default function InviteSecurityGuard() {
  const [selectedTab, setSelectedTab] = useState(0);

  const handleTabClick = (item?: PivotItem) => {
    setSelectedTab(item?.props?.itemKey ? +item?.props?.itemKey : 0);
  };
  return (
    <div className="invite-security-guard">
      <Pivot selectedKey={String(selectedTab)} onLinkClick={handleTabClick}>
        <PivotItem headerText="Invite User" itemKey="0">
          <SecurityGuardForm setSelectedTab={setSelectedTab} />
        </PivotItem>
        <PivotItem headerText="View Invites" itemKey="1">
          <ManageInvites />
        </PivotItem>
      </Pivot>
    </div>
  );
}
