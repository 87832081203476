import React, { useState, useContext, useEffect } from "react";
import BottomNavBar from "./Components/BottomNavBar/BottomNavBar";
import { UserContext } from "./context/UserContextProvider";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import LoginPage from "./screens/LoginPage/LoginPage";
import Home from "./screens/Home/Home";
import PrivateRoute from "./Components/PrivateRoute/PrivateRoute";
import TopNavBar from "./Components/TopNavBar/TopNavBar";
import NotFound from "./screens/NotFound/NotFound";
import HostPage from "./screens/HostPage/HostPage";
import Appointment from "./screens/Appointment/Appointment";
import SettingsPage from "./screens/SettingsPage/SettingsPage";
import AppointmentVisitor from "./screens/Appointment/AppointmentVisitor";
import Invite from "./screens/Invite/Invite";
import Admin from "./screens/Admin/Admin";
import SignupPage from "./screens/SignupPage/SignupPage";
import ManageCustomers from "./screens/CustomerScreens/ManageCustomers/ManageCustomers";
import ManageSecurityGuards from "./screens/SecurityGuardScreens/ManageSecurityGuards/ManageSecurityGuards";
import ManageHosts from "./screens/HostsScreens/ManageHosts/ManageHosts";
import CustomerForm from "./screens/CustomerScreens/CustomerForm/CustomerForm";
import SecurityGuardForm from "./screens/SecurityGuardScreens/SecurityGuardForm/SecurityGuardForm";
import HostForm from "./screens/HostsScreens/HostForm/HostForm";
import InviteSecurityGuard from "./screens/SecurityGuardScreens/InviteSecurityGuard/InviteSecurityGuard";

export default function Main() {
  const { isLoggedIn, user, loadingUser, isAuthStateChecked } =
    useContext(UserContext);
  const { pathname } = useLocation();

  return (
    <div id="main" className="main">
      {isLoggedIn && pathname !== "/not-found" && <TopNavBar />}
      <div
        style={{
          margin:
            isLoggedIn &&
            isAuthStateChecked &&
            !loadingUser &&
            pathname !== "/not-found"
              ? "72px 0px 64px"
              : 0,
        }}
      >
        <Routes>
          <Route path="/" element={<Navigate to={"/home"} />} />
          <Route
            path="/login"
            element={isLoggedIn && isAuthStateChecked ? null : <LoginPage />}
          />
          <Route
            path="/register"
            element={isLoggedIn && isAuthStateChecked ? null : <SignupPage />}
          />
          <Route
            path="/home"
            element={
              <PrivateRoute>
                <Home />
              </PrivateRoute>
            }
          />
          <Route
            path="/appointment/create"
            element={
              <PrivateRoute>
                <Appointment />
              </PrivateRoute>
            }
          />
          <Route
            path="/settings"
            element={
              <PrivateRoute>
                <SettingsPage />
              </PrivateRoute>
            }
          />
          <Route
            path="/appointment/visitor"
            element={
              <PrivateRoute>
                <AppointmentVisitor />
              </PrivateRoute>
            }
          />
          <Route
            path="/host"
            element={
              <PrivateRoute>
                <HostPage />
              </PrivateRoute>
            }
          />
          <Route
            path="/admin"
            element={
              <PrivateRoute>
                <Admin />
              </PrivateRoute>
            }
          />
          <Route
            path="/customers/manage"
            element={
              <PrivateRoute>
                <ManageCustomers />
              </PrivateRoute>
            }
          />
          <Route
            path="/customers/create"
            element={
              <PrivateRoute>
                <CustomerForm />
              </PrivateRoute>
            }
          />
          <Route
            path="/customers/:customerId/edit"
            element={
              <PrivateRoute>
                <CustomerForm />
              </PrivateRoute>
            }
          />
          <Route
            path="/security-guards/manage"
            element={
              <PrivateRoute>
                <ManageSecurityGuards />
              </PrivateRoute>
            }
          />
          <Route
            path="/security-guards/invite"
            element={
              <PrivateRoute>
                <InviteSecurityGuard />
              </PrivateRoute>
            }
          />
          <Route
            path="/security-guards/:securityGuardId/edit"
            element={
              <PrivateRoute>
                <SecurityGuardForm />
              </PrivateRoute>
            }
          />
          <Route
            path="/hosts/manage"
            element={
              <PrivateRoute>
                <ManageHosts />
              </PrivateRoute>
            }
          />
          <Route
            path="/hosts/create"
            element={
              <PrivateRoute>
                <HostForm />
              </PrivateRoute>
            }
          />
          <Route
            path="/hosts/:hostId/edit"
            element={
              <PrivateRoute>
                <HostForm />
              </PrivateRoute>
            }
          />
          <Route path="/invite" element={<Invite />} />
          <Route path="/not-found" element={<NotFound />} />
          <Route path="*" element={<Navigate to={"/not-found"} />} />
        </Routes>
      </div>
      {user &&
        isLoggedIn &&
        !loadingUser &&
        pathname == "/home" &&
        user?.role?.includes("security_guard") && <BottomNavBar />}
    </div>
  );
}
