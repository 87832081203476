import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useRef,
} from "react";
import "./hostPage.scss";
import {
  VisitStatusType,
  VisitType,
  HostType,
} from "../../types/CommonTypes/CommonTypes.types";
import FilterIcon from "../../assets/images/filter.svg";
import CheckedIcon from "../../assets/images/checked.svg";
import SearchIcon from "../../assets/images/search-icon.svg";
import InfiniteScroll from "react-infinite-scroll-component";
import VisitorCard from "../../Components/VisitorCard/VisitorCard";
import Communication from "../Communication/Communication";
import { BottomSheet } from "react-spring-bottom-sheet";
import {
  SearchBox,
  IconButton,
  Panel,
  Checkbox,
  DefaultButton,
  PrimaryButton,
  Text,
  Spinner,
  ChoiceGroup,
  DatePicker,
  IChoiceGroupOption,
} from "@fluentui/react";
import { db } from "../../firebase-config";
import {
  Query,
  query,
  where,
  startAfter,
  limit,
  collection,
  orderBy,
  serverTimestamp,
  onSnapshot,
} from "firebase/firestore";
import { getHostsByUserId } from "../../services/hostService";
import {
  getVisitsByQuery,
  updateVisitData,
} from "../../services/visitsService";
import Select from "react-select";
import { UserContext } from "./../../context/UserContextProvider";
import ConfirmationDialog from "../../Components/ConfirmationDialog/ConfirmationDialog";
import { getClientData } from "../../services/clientService";
import { getCustomerDetails } from "../../services/customerService";
import WrongUserCard from "../../Components/WrongUserCard/WrongUserCard";
import NoDataCard from "../../Components/NoDataCard/NoDataCard";

type VisitsChoiceType = "visitors_waiting" | "custom_date";

function HostPage() {
  const [hostsByUserId, setHostsByUserId] = useState([] as HostType[]);
  const [hostDetails, setHostDetails] = useState({} as HostType);
  console.log(
    "🚀 ~ file: HostPage.tsx:62 ~ HostPage ~ hostDetails",
    hostDetails
  );
  const [hasMore, setHasMore] = useState(true);
  const [lastVisible, setLastVisible] = useState<any>(null);
  const [visits, setVisits] = useState([] as VisitType[]);
  const [selectedVisit, setSelectedVisit] = useState<VisitType | null>(null);
  const [isSidePanelVisible, setIsSidePanelVisible] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [customDateRange, setCustomDateRange] = useState({
    from: new Date(new Date().setDate(new Date().getDate() - 7)),
    to: new Date(),
  });
  const [selectedVisitsChoice, setSelectedVisitsChoice] =
    useState<VisitsChoiceType | null>("visitors_waiting");
  const [filterByStatus, setFilterByStatus] = useState([] as VisitStatusType[]);
  const [selectedOrganizationByHost, setSelectedOrganizationByHost] =
    useState<any>({});
  const [showAllowConfirmationDialog, setShowAllowConfirmationDialog] =
    useState(false);
  const [showRestrictConfirmationDialog, setShowRestrictConfirmationDialog] =
    useState(false);
  const [isBottomSheetOpen, setIsBottomSheetOpen] = useState(false);
  const [
    loadingOrganizationDetailsForDropdown,
    setLoadingOrganizationDetailsForDropdown,
  ] = useState(false);
  const [
    formattedOrganizationDetailsForDropdown,
    setFormattedOrganizationDetailsForDropdown,
  ] = useState<any>([]);
  const [textToDisplay, setTextToDisplay] = useState("");

  const snapShotfns = useRef<any>([]);

  const { user, isLoggedIn, loadingUser } = useContext(UserContext);
  const pageSize = process.env.REACT_APP_NO_OF_VISITS_TO_LOAD
    ? Number(process.env.REACT_APP_NO_OF_VISITS_TO_LOAD)
    : 10;
  const main = document.getElementById("main");

  const visitsColRef = collection(db, "Visits");

  const dateforsearch = "visit_start_time";

  const choiceGroupOptions = [
    { key: "visitors_waiting", text: "Visitors Waiting" },
    { key: "custom_date", text: "Custom Date Range" },
  ];

  useEffect(() => {
    if (user && isLoggedIn && !loadingUser) {
      if (user.role.includes("host")) {
        setLoadingOrganizationDetailsForDropdown(true);
        getHostsByUserId(user.id as string)
          .then((res) => {
            console.log("🚀 ~ file: HostPage.tsx:123 ~ .then ~ res", res);
            setHostsByUserId([...res] as HostType[]);
          })
          .finally(() => setLoadingOrganizationDetailsForDropdown(false));
        return;
      }
      setTextToDisplay("This page can be accessed only by host");
    }
  }, [user, isLoggedIn, loadingUser]);

  useEffect(() => {
    if (hostDetails?.id) {
      const q = query(
        visitsColRef,
        where("hostId", "==", hostDetails.id),
        where("customerId", "==", hostDetails.customerId),
        where("status", "==", "pending"),
        orderBy("visit_start_time", "desc"),
        limit(pageSize)
      );
      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const lastVisibleLocal =
          querySnapshot.docs[querySnapshot.docs.length - 1];
        const data = querySnapshot.docs.map((item) => ({
          ...item.data(),
          id: item.id,
        }));
        setLastVisible(lastVisibleLocal);
        setVisits([...data] as VisitType[]);
      });

      return () => {
        unsubscribe();
      };
    }
  }, [hostDetails]);

  useEffect(() => {
    return () => {
      if (snapShotfns?.current?.length > 0) {
        snapShotfns.current.forEach((fn: any) => {
          fn();
        });
      }
    };
  }, []);

  useEffect(() => {
    if (hostsByUserId && hostsByUserId.length > 0) {
      setLoadingOrganizationDetailsForDropdown(true);
      const result = hostsByUserId.map(async (host: any) => {
        const clientData: any = await getClientData(host?.clientId);
        const customerData: any = await getCustomerDetails(host?.customerId);
        if (clientData && customerData) {
          return {
            host,
            value: host.id,
            customerName: customerData?.companyName,
            label: `${customerData?.companyName} - ${clientData?.companyName}`,
          };
        }
      });
      Promise.all(result)
        .then((res) => {
          const defaultHost = res[0];
          setHostDetails({ ...defaultHost?.host });
          setSelectedOrganizationByHost(defaultHost);
          setFormattedOrganizationDetailsForDropdown([...res]);
        })
        .finally(() => setLoadingOrganizationDetailsForDropdown(false));
    }
  }, [hostsByUserId]);

  const updateInitialQueryData = (q: Query) => {
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const lastVisibleLocal =
        querySnapshot.docs[querySnapshot.docs.length - 1];
      const data = querySnapshot.docs.map((item) => ({
        ...item.data(),
        id: item.id,
      }));
      snapShotfns.current = [...snapShotfns.current, unsubscribe];

      if (data.length === 0) {
        setHasMore(false);
        return;
      }
      setHasMore(true);
      setLastVisible(lastVisibleLocal);
      setVisits([...data] as VisitType[]);
    });
  };

  useEffect(() => {
    if (
      user &&
      isLoggedIn &&
      !loadingUser &&
      hostDetails?.id &&
      main?.scrollTop === 0 &&
      user?.role.includes("host")
    ) {
      if (searchKeyword === "" && selectedVisitsChoice === "visitors_waiting") {
        const q = query(
          visitsColRef,
          where("hostId", "==", hostDetails.id),
          where("customerId", "==", hostDetails.customerId),
          where("status", "==", "pending"),
          orderBy("visit_start_time", "desc"),
          limit(pageSize)
        );
        updateInitialQueryData(q);
        return;
      }
      if (searchKeyword) {
        const q = query(
          collection(db, "Visits"),
          where("hostId", "==", hostDetails.id),
          where("customerId", "==", hostDetails.customerId),
          where(
            "visiting_person_phone_number",
            "==",
            getFormattedSearchKeyword(searchKeyword)
          ),
          orderBy("visit_start_time", "desc"),
          limit(pageSize)
        );
        updateInitialQueryData(q);
        return;
      }
      if (selectedVisitsChoice === "custom_date") {
        if (filterByStatus.length === 0) {
          const q = query(
            visitsColRef,
            where("hostId", "==", hostDetails.id),
            where("customerId", "==", hostDetails.customerId),
            where(dateforsearch, ">=", customDateRange.from),
            where(dateforsearch, "<=", customDateRange.to),
            where("status", "in", [
              "approved",
              "pending",
              "completed",
              "rejected",
            ]),
            orderBy("visit_start_time", "desc"),
            limit(pageSize)
          );
          updateInitialQueryData(q);
          return;
        }
        if (filterByStatus.length > 0) {
          const q = query(
            visitsColRef,
            where("hostId", "==", hostDetails.id),
            where("customerId", "==", hostDetails.customerId),
            where(dateforsearch, ">=", customDateRange.from),
            where(dateforsearch, "<=", customDateRange.to),
            where("status", "in", filterByStatus),
            orderBy("visit_start_time", "desc"),
            limit(pageSize)
          );
          updateInitialQueryData(q);
          return;
        }
      }
    }
  }, [
    user,
    isLoggedIn,
    loadingUser,
    hostDetails,
    searchKeyword,
    filterByStatus,
    selectedVisitsChoice,
    customDateRange,
  ]);

  const updateVisitsByQuery = (query: Query) => {
    const unsubscribe = onSnapshot(query, (querySnapshot) => {
      const lastVisibleLocal =
        querySnapshot.docs[querySnapshot.docs.length - 1];
      const data = querySnapshot.docs.map((item) => ({
        ...item.data(),
        id: item.id,
      }));
      snapShotfns.current = [...snapShotfns.current, unsubscribe];

      if (data.length === 0) {
        setHasMore(false);
        return;
      }
      setHasMore(true);
      setLastVisible(lastVisibleLocal);
      setVisits([...visits, ...data] as VisitType[]);
    });
  };

  const fetchMoreVisits = useCallback(() => {
    if (
      user &&
      isLoggedIn &&
      !loadingUser &&
      user.role.includes("host") &&
      hostDetails?.id
    ) {
      if (searchKeyword === "" && selectedVisitsChoice === "visitors_waiting") {
        const q = query(
          visitsColRef,
          where("hostId", "==", hostDetails.id),
          where("customerId", "==", hostDetails.customerId),
          where("status", "==", "pending"),
          orderBy("visit_start_time", "desc"),
          startAfter(lastVisible),
          limit(pageSize)
        );
        updateVisitsByQuery(q);
        return;
      }
      if (searchKeyword) {
        const q = query(
          collection(db, "Visits"),
          where("hostId", "==", hostDetails.id),
          where("customerId", "==", hostDetails.customerId),
          where(
            "visiting_person_phone_number",
            "==",
            getFormattedSearchKeyword(searchKeyword)
          ),
          orderBy("visit_start_time", "desc"),
          startAfter(lastVisible),
          limit(pageSize)
        );
        updateVisitsByQuery(q);
        return;
      }
      if (selectedVisitsChoice === "custom_date") {
        if (filterByStatus.length === 0) {
          const q = query(
            visitsColRef,
            where("hostId", "==", hostDetails.id),
            where("customerId", "==", hostDetails.customerId),
            where(dateforsearch, ">=", customDateRange.from),
            where(dateforsearch, "<=", customDateRange.to),
            where("status", "in", [
              "approved",
              "pending",
              "completed",
              "rejected",
            ]),
            orderBy("visit_start_time", "desc"),
            startAfter(lastVisible),
            limit(pageSize)
          );
          updateVisitsByQuery(q);
          return;
        }
        if (filterByStatus.length > 0) {
          const q = query(
            visitsColRef,
            where("hostId", "==", hostDetails.id),
            where("customerId", "==", hostDetails.customerId),
            where(dateforsearch, ">=", customDateRange.from),
            where(dateforsearch, "<=", customDateRange.to),
            where("status", "in", filterByStatus),
            orderBy("visit_start_time", "desc"),
            startAfter(lastVisible),
            limit(pageSize)
          );
          updateVisitsByQuery(q);
          return;
        }
      }
    }
  }, [
    user,
    isLoggedIn,
    loadingUser,
    hostDetails,
    lastVisible,
    selectedVisitsChoice,
    searchKeyword,
    filterByStatus,
  ]);

  const getFormattedSearchKeyword = (searchKeyword: string) => {
    if (searchKeyword.trim().startsWith("+91")) {
      return searchKeyword;
    } else {
      return `+91${searchKeyword.trim()}`;
    }
  };

  const getFormattedVisitData = (visits: VisitType[]) => {
    if (visits && visits.length) {
      return visits
        .sort(
          (a, b) =>
            b.visit_start_time.toDate().getTime() -
            a.visit_start_time.toDate().getTime()
        )
        .reduce((obj: any, visit: VisitType) => {
          const visitDateString = new Date(
            visit.visit_start_time.toDate()
          ).toDateString();
          if (obj[visitDateString]) {
            obj[visitDateString].push(visit);
            return obj;
          }

          obj[visitDateString] = [{ ...visit }];
          return obj;
        }, {});
    }
  };

  const formatDayDifference = (dateString: string) => {
    const now = new Date();
    const date = new Date(dateString);

    const diffInDays = Math.floor(
      (now.getTime() - date.getTime()) / (1000 * 60 * 60 * 24)
    );
    if (diffInDays === 0) return "Today:";
    if (diffInDays === 1) return "Yesterday:";
    return null;
  };

  const formatDate = (dateString: string) => {
    const dateObj = new Date(dateString);
    const days = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];

    const day = days[dateObj.getDay()];
    const date = dateObj.getDate();
    const month = dateObj.getMonth() + 1;
    const year = dateObj.getFullYear();

    return `${date}-${month}-${year} | ${day}`;
  };

  const handleResetFilterByStatus = () => {
    setFilterByStatus([]);
  };

  const resetLastQuery = () => {
    main?.scrollTo(0, 0);
    setVisits([]);
    setLastVisible(null);
  };

  const handleChoiceGroupChange = (
    ev: React.FormEvent<HTMLElement | HTMLInputElement> | undefined,
    option: IChoiceGroupOption | undefined
  ) => {
    resetLastQuery();
    setSearchKeyword("");
    setSelectedVisitsChoice(option?.key as VisitsChoiceType);
  };

  const handleSearchInputChange = (
    event?: React.ChangeEvent<HTMLInputElement> | undefined,
    newValue?: string | undefined
  ) => {
    resetLastQuery();
    setSelectedVisitsChoice(null);
    setSearchKeyword(newValue as string);
  };

  const handleCheckboxesChange = (
    ev?: React.FormEvent<HTMLInputElement | HTMLElement> | undefined,
    checked?: boolean | undefined
  ) => {
    resetLastQuery();

    const { name } = ev?.target as HTMLInputElement;
    if (checked) {
      setFilterByStatus([...filterByStatus, name as VisitStatusType]);
      return;
    }

    if (!checked) {
      if (filterByStatus.length) {
        const arr = filterByStatus.filter((status) => status !== name);
        setFilterByStatus([...arr]);
      }
    }
  };

  const handleAllowVisitor = () => {
    if (
      user?.role.includes("host") &&
      selectedVisit?.id &&
      selectedVisit?.status === "pending"
    ) {
      updateVisitData(selectedVisit?.id, {
        host_respond_time: serverTimestamp(),
        status: "approved",
      }).then(() => handleDialogsClose());
    }
  };

  const handleCustomerDetailsChange = (newValue: any) => {
    setSelectedOrganizationByHost(newValue);
    setHostDetails({ ...newValue?.host });
  };

  const handleRestrictVisitor = () => {
    if (
      user?.role.includes("host") &&
      selectedVisit?.id &&
      selectedVisit?.status === "pending"
    ) {
      updateVisitData(selectedVisit?.id, {
        host_respond_time: serverTimestamp(),
        status: "restricted",
      }).then(() => handleDialogsClose());
    }
  };

  const handleDialogsClose = () => {
    setSelectedVisit(null);
    setShowAllowConfirmationDialog(false);
    setShowRestrictConfirmationDialog(false);
  };

  const toggleSidePanel = () => {
    setIsSidePanelVisible((prevState) => !prevState);
  };

  const handleSidePanelDismiss = () => {
    setIsSidePanelVisible(false);
  };

  const handleDateSelect = (
    date: Date | null | undefined,
    type: "from" | "to"
  ) => {
    resetLastQuery();
    const dateRangeObj = { ...customDateRange };
    dateRangeObj[type] =
      type == "from"
        ? (date as Date)
        : (new Date(date?.setHours(23, 59, 59, 999) as number) as Date);
    setCustomDateRange({ ...dateRangeObj });
  };

  const onClickSeeMore = (visit: VisitType) => {
    setIsBottomSheetOpen(true);
    setSelectedVisit(visit);
  };

  const onDismissBottomSheet = () => {
    setIsBottomSheetOpen(false);
    setSelectedVisit(null);
  };

  const reactSelectCustomStyles = {
    option: (baseStyles: any, state: any) => ({
      ...baseStyles,
      cursor: "pointer",
      color: state.isSelected ? "#ffffff" : "#605c5c",
      fontSize: "14px",
      fontWeight: "400",
      overflow: "hidden",
      textOverflow: "ellipsis",
      backgroundColor: state.isSelected ? "#6c70c5" : "#ffffff",
      "&:hover": {
        backgroundColor: "#6c70c50a",
        color: "#6c70c5",
      },
      "&:active": {
        backgroundColor: "#6c70c51a",
        color: "#6c70c5",
      },
    }),
    singleValue: (baseStyles: any, state: any) => ({
      ...baseStyles,
      fontSize: "14px",
      fontWeight: "400",
      letterSpacing: "0.5px",
      color: "#605C5C",
    }),
    valueContainer: (baseStyles: any, state: any) => ({
      ...baseStyles,
      padding: "0px 4px 0px 14px",
    }),
    placeholder: (baseStyles: any) => ({
      ...baseStyles,
      color: "#a09c9c",
      letterSpcing: "0.3px",
      overflow: "hidden",
      whiteSpace: "noWrap",
      textOverflow: "ellipsis",
    }),
    loadingIndicator: (baseStyles: any) => ({
      ...baseStyles,
      color: "#6c70c5",
    }),
  };

  return (
    <>
      {!textToDisplay && (
        <div className="host-page">
          <BottomSheet
            open={isBottomSheetOpen}
            onDismiss={onDismissBottomSheet}
            snapPoints={({ minHeight, maxHeight }) => [
              maxHeight * 0.75,
              maxHeight,
            ]}
            expandOnContentDrag
          >
            <Communication
              visit={selectedVisit as VisitType}
              onDismissBottomSheet={onDismissBottomSheet}
            />
          </BottomSheet>
          {showAllowConfirmationDialog && (
            <ConfirmationDialog
              open={showAllowConfirmationDialog}
              onClose={handleDialogsClose}
              title={`Are you sure, you want to allow ${selectedVisit?.visiting_person_name}?`}
              onClickPrimaryButton={handleAllowVisitor}
              primaryBtnText="Allow"
            />
          )}
          {showRestrictConfirmationDialog && (
            <ConfirmationDialog
              open={showRestrictConfirmationDialog}
              onClose={handleDialogsClose}
              title={`Are you sure, you want to restrict ${selectedVisit?.visiting_person_name}?`}
              onClickPrimaryButton={handleRestrictVisitor}
              primaryBtnText="Restrict"
            />
          )}
          <section className="search-bar-section">
            <div className="search-bar-container">
              <SearchBox
                showIcon
                placeholder="Search by contact number"
                value={searchKeyword}
                onChange={handleSearchInputChange}
                iconProps={{ imageProps: { src: SearchIcon } }}
              />
              <div className="icons-container">
                <IconButton
                  id="filter-icon"
                  iconProps={{
                    imageProps: {
                      src: FilterIcon,
                      styles: {
                        image: {
                          width: "20px",
                          height: "20px",
                        },
                      },
                    },
                  }}
                  styles={{
                    root: {
                      width: "20px",
                      height: "20px",
                    },
                    icon: {
                      width: "20px",
                      height: "20px",
                    },
                  }}
                  onClick={toggleSidePanel}
                />
                {isSidePanelVisible && (
                  <Panel
                    isOpen={isSidePanelVisible}
                    onDismiss={handleSidePanelDismiss}
                  >
                    <div className="side-panel-container">
                      <div className="customer-selection-container">
                        <Text
                          variant="large"
                          style={{
                            marginBottom: "14px",
                            display: "inline-block",
                            fontSize: "18px",
                            fontWeight: 600,
                          }}
                        >
                          Select your organization
                        </Text>
                        <Select
                          isClearable
                          isSearchable
                          isLoading={loadingOrganizationDetailsForDropdown}
                          value={selectedOrganizationByHost}
                          classNamePrefix="react-select"
                          placeholder="Select your organization"
                          options={formattedOrganizationDetailsForDropdown}
                          formatOptionLabel={(option: any, { context }) =>
                            context === "value"
                              ? `${option.customerName}`
                              : option.label
                          }
                          onChange={handleCustomerDetailsChange}
                          styles={{
                            ...reactSelectCustomStyles,
                            control: (baseStyles: any, state: any) => {
                              return {
                                ...baseStyles,
                                borderWidth: "0.5px",
                                height: "45px",
                                width: "100%",
                                borderRadius: "8px",
                                borderColor: "#605c5c80",
                                "&:hover": {
                                  cursor: "pointer",
                                  borderColor: "#605c5c80",
                                },
                              };
                            },
                          }}
                        />
                      </div>
                      <div className="visits-selection-container">
                        <Text
                          variant="large"
                          style={{
                            marginBottom: "8px",
                            display: "inline-block",
                            fontSize: "18px",
                            fontWeight: 600,
                          }}
                        >
                          Select visits
                        </Text>
                        <div className="choice-group-container">
                          <ChoiceGroup
                            options={choiceGroupOptions}
                            selectedKey={selectedVisitsChoice}
                            onChange={handleChoiceGroupChange}
                          />
                        </div>
                        {selectedVisitsChoice === "custom_date" && (
                          <div className="date-pickers-container">
                            <div className="date-picker-row">
                              <Text
                                variant="mediumPlus"
                                style={{ width: "40px" }}
                              >
                                From
                              </Text>
                              <DatePicker
                                value={customDateRange.from}
                                onSelectDate={(date) => {
                                  handleDateSelect(date, "from");
                                }}
                                maxDate={new Date()}
                                placeholder="Select from date"
                                className="date-picker"
                              />
                            </div>
                            <div
                              className="date-picker-row"
                              style={{ marginTop: "12px" }}
                            >
                              <Text
                                variant="mediumPlus"
                                style={{ width: "40px" }}
                              >
                                To
                              </Text>
                              <DatePicker
                                value={customDateRange.to}
                                onSelectDate={(date) => {
                                  handleDateSelect(date, "to");
                                }}
                                maxDate={new Date()}
                                placeholder="Select to date"
                                className="date-picker"
                              />
                            </div>
                          </div>
                        )}
                      </div>
                      {selectedVisitsChoice === "custom_date" && (
                        <div className="visits-filter-container">
                          <Text
                            variant="large"
                            style={{
                              fontSize: "18px",
                              fontWeight: 600,
                              marginBottom: "16px",
                              display: "inline-block",
                            }}
                          >
                            Filter by status
                          </Text>
                          <div className="filter-menu-item">
                            <Checkbox
                              label="Completed"
                              name="completed"
                              onChange={handleCheckboxesChange}
                              checked={filterByStatus.includes("completed")}
                              checkmarkIconProps={{
                                imageProps: { src: CheckedIcon },
                              }}
                            />
                          </div>
                          <div className="filter-menu-item">
                            <Checkbox
                              label="Approved"
                              name="approved"
                              onChange={handleCheckboxesChange}
                              checked={filterByStatus.includes("approved")}
                              checkmarkIconProps={{
                                imageProps: { src: CheckedIcon },
                              }}
                            />
                          </div>
                          <div className="filter-menu-item">
                            <Checkbox
                              label="Pending"
                              name="pending"
                              onChange={handleCheckboxesChange}
                              checked={filterByStatus.includes("pending")}
                              checkmarkIconProps={{
                                imageProps: { src: CheckedIcon },
                              }}
                            />
                          </div>
                          <div className="filter-menu-item">
                            <Checkbox
                              label="Restricted"
                              name="restricted"
                              onChange={handleCheckboxesChange}
                              checked={filterByStatus.includes("restricted")}
                              checkmarkIconProps={{
                                imageProps: { src: CheckedIcon },
                              }}
                            />
                          </div>
                          <div className="filter-callout-action">
                            <DefaultButton
                              onClick={handleResetFilterByStatus}
                              style={{
                                width: "100%",
                              }}
                            >
                              Reset all
                            </DefaultButton>
                          </div>
                        </div>
                      )}
                    </div>
                  </Panel>
                )}
              </div>
            </div>
          </section>
          <section className="visitors-section">
            <div className="screen-header">
              <Text variant="xxLarge">VISITORS WAITING</Text>
            </div>
            <InfiniteScroll
              hasMore={hasMore}
              endMessage={visits?.length == 0 && !hasMore && <NoDataCard />}
              loader={
                <div
                  style={{
                    padding: "20px 0px",
                  }}
                >
                  <Spinner />
                </div>
              }
              next={fetchMoreVisits}
              scrollableTarget="main"
              dataLength={visits.length}
            >
              {visits?.length > 0 &&
                getFormattedVisitData(visits) &&
                Object.keys(getFormattedVisitData(visits)).length > 0 &&
                Object.keys(getFormattedVisitData(visits)).map((key) => (
                  <div className="day-wise-visitor-container" key={key}>
                    <div className="date">
                      <Text variant="mediumPlus">
                        {formatDayDifference(key)}
                        <Text variant="medium" style={{ marginLeft: "5px" }}>
                          {formatDate(key)}
                        </Text>
                      </Text>
                    </div>

                    {getFormattedVisitData(visits)[key].length > 0 &&
                      getFormattedVisitData(visits)[key].map(
                        (visit: VisitType) => (
                          <div
                            className="visitor-card-wrapper"
                            style={{
                              borderBottom:
                                visit.status === "pending"
                                  ? "1px solid rgba(96, 92, 92, 0.15)"
                                  : "none",
                            }}
                            key={visit.id}
                          >
                            <VisitorCard
                              visit={visit}
                              onClickSeeMore={onClickSeeMore}
                              style={{
                                borderBottomWidth:
                                  visit.status === "pending" ? "0px" : "1px",
                              }}
                            />
                            {visit.status === "pending" && (
                              <div className="host-action-button-Container">
                                <DefaultButton
                                  style={{
                                    marginRight: "16px",
                                    height: "40px",
                                  }}
                                  onClick={() => {
                                    setSelectedVisit(visit);
                                    setShowRestrictConfirmationDialog(true);
                                  }}
                                >
                                  Restrict
                                </DefaultButton>
                                <PrimaryButton
                                  style={{ height: "40px" }}
                                  onClick={() => {
                                    setSelectedVisit(visit);
                                    setShowAllowConfirmationDialog(true);
                                  }}
                                >
                                  Allow
                                </PrimaryButton>
                              </div>
                            )}
                          </div>
                        )
                      )}
                  </div>
                ))}
            </InfiniteScroll>
          </section>
        </div>
      )}
      {textToDisplay && <WrongUserCard textToDisplay={textToDisplay} />}
    </>
  );
}

export default HostPage;
