import React, { useState, useEffect, useContext } from "react";
import "./customerForm.scss";
import ScreenHeader from "../../../Components/ScreenHeader/ScreenHeader";
import { PrimaryButton, Spinner, Text, TextField } from "@fluentui/react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { UserContext } from "../../../context/UserContextProvider";
import { geohashForLocation } from "geofire-common";
import { GeoPoint } from "firebase/firestore";
import {
  getCustomerDetails,
  addCustomer,
  updateCustomerDetails,
} from "../../../services/customerService";

export default function CustomerForm() {
  const { customerId } = useParams();
  const routeLocation = useLocation();
  const navigate = useNavigate();
  const isEditCustomerRoute = routeLocation.pathname.endsWith("/edit");
  const { user, isLoggedIn, loadingUser } = useContext(UserContext);
  const [editMode, setEditMode] = useState(false);
  console.log(
    "🚀 ~ file: CustomerForm.tsx:16 ~ CustomerForm ~ editMode",
    editMode
  );
  const [textToDisplay, setTextToDisplay] = useState("");
  const [loadingCustomerData, setLoadingCustomerData] = useState(false);
  const phoneNumberRegex = /^[0-9]{10}$/g;
  const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  const latRegex =
    /^(\+|-)?(?:90(?:(?:\.0{1,20})?)|(?:[0-9]|[1-8][0-9])(?:(?:\.[0-9]{1,20})?))$/;
  const longRegex =
    /^(\+|-)?(?:180(?:(?:\.0{1,20})?)|(?:[0-9]|[1-9][0-9]|1[0-7][0-9])(?:(?:\.[0-9]{1,20})?))$/;
  const [location, setLocation] = useState({ latitude: "", longitude: "" });
  const [locationError, setLocationError] = useState({
    latitude: "",
    longitude: "",
  });
  const [customerData, setCustomerData] = useState({
    companyName: "",
    contactName: "",
    contactNumber: "",
    contactEmail: [""],
    address: "",
  });

  const [customerDataError, setCustomerDataError] = useState({
    companyName: "",
    contactName: "",
    contactNumber: "",
    contactEmail: [""],
    address: "",
  });
  const [showEditOrCreateCustomerLoader, setShowEditOrCreateCustomerLoader] =
    useState(false);

  useEffect(() => {
    if (!loadingUser) {
      if (user && isLoggedIn && user?.role.includes("admin")) {
        setTextToDisplay("");
        if (customerId) {
          getCustomer(customerId);
        }
      } else {
        setTextToDisplay(
          "Wrong user!, This page can be accessed only by admin"
        );
      }
    }
  }, [user, isLoggedIn, loadingUser, customerId]);

  const getCustomer = (customerId: string) => {
    setLoadingCustomerData(true);
    getCustomerDetails(customerId)
      .then((res) => {
        if (res) {
          const { geopoint } = res?.location;
          setLocation({
            latitude: geopoint?.latitude,
            longitude: geopoint?.longitude,
          });
          delete res?.location;
          setCustomerData({
            ...res,
            contactNumber: res?.contactNumber.substring(3),
          });
          setEditMode(true);
        } else {
          setTextToDisplay("Customer doesn't exists");
          setEditMode(false);
        }
      })
      .finally(() => {
        setLoadingCustomerData(false);
      });
  };

  const handleNameInputChange = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string | undefined
  ) => {
    const { name } = event.target as HTMLInputElement;
    const error: any = { ...customerDataError };
    const data: any = { ...customerData };
    data[name] = newValue;
    error[name] = "";
    setCustomerDataError({ ...error });
    setCustomerData({ ...data });
    const { length } = newValue as string;

    if (newValue) {
      if (name === "companyName" && newValue.length > 100) {
        error[name] = "Maximum 100 characters allowed";
        setCustomerDataError({ ...error });
      }
      if (name === "contactName" && newValue.length > 50) {
        error[name] = "Maximum 50 characters allowed";
        setCustomerDataError({ ...error });
      }
      const res = /^([A-Za-z\s])*$/g.test(newValue as string);
      if (!res) {
        error[name] = "Only alphabets are allowed";
        setCustomerDataError({ ...error });
      }
    }
  };

  const handlePhoneNumberChange = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string | undefined
  ) => {
    setCustomerDataError({
      ...customerDataError,
      contactNumber: "",
    });
    setCustomerData({
      ...customerData,
      contactNumber: newValue as string,
    });

    const { length } = newValue as string;

    if (length === 0) return;

    if (newValue && newValue.length >= 10) {
      const res = phoneNumberRegex.test(newValue);
      if (!res) {
        setCustomerDataError({
          ...customerDataError,
          contactNumber: "Enter valid phone number",
        });
      }
      return;
    }
    const res = /^([0-9]*)$/g.test(newValue as string);
    if (
      !res ||
      newValue?.includes("+") ||
      newValue?.includes("e") ||
      newValue?.includes("E")
    ) {
      setCustomerDataError({
        ...customerDataError,
        contactNumber: "Enter valid phone number",
      });
    }
  };

  const handleEmailChange = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number,
    newValue?: string | undefined
  ) => {
    const data: any = { ...customerData };
    const error: any = { ...customerDataError };
    error["contactEmail"][index] = "";
    data.contactEmail[index] = newValue as string;
    setCustomerDataError({
      ...error,
    });
    setCustomerData({
      ...data,
    });
    if (newValue) {
      if (newValue.length > 50) {
        error["contactEmail"][index] = "Maximum 50 characters allowed";
        setCustomerDataError({ ...error });
      }
    }
  };

  const handleLocationChange = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string | undefined
  ) => {
    const { name } = event.target as HTMLInputElement;
    const data: any = { ...location };
    const error: any = { ...locationError };

    data[name] = newValue as string;
    error[name] = "";
    setLocation({ ...data });
    setLocationError({ ...error });

    const { length } = newValue as string;

    if (length === 0) return;

    if (name === "latitude") {
      const res = latRegex.test(newValue as string);
      if (!res) {
        setLocationError({ ...error, latitude: "Enter valid latitude" });
      }
    }

    if (name === "longitude") {
      const res = longRegex.test(newValue as string);
      if (!res) {
        setLocationError({ ...error, longitude: "Enter valid longitude" });
      }
    }
  };

  const handleAddressChange = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string | undefined
  ) => {
    setCustomerData({ ...customerData, address: newValue as string });
    setCustomerDataError({ ...customerDataError, address: "" });

    const { length } = newValue as string;

    if (length === 0) return;
    if (length > 500) {
      setCustomerDataError({
        ...customerDataError,
        address: "Maximum 500 characters allowed",
      });
    }
  };

  const handleAddEmail = () => {
    const contactEmailLocal = [...customerData?.contactEmail];
    const contactEmailErrorLocal = [...customerDataError?.contactEmail];
    contactEmailLocal.push("");
    contactEmailErrorLocal.push("");
    setCustomerData({ ...customerData, contactEmail: [...contactEmailLocal] });
    setCustomerDataError({
      ...customerDataError,
      contactEmail: [...contactEmailErrorLocal],
    });
  };

  const isThereErrorInData = (
    customerDataError: any,
    locationError: any,
    contactEmailErrorLocal: string[]
  ) => {
    const custDataError: any = customerDataError;
    const locError: any = locationError;

    const res1 = Object.keys(custDataError).every((key) => !custDataError[key]);

    const res2 = Object.keys(locError).every((key) => !locError[key]);

    const res3 = contactEmailErrorLocal.every((emailErr) => !emailErr);

    return res1 && res2 && res3;
  };

  const isCustomerDataValid = () => {
    const { customerErrorLocal, locationErrorLocal, contactEmailErrorLocal } =
      validateCustomerData();

    const res = isThereErrorInData(
      customerErrorLocal,
      locationErrorLocal,
      contactEmailErrorLocal
    );
    return res;
  };

  const validateCustomerData = () => {
    const customerLocal = { ...customerData } as any;
    const customerErrorLocal = { ...customerDataError } as any;

    delete customerLocal?.contactEmail;
    delete customerErrorLocal?.contactEmail;

    const contactEmailLocal = [...customerData?.contactEmail];

    const locationLocal = { ...location } as any;
    const locationErrorLocal = { ...locationError } as any;

    const mandatoryCustomerFields = Object.keys(customerLocal);
    const mandatoryLocationFields = Object.keys(locationLocal);

    mandatoryCustomerFields.forEach((field: any) => {
      customerLocal[field] = customerLocal[field].trim();

      if (customerLocal[field] === "") {
        customerErrorLocal[field] = "This field is required";
        return;
      }

      if (field === "contactNumber") {
        const res = phoneNumberRegex.test(customerLocal.contactNumber);
        if (!res) {
          customerErrorLocal.contactNumber = "Enter valid phone number";
        }
      }
    });
    mandatoryLocationFields.forEach((field: any) => {
      if (locationLocal[field] === "") {
        locationErrorLocal[field] = "This field is required";
        return;
      }
    });

    const filteredEmails = contactEmailLocal
      .filter((email) => email)
      .map((email) => email.trim());

    const filteredEmailsError = [""];
    if (filteredEmails?.length === 0) {
      filteredEmails.push("");
    }

    filteredEmails.forEach((email, index) => {
      if (email === "") {
        filteredEmailsError[index] = "This field is required";
        return;
      }
      const res = emailRegex.test(email);
      if (!res) {
        filteredEmailsError[index] = "Enter valid email";
      }
    });

    setCustomerData({ ...customerLocal, contactEmail: [...filteredEmails] });

    setCustomerDataError({
      ...customerErrorLocal,
      contactEmail: [...filteredEmailsError],
    });
    setLocationError({ ...locationErrorLocal });

    return {
      customerErrorLocal,
      locationErrorLocal,
      contactEmailErrorLocal: filteredEmailsError,
    };
  };

  const getGeoHashAndGeoPointsForLocation = (
    latitude: number,
    longitude: number
  ) => {
    const geohash = geohashForLocation([latitude, longitude], 12);

    const geopoint = new GeoPoint(latitude, longitude);

    return { geohash, geopoint };
  };

  const handleAddCustomer = async () => {
    const data = { ...customerData } as any;
    const locationLocal = { ...location };

    data.clientId = user?.clientId;
    data.contactNumber = `+91${customerData?.contactNumber}`;
    data.location = getGeoHashAndGeoPointsForLocation(
      +locationLocal?.latitude,
      +locationLocal?.longitude
    );

    try {
      const res = await addCustomer(data);
    } finally {
      setShowEditOrCreateCustomerLoader(false);
      navigate("/customers/manage");
    }
  };
  const handleUpdateCustomer = async () => {
    const data = { ...customerData } as any;
    const locationLocal = { ...location };
    data.contactNumber = `+91${customerData?.contactNumber}`;
    data.location = getGeoHashAndGeoPointsForLocation(
      +locationLocal?.latitude,
      +locationLocal?.longitude
    );

    delete data?.id;

    try {
      const res = await updateCustomerDetails(customerId as string, {
        ...data,
      });
    } finally {
      setShowEditOrCreateCustomerLoader(false);
      navigate("/customers/manage");
    }
  };

  const handleCreateOrEditCustomer = () => {
    if (
      !showEditOrCreateCustomerLoader &&
      isCustomerDataValid() &&
      user &&
      isLoggedIn &&
      user?.role?.includes("admin")
    ) {
      setShowEditOrCreateCustomerLoader(true);
      if (isEditCustomerRoute && editMode) {
        handleUpdateCustomer();
      } else {
        handleAddCustomer();
      }
    }
  };

  return (
    <>
      {!textToDisplay && (
        <div className="customer-form">
          <ScreenHeader
            title={isEditCustomerRoute ? "EDIT CUSTOMER" : "ADD CUSTOMER"}
          />
          {(loadingCustomerData || loadingUser) && (
            <div
              style={{
                padding: "20px 0px 0px",
              }}
            >
              <Spinner />
            </div>
          )}
          <section className="customer-form-input-section">
            <div className="input-field">
              <Text variant="mediumPlus">Company Name:</Text>
              <TextField
                name="companyName"
                value={customerData?.companyName}
                errorMessage={customerDataError?.companyName}
                invalid={!!customerDataError?.companyName}
                onChange={handleNameInputChange}
                className={customerDataError?.companyName ? "input-error" : ""}
                placeholder={"Enter company name"}
              />
            </div>
            <div className="input-field">
              <Text variant="mediumPlus">Contact Name:</Text>
              <TextField
                name="contactName"
                value={customerData?.contactName}
                errorMessage={customerDataError?.contactName}
                invalid={!!customerDataError?.contactName}
                onChange={handleNameInputChange}
                className={customerDataError?.contactName ? "input-error" : ""}
                placeholder={"Enter contact name"}
              />
            </div>
            <div className="input-field">
              <Text variant="mediumPlus">Contact Number:</Text>
              <TextField
                name="contactNumber"
                prefix="+91"
                value={customerData?.contactNumber}
                errorMessage={customerDataError?.contactNumber}
                invalid={!!customerDataError?.contactNumber}
                onChange={handlePhoneNumberChange}
                className={
                  customerDataError?.contactNumber
                    ? "input-error input-prefix"
                    : "input-prefix"
                }
                placeholder={"Enter contact number"}
              />
            </div>
            <div className="input-field location-input-field">
              <div className="location-text">
                <Text variant="mediumPlus">Location:</Text>
              </div>
              <div className="location-lat-long-container">
                <TextField
                  name="latitude"
                  value={location?.latitude}
                  errorMessage={locationError?.latitude}
                  invalid={!!locationError?.latitude}
                  onChange={handleLocationChange}
                  className={locationError?.latitude ? "input-error" : ""}
                  placeholder={"Enter latitude"}
                />
                <div style={{ marginTop: "12px" }}>
                  <TextField
                    name="longitude"
                    value={location?.longitude}
                    errorMessage={locationError?.longitude}
                    invalid={!!locationError?.longitude}
                    onChange={handleLocationChange}
                    className={locationError?.longitude ? "input-error" : ""}
                    placeholder={"Enter longitude"}
                  />
                </div>
              </div>
            </div>
            <div className="input-field email-input-field">
              <div className="email-text">
                <Text variant="mediumPlus">Contact Email:</Text>
              </div>
              <div className="email-input-conatiner">
                {customerData?.contactEmail?.length > 0 &&
                  customerData?.contactEmail.map((email, index) => (
                    <div className="email-input" key={index}>
                      <TextField
                        name="contactEmail"
                        value={customerData?.contactEmail[index]}
                        errorMessage={customerDataError?.contactEmail[index]}
                        invalid={!!customerDataError?.contactEmail[index]}
                        onChange={(event, newValue) =>
                          handleEmailChange(event, index, newValue)
                        }
                        className={
                          customerDataError?.contactEmail[index]
                            ? "input-error"
                            : ""
                        }
                        placeholder={`Enter contact email ${index + 1}`}
                      />
                    </div>
                  ))}
                {customerData?.contactEmail?.length < 3 && (
                  <div className="add-email-btn-wrapper">
                    <Text
                      variant="mediumPlus"
                      className="add-email-btn"
                      onClick={handleAddEmail}
                    >
                      Add Email
                    </Text>
                  </div>
                )}
              </div>
            </div>
          </section>
          <section className="address-input-section">
            <Text variant="mediumPlus"> Address:</Text>
            <div className="address-input-container">
              <div className="address-textarea-container">
                <TextField
                  multiline
                  resizable={false}
                  name="address"
                  placeholder="Enter company address"
                  value={customerData.address}
                  errorMessage={customerDataError?.address}
                  invalid={!!customerDataError?.address}
                  className={customerDataError?.address ? "input-error" : ""}
                  onChange={handleAddressChange}
                  styles={{
                    root: {
                      width: "100%",
                      height: "100%",
                    },
                    fieldGroup: {
                      backgroundColor: "rgba(250, 250, 250, 1)",
                      height: "100%",
                      padding: "12px 16px",
                      border: 0,
                    },
                  }}
                />
              </div>
            </div>
          </section>
          <section className="action-button-section">
            {showEditOrCreateCustomerLoader && (
              <div
                style={{
                  padding: "20px 0px 0px",
                }}
              >
                <Spinner />
              </div>
            )}
            <PrimaryButton
              iconProps={{
                iconName: isEditCustomerRoute ? "Save" : "Add",
              }}
              onClick={handleCreateOrEditCustomer}
              style={{ marginTop: "20px" }}
              styles={{
                textContainer: {
                  marginLeft: "5px",
                  flexGrow: "auto",
                },
              }}
            >
              {isEditCustomerRoute ? "Save" : "Add"}
            </PrimaryButton>
          </section>
        </div>
      )}
      {textToDisplay && (
        <div
          style={{
            width: "100%",
            height: "200px",
            display: "flex",
            padding: "28px",
            textAlign: "center",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Text variant="large">{textToDisplay}</Text>
        </div>
      )}
    </>
  );
}
