import React, { useContext } from "react";
import "./bottomNavBar.scss";
import { useNavigate, useLocation } from "react-router-dom";
import AddVisitorIcon from "../../assets/images/add-visitor.svg";
import { IconButton } from "@fluentui/react/lib/Button";

export default function BottomNavBar() {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const handleAddButtonClick = () => {
    if (pathname === "/appointment/create") {
      return;
    }
    navigate(`/appointment/create`);
  };
  return (
    <div className="bottom-nav-bar">
      <div
        style={{
          width: "48px",
          height: "48px",
        }}
      >
        <IconButton
          iconProps={{
            imageProps: {
              src: AddVisitorIcon,
              styles: {
                image: {
                  width: "48px",
                  height: "48px",
                },
              },
            },
          }}
          styles={{
            root: {
              width: "48px",
              height: "48px",
              borderRadius: "50%",
            },
            icon: {
              width: "48px",
              height: "48px",
            },
          }}
          onClick={handleAddButtonClick}
        />
      </div>
    </div>
  );
}
