import React from "react";
import "./wrongUserCard.scss";
import { Image, Text } from "@fluentui/react";
import WrongUserImage from "../../assets/images/wrong-user-image.svg";

export default function WrongUserCard({
  textToDisplay,
}: {
  textToDisplay: string;
}) {
  return (
    <div className="wrong-user-card">
      <div className="image-container">
        <Image src={WrongUserImage} styles={{ image: { maxWidth: "100%" } }} />
        <Text variant="xxLarge" className="text">
          Wrong user
        </Text>
      </div>
      <div className="text-container">
        <Text
          variant="mediumPlus"
          style={{ fontSize: "16px", textAlign: "center" }}
        >
          {textToDisplay}
        </Text>
      </div>
    </div>
  );
}
