import React, { useState, useEffect } from "react";
import ScreenHeader from "../../../Components/ScreenHeader/ScreenHeader";
import "./manageInvites.scss";
import InviteCard from "../../../Components/InviteCard/InviteCard";
import { InviteType } from "../../../types/CommonTypes/CommonTypes.types";
import useUser from "../../../hooks/useUser";
import { Spinner } from "@fluentui/react";
import {
  deleteInvite,
  getAllActiveInvites,
} from "../../../services/inviteService";
import NoDataCard from "../../../Components/NoDataCard/NoDataCard";
import ConfirmationDialog from "../../../Components/ConfirmationDialog/ConfirmationDialog";

export default function ManageInvites() {
  const { user, isLoggedIn, loadingUser } = useUser();
  const [isLoadingInvites, setIsLoadingInvites] = useState(false);
  const [activeInvites, setActiveInvites] = useState([] as InviteType[]);
  const [selectedInvite, setSelectedInvite] = useState<InviteType | null>(null);
  const [
    showDeleteInviteConfirmationDialog,
    setShowDeleteInviteConfirmationDialog,
  ] = useState(false);

  useEffect(() => {
    if (!loadingUser) {
      if (user && isLoggedIn && user?.role?.includes("admin")) {
        getActiveInvites(user?.clientId as string);
      }
    }
  }, [user, isLoggedIn, loadingUser]);

  const getActiveInvites = async (clientId: string) => {
    try {
      setIsLoadingInvites(true);
      const res = await getAllActiveInvites(clientId);
      if (res?.length) {
        setActiveInvites([...res]);
      }
    } finally {
      setIsLoadingInvites(false);
    }
  };

  const onClickDelete = (invite: InviteType) => {
    setShowDeleteInviteConfirmationDialog(true);
    setSelectedInvite(invite);
  };

  const onCloseDialog = () => {
    setShowDeleteInviteConfirmationDialog(false);
    setSelectedInvite(null);
  };
  const handleDeleteInvite = async () => {
    try {
      const res = await deleteInvite(selectedInvite?.id as string);
    } finally {
      setShowDeleteInviteConfirmationDialog(false);
      setSelectedInvite(null);
      getActiveInvites(user?.clientId as string);
    }
  };

  return (
    <div className="manage-invites">
      <ConfirmationDialog
        open={showDeleteInviteConfirmationDialog}
        onClose={onCloseDialog}
        title="Are you sure, you want to delete this invite?"
        primaryBtnText="Delete"
        onClickPrimaryButton={handleDeleteInvite}
        primaryBtnStyles={{ backgroundColor: "#fd0118", border: 0 }}
      />
      <ScreenHeader title="ALL INVITES" />
      <section className="invite-cards-list-section">
        {activeInvites?.length > 0 &&
          !isLoadingInvites &&
          activeInvites?.map((invite) => (
            <InviteCard
              key={invite?.id}
              invite={invite}
              onClickDelete={onClickDelete}
            />
          ))}
        {activeInvites?.length === 0 && !isLoadingInvites && !loadingUser && (
          <NoDataCard />
        )}
        {(isLoadingInvites || loadingUser) && (
          <div
            style={{
              padding: "20px 0px",
            }}
          >
            <Spinner />
          </div>
        )}
      </section>
    </div>
  );
}
