import React, { useState, useRef } from "react";

type AudioObjType = {
  audioBlob: Blob;
  audio: HTMLAudioElement;
};

export default function useVoiceRecorder() {
  const [mediaRecorder, setMediaRecorder] = useState<MediaRecorder | null>(
    null
  );
  const audioChunksRef = useRef<any>([]);
  const [streamBeingCaptured, setStreamBeingCaptured] =
    useState<MediaStream | null>(null);

  const startRecording = async () => {
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      return navigator.mediaDevices
        .getUserMedia({ audio: true })
        .then((stream) => {
          const mediaRecorderLocal = new MediaRecorder(stream);
          setMediaRecorder(mediaRecorderLocal);
          audioChunksRef.current = [];
          setStreamBeingCaptured(stream);

          mediaRecorderLocal.ondataavailable = (e) => {
            audioChunksRef.current = [...audioChunksRef.current, e.data];
          };

          mediaRecorderLocal.start();
          return true;
        });
    } else
      return Promise.reject(
        new Error("This device doesn't support voice recording")
      );
  };

  const stopRecording = () => {
    return new Promise((resolve: (value: AudioObjType) => void) => {
      const mimeType = mediaRecorder?.mimeType;
      const handleAudioDurationChange = (
        audioBlob: Blob,
        audio: HTMLAudioElement
      ) => {
        audio.currentTime = 1000000;
        if (audio.duration !== Infinity) {
          audio.removeEventListener("durationchange", () => {
            handleAudioDurationChange(audioBlob, audio);
          });
          audio.currentTime = 0;
          resolve({ audio, audioBlob });
          resetRecorder();
        }
      };

      if (mediaRecorder) {
        mediaRecorder.onstop = () => {
          const audioBlob = new Blob(audioChunksRef.current, {
            type: mimeType,
          });
          const audioUrl = URL.createObjectURL(audioBlob);
          const audio = new Audio(audioUrl);
          audio.addEventListener("durationchange", () => {
            handleAudioDurationChange(audioBlob, audio);
          });
        };
      }
      const tracks = streamBeingCaptured?.getTracks();
      if (tracks) {
        tracks.forEach((track) => track.stop());
      }

      if (mediaRecorder) {
        mediaRecorder.stop();
      }
    });
  };

  const resetRecorder = () => {
    audioChunksRef.current = [];
    setMediaRecorder(null);
    setStreamBeingCaptured(null);
  };

  return { startRecording, stopRecording };
}
