import React from "react";
import {
  Dialog,
  DialogType,
  DialogFooter,
  PrimaryButton,
  DefaultButton,
} from "@fluentui/react";
import "./confirmationDialog.scss";
import { ConfirmationDialogPropsType } from "./ConfirmationDialog.types";

export default function ConfirmationDialog({
  open,
  onClose,
  title,
  subText,
  onClickPrimaryButton,
  primaryBtnText,
  primaryBtnStyles,
}: ConfirmationDialogPropsType) {
  return (
    <Dialog
      hidden={!open}
      onDismiss={onClose}
      dialogContentProps={{ type: DialogType.normal, title, subText }}
      modalProps={{ isBlocking: false }}
      className="confirmation-dialog"
    >
      <DialogFooter>
        <DefaultButton style={{ height: "40px" }} onClick={onClose}>
          Cancel
        </DefaultButton>
        <PrimaryButton
          style={{ height: "40px", ...primaryBtnStyles }}
          onClick={onClickPrimaryButton}
        >
          {primaryBtnText}
        </PrimaryButton>
      </DialogFooter>
    </Dialog>
  );
}
