import React, { useState, useEffect, useContext } from "react";
import "./manageHosts.scss";
import ScreenHeader from "../../../Components/ScreenHeader/ScreenHeader";
import HostCard from "../../../Components/HostCard/HostCard";
import { BottomSheet } from "react-spring-bottom-sheet";
import { UserContext } from "../../../context/UserContextProvider";
import { getHosts } from "../../../services/hostService";
import { useNavigate } from "react-router-dom";
import {
  CustomerType,
  HostType,
} from "../../../types/CommonTypes/CommonTypes.types";
import { Text, Spinner, IconButton } from "@fluentui/react";
import AddVisitorIcon from "../../../assets/images/add-visitor.svg";
import HostDetails from "../HostDetails/HostDetails";
import { getCustomers } from "../../../services/customerService";
import WrongUserCard from "../../../Components/WrongUserCard/WrongUserCard";
import NoDataCard from "../../../Components/NoDataCard/NoDataCard";

export default function ManageHosts() {
  const navigate = useNavigate();
  const { user, isLoggedIn, loadingUser } = useContext(UserContext);
  const [textToDisplay, setTextToDisplay] = useState("");
  const [loadingHosts, setLoadingHosts] = useState(false);
  const [loadingCustomers, setLoadingCustomers] = useState(false);
  const [allCustomers, setAllCustomers] = useState([] as CustomerType[]);
  const [allHosts, setAllHosts] = useState([] as HostType[]);
  const [selectedHost, setSelectedHost] = useState<HostType | null>(null);
  const [isBottomSheetOpen, setIsBottomSheetOpen] = useState(false);

  useEffect(() => {
    if (!loadingUser) {
      if (user && isLoggedIn && user?.role?.includes("admin")) {
        setTextToDisplay("");
        getAllHosts(user?.clientId as string);
      } else {
        setTextToDisplay("This page can be accessed only by admin");
      }
    }
  }, [user, isLoggedIn, loadingUser]);

  useEffect(() => {
    if (user && isLoggedIn && allHosts?.length) {
      getAllCustomers(user?.clientId as string);
    }
  }, [user, isLoggedIn, allHosts]);

  const getAllHosts = (clientId: string) => {
    setLoadingHosts(true);
    getHosts(clientId)
      .then((res) => {
        setAllHosts(res as HostType[]);
      })
      .finally(() => {
        setLoadingHosts(false);
      });
  };

  const getAllCustomers = (clientId: string) => {
    setLoadingCustomers(true);
    getCustomers(clientId)
      .then((res) => {
        if (res) {
          setAllCustomers(res as CustomerType[]);
        }
      })
      .finally(() => {
        setLoadingCustomers(false);
      });
  };

  const onClickSeeMore = (host: HostType) => {
    setIsBottomSheetOpen(true);
    setSelectedHost(host);
  };

  const getCustomerDetailsById = (customerId: string) => {
    if (allCustomers?.length > 0) {
      const res = allCustomers.find((customer) => customer?.id === customerId);
      return res;
    }
  };

  const handleAddHostBtnClick = () => {
    navigate("/hosts/create");
  };

  const onDismissBottomSheet = () => {
    setIsBottomSheetOpen(false);
    setSelectedHost(null);
  };
  return (
    <>
      {!textToDisplay && (
        <div className="manage-hosts">
          <ScreenHeader title="MANAGE HOSTS" />
          <BottomSheet
            open={isBottomSheetOpen}
            onDismiss={onDismissBottomSheet}
            snapPoints={({ minHeight, maxHeight }) => [
              maxHeight * 0.75,
              maxHeight,
            ]}
            expandOnContentDrag
          >
            <HostDetails
              host={selectedHost as HostType}
              customerDetails={
                getCustomerDetailsById(
                  selectedHost?.customerId as string
                ) as CustomerType
              }
            />
          </BottomSheet>
          <section className="hosts-cards-list-section">
            {allHosts?.length > 0 &&
              !loadingHosts &&
              allHosts.map((host) => (
                <HostCard
                  key={host?.id}
                  host={host}
                  CustomerDetails={
                    getCustomerDetailsById(host?.customerId) as CustomerType
                  }
                  onClickSeeMore={onClickSeeMore}
                />
              ))}
            {allHosts?.length === 0 && !loadingHosts && !loadingUser && (
              <NoDataCard />
            )}
            {(loadingHosts || loadingCustomers || loadingUser) && (
              <div
                style={{
                  padding: "20px 0px",
                }}
              >
                <Spinner />
              </div>
            )}
          </section>
          <div className="add-host-btn">
            <IconButton
              iconProps={{
                imageProps: {
                  src: AddVisitorIcon,
                  styles: {
                    image: {
                      width: "48px",
                      height: "48px",
                    },
                  },
                },
              }}
              styles={{
                root: {
                  width: "48px",
                  height: "48px",
                  borderRadius: "50%",
                  backgroundColor: "#ffffff",
                },
                icon: {
                  width: "48px",
                  height: "48px",
                },
              }}
              onClick={handleAddHostBtnClick}
            />
          </div>
        </div>
      )}
      {textToDisplay && <WrongUserCard textToDisplay={textToDisplay} />}
    </>
  );
}
