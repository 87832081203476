import React, { useState, useEffect, useContext } from "react";
import "./manageCustomers.scss";
import { IconButton, Spinner, Text } from "@fluentui/react";
import { BottomSheet } from "react-spring-bottom-sheet";
import CustomerCard from "../../../Components/CustomerCard/CustomerCard";
import { UserContext } from "../../../context/UserContextProvider";
import { getCustomers } from "../../../services/customerService";
import { CustomerType } from "../../../types/CommonTypes/CommonTypes.types";
import CustomerDetails from "../CustomerDetails/CustomerDetails";
import ScreenHeader from "../../../Components/ScreenHeader/ScreenHeader";
import AddVisitorIcon from "../../../assets/images/add-visitor.svg";
import { useNavigate } from "react-router-dom";
import WrongUserCard from "../../../Components/WrongUserCard/WrongUserCard";
import NoDataCard from "../../../Components/NoDataCard/NoDataCard";

export default function ManageCustomers() {
  const navigate = useNavigate();
  const { user, isLoggedIn, loadingUser } = useContext(UserContext);
  const [allCustomers, setAllCustomers] = useState([] as CustomerType[]);
  const [selectedCustomer, setSelectedCustomer] = useState<CustomerType | null>(
    null
  );
  const [loadingCustomers, setLoadingCustomers] = useState(false);
  const [isBottomSheetOpen, setIsBottomSheetOpen] = useState(false);
  const [textToDisplay, setTextToDisplay] = useState("");

  useEffect(() => {
    if (!loadingUser) {
      if (user && isLoggedIn && user?.role.includes("admin")) {
        setTextToDisplay("");
        getAllCustomers(user?.clientId as string);
      } else {
        setTextToDisplay("This page can be accessed only by admin");
      }
    }
  }, [user, isLoggedIn, loadingUser]);

  const getAllCustomers = async (clientId: string) => {
    try {
      setLoadingCustomers(true);
      const res = await getCustomers(clientId);
      setAllCustomers([...res] as CustomerType[]);
    } catch (err) {
      console.log(
        "🚀 ~ file: ManageCustomers.tsx:31 ~ getAllCustomers ~ err",
        err
      );
    } finally {
      setLoadingCustomers(false);
    }
  };

  const onClickSeeMore = (customer: CustomerType) => {
    setIsBottomSheetOpen(true);
    setSelectedCustomer(customer);
  };

  const onDismissBottomSheet = () => {
    setSelectedCustomer(null);
    setIsBottomSheetOpen(false);
  };

  const handleAddCustomerBtnClick = () => {
    navigate("/customers/create");
  };

  return (
    <>
      {!textToDisplay && (
        <div className="manage-customers">
          <BottomSheet
            open={isBottomSheetOpen}
            onDismiss={onDismissBottomSheet}
            snapPoints={({ minHeight, maxHeight }) => [
              maxHeight * 0.75,
              maxHeight,
            ]}
            expandOnContentDrag
          >
            <CustomerDetails customer={selectedCustomer as CustomerType} />
          </BottomSheet>
          <ScreenHeader title="MANAGE CUSTOMERS" />
          <section className="customer-cards-list">
            {allCustomers?.length > 0 &&
              !loadingCustomers &&
              allCustomers.map((customer) => (
                <CustomerCard
                  key={customer.id}
                  customer={customer}
                  onClickSeeMore={onClickSeeMore}
                />
              ))}
            {!loadingCustomers &&
              !loadingUser &&
              allCustomers?.length === 0 && <NoDataCard />}
            {(loadingCustomers || loadingUser) && (
              <div
                style={{
                  padding: "20px 0px",
                }}
              >
                <Spinner />
              </div>
            )}
          </section>
          <div className="add-customer-btn">
            <IconButton
              iconProps={{
                imageProps: {
                  src: AddVisitorIcon,
                  styles: {
                    image: {
                      width: "48px",
                      height: "48px",
                    },
                  },
                },
              }}
              styles={{
                root: {
                  width: "48px",
                  height: "48px",
                  borderRadius: "50%",
                  backgroundColor: "#ffffff",
                },
                icon: {
                  width: "48px",
                  height: "48px",
                },
              }}
              onClick={handleAddCustomerBtnClick}
            />
          </div>
        </div>
      )}
      {textToDisplay && <WrongUserCard textToDisplay={textToDisplay} />}
    </>
  );
}
