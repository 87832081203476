import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { auth } from "../firebase-config";
import { signOut, onAuthStateChanged } from "firebase/auth";
import {
  UserRolesType,
  UserType,
} from "../types/CommonTypes/CommonTypes.types";
import { addUser, checkUserExists, getUser } from "../services/userService";
import { Spinner, SpinnerSize } from "@fluentui/react";
import {
  getHostDetailsByPhoneNumber,
  updateHostDetails,
} from "../services/hostService";
import { serverTimestamp } from "firebase/firestore";
import { deleteInvite } from "../services/inviteService";

type UserContextProviderPropsType = {
  children: React.ReactNode;
};

type UserContextType = {
  user: UserType | null;
  isAuthStateChecked: boolean;
  loadingUser: boolean;
  isLoggedIn: boolean;
  userErrorMessage: string;
  logoutUser: () => void;
  loginUser: (userData: any, navigateTo: any) => void;
};

export const UserContext = React.createContext({} as UserContextType);

export default function UserContextProvider({
  children,
}: UserContextProviderPropsType) {
  const location = useLocation();
  const [isAuthStateChecked, setIsAuthStateChecked] = useState(false);
  const [userId, setUserId] = useState<string | null>();
  const [user, setUser] = useState<UserType | null>(null);
  const [userErrorMessage, setUserErrorMessage] = useState("");
  const [loadingUser, setLoadingUser] = useState(true);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      console.log(
        "🚀 ~ file: UserContextProvider.tsx:40 ~ unsubscribe ~ user",
        user
      );
      setLoadingUser(true);
      setUserErrorMessage("");
      setIsAuthStateChecked(true);
      const loginDetails = JSON.parse(
        localStorage.getItem("login_details") as string
      );

      const selectedUserRole = loginDetails?.selectedUserRole;

      const invitedUserDetails = loginDetails?.invite;

      if (user && selectedUserRole) {
        checkUserExists(user?.uid)
          .then(async (isUserExists) => {
            if (isUserExists) {
              getUser(user?.uid)
                .then(async (currentUserData: any) => {
                  if (currentUserData?.isActive) {
                    if (currentUserData.role.includes(selectedUserRole)) {
                      console.log(currentUserData.role);
                      const path = getPathBasedByUserRole(selectedUserRole);

                      if (selectedUserRole === "host") {
                        const hostResponse = await getHostDetailsByPhoneNumber(
                          user?.phoneNumber as string
                        );
                        const hostsWithoutUserId = hostResponse.filter(
                          (hostDetails: any) => !hostDetails.userId
                        );
                        if (hostsWithoutUserId?.length > 0) {
                          Promise.all(
                            hostsWithoutUserId.map(async (host: any) => {
                              const hostDetailsCopy: any = { ...host };
                              delete hostDetailsCopy.id;
                              return await updateHostDetails(host.id, {
                                ...hostDetailsCopy,
                                userId: user?.uid,
                              });
                            })
                          ).then((res) => {
                            loginUser(currentUserData, path);
                            return;
                          });
                        } else {
                          loginUser(currentUserData, path);
                          return;
                        }
                      }
                      loginUser(currentUserData, path);
                    } else {
                      if (selectedUserRole === "security_guard") {
                        if (invitedUserDetails) {
                          if (
                            user?.phoneNumber ===
                            invitedUserDetails?.phoneNumber
                          ) {
                            console.log(invitedUserDetails);
                            const userDetails = {
                              clientId: invitedUserDetails?.clientId,
                              email: invitedUserDetails?.email,
                              isActive: true,
                              name: invitedUserDetails?.name,
                              phoneNumber: user?.phoneNumber,
                              role: removeDuplicateRolesFromUser([
                                ...invitedUserDetails?.role,
                                ...currentUserData?.role,
                              ]),
                            };
                            await deleteInvite(invitedUserDetails?.id);
                            updateUserDetailsAndLogin(
                              user?.uid,
                              {
                                ...userDetails,
                              },
                              selectedUserRole
                            );
                          } else {
                            handleGoBack(
                              "Wrong user, cannot access this invite"
                            );
                          }
                        } else {
                          handleGoBack("You cannot login as security guard");
                        }
                      } else if (selectedUserRole === "host") {
                        const hostResponse = await getHostDetailsByPhoneNumber(
                          user?.phoneNumber as string
                        );
                        const hostDetails = hostResponse[0];
                        if (hostDetails) {
                          const userDetails = {
                            phoneNumber: user?.phoneNumber,
                            isActive: true,
                            role: removeDuplicateRolesFromUser([
                              ...currentUserData?.role,
                              "host",
                            ]),
                          };
                          updateUserDetailsAndLogin(
                            user?.uid,
                            { ...userDetails },
                            selectedUserRole
                          );
                        } else {
                          handleGoBack("You cannot login as host");
                        }
                      } else if (selectedUserRole === "visitor") {
                        const userDetails = {
                          phoneNumber: user?.phoneNumber,
                          isActive: true,
                          role: removeDuplicateRolesFromUser([
                            ...currentUserData?.role,
                            "visitor",
                          ]),
                        };
                        updateUserDetailsAndLogin(
                          user?.uid,
                          { ...userDetails },
                          selectedUserRole
                        );
                      } else {
                        handleGoBack("You cannot login as admin");
                      }
                    }
                  } else {
                    handleGoBack(
                      "Your account is disabled, Please contact your organization."
                    );
                  }
                })
                .finally(() => setLoadingUser(false));
            } else {
              if (selectedUserRole === "security_guard") {
                if (invitedUserDetails) {
                  if (user?.phoneNumber === invitedUserDetails?.phoneNumber) {
                    console.log(invitedUserDetails);
                    const userDetails = {
                      clientId: invitedUserDetails?.clientId,
                      createdDate: serverTimestamp(),
                      email: invitedUserDetails?.email,
                      isActive: true,
                      name: invitedUserDetails?.name,
                      phoneNumber: user?.phoneNumber,
                      role: invitedUserDetails?.role,
                    };

                    await deleteInvite(invitedUserDetails?.id);

                    updateUserDetailsAndLogin(
                      user?.uid,
                      { ...userDetails },
                      selectedUserRole
                    );
                  } else {
                    handleGoBack("Wrong user, cannot access this invite");
                  }
                } else {
                  handleGoBack("Wrong user, you are not a security guard");
                }
              } else if (selectedUserRole === "host") {
                const hostResponse = await getHostDetailsByPhoneNumber(
                  user?.phoneNumber as string
                );
                const hostDetails = hostResponse[0];
                if (hostDetails) {
                  const userDetails = {
                    createdDate: serverTimestamp(),
                    phoneNumber: user?.phoneNumber,
                    isActive: true,
                    role: ["host"],
                  };

                  updateUserDetailsAndLogin(
                    user?.uid,
                    { ...userDetails },
                    selectedUserRole
                  );
                } else {
                  handleGoBack("Wrong user, you are not a host");
                }
              } else if (selectedUserRole === "visitor") {
                const userDetails = {
                  createdDate: serverTimestamp(),
                  phoneNumber: user?.phoneNumber,
                  isActive: true,
                  role: ["visitor"],
                };

                updateUserDetailsAndLogin(
                  user?.uid,
                  { ...userDetails },
                  selectedUserRole
                );
              } else {
                handleGoBack("User not found, Please register your company");
              }
            }
          })
          .finally(() => {
            localStorage.removeItem("login_details");
          });
      } else if (user && !selectedUserRole) {
        setUserId(user?.uid);
        setIsLoggedIn(true);
      } else {
        setLoadingUser(false);
        setIsLoggedIn(false);
      }
    });
    return unsubscribe;
  }, []);

  useEffect(() => {
    if (userId) {
      getUser(userId).then((res: any) => {
        if (res?.isActive) {
          setUser({ ...res } as UserType);
          localStorage.setItem("vm_user", JSON.stringify({ ...res }));
          setIsLoggedIn(true);
          setLoadingUser(false);
        } else {
          handleGoBack(
            "Your account is disabled, Please contact your organization."
          );
        }
      });
    }
  }, [userId]);

  const handleGoBack = (errorMessage: string) => {
    setUserErrorMessage(errorMessage);
    logoutUser();
  };

  const removeDuplicateRolesFromUser = (roles: UserRolesType[]) => {
    const arr = [...roles];
    return arr.filter((item, index) => arr.indexOf(item) === index);
  };

  const updateUserDetailsAndLogin = (
    userId: string,
    detailsToUpdate: any,
    selectedUserRole: UserRolesType
  ) => {
    addUser(userId, {
      ...detailsToUpdate,
    })
      .then(async () => {
        if (selectedUserRole == "host") {
          const hostResponse = await getHostDetailsByPhoneNumber(
            detailsToUpdate?.phoneNumber
          );
          const hostsWithoutUserId = hostResponse.filter(
            (hostDetails: any) => !hostDetails.userId
          );
          if (hostsWithoutUserId?.length > 0) {
            return Promise.all(
              hostsWithoutUserId.map(async (host: any) => {
                const hostDetailsCopy: any = { ...host };
                delete hostDetailsCopy.id;
                return await updateHostDetails(host.id, {
                  ...hostDetailsCopy,
                  userId: userId,
                });
              })
            );
          }
        }
      })
      .then(() => {
        getUser(userId)
          .then((data: any) => {
            if (data?.isActive && data?.role) {
              const path = getPathBasedByUserRole(selectedUserRole);
              loginUser(data, path);
            }
          })
          .finally(() => setLoadingUser(false));
      });
  };

  const getPathBasedByUserRole = (userRole: UserRolesType) => {
    const userFrom = JSON.parse(localStorage.getItem("user_from") as string);
    if (userRole.includes("admin")) return "/admin";
    if (userRole.includes("security_guard")) return "/home";
    if (userRole.includes("host")) return "/host";
    if (userRole.includes("visitor")) {
      if (
        userFrom?.from?.pathname === "/appointment/visitor" &&
        userFrom?.from?.search
      ) {
        return `${userFrom?.from.pathname}${userFrom?.from.search}`;
      } else return "appointment/visitor";
    }
  };

  const loginUser = (userData: any, navigateTo: any) => {
    setUser(userData);
    setIsLoggedIn(true);
    setLoadingUser(false);
    localStorage.setItem("vm_user", JSON.stringify(userData));
    navigate(navigateTo, { replace: true });
  };

  const logoutUser = (state?: any) => {
    signOut(auth).then(() => {
      setUserId(null);
      setIsLoggedIn(false);
      setLoadingUser(false);
      setUser(null);
      localStorage.removeItem("vm_user");
    });
  };

  return (
    <UserContext.Provider
      value={{
        user,
        userErrorMessage,
        isLoggedIn,
        loadingUser,
        isAuthStateChecked,
        logoutUser,
        loginUser,
      }}
    >
      {loadingUser && (
        <div className="loader-wrapper">
          <Spinner
            size={SpinnerSize.large}
            styles={{
              circle: {
                borderColor: "#6c70c545 #6c70c5 #6c70c5",
              },
            }}
          />
        </div>
      )}
      {children}
    </UserContext.Provider>
  );
}
