import React from "react";
import "./customerCard.scss";
import { Text } from "@fluentui/react";
import { CustomerType } from "../../types/CommonTypes/CommonTypes.types";

type CustomerCardPropsType = {
  customer: CustomerType;
  onClickSeeMore: (customer: CustomerType) => void;
};

export default function CustomerCard({
  customer,
  onClickSeeMore,
}: CustomerCardPropsType) {
  return (
    <div className="customer-card">
      <Text variant="xLarge">{customer?.companyName}</Text>
      <Text variant="medium" className="company-address">
        {customer?.address}
      </Text>
      <div className="see-more-btn-container">
        <Text
          variant="mediumPlus"
          className="see-more-btn"
          onClick={() => onClickSeeMore(customer)}
        >
          View More
        </Text>
      </div>
    </div>
  );
}
