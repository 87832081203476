export const formatTime = (seconds: number = 0) => {
  const date = new Date();
  date.setHours(0);
  date.setMinutes(0);
  date.setSeconds(seconds);

  const hhmmssFormat = date.toTimeString().substring(0, 8);

  return hhmmssFormat.split(":")[0] == "00"
    ? hhmmssFormat.substring(3)
    : hhmmssFormat;
};
