import React, { useState, useEffect, useContext } from "react";
import "./admin.scss";
import ManageCustomersIcon from "../../assets/images/manage-customers.svg";
import ManageSecurityGuards from "../../assets/images/manage-securityguard.svg";
import ManageHosts from "../../assets/images/manage-hosts.svg";
import { Text, ImageIcon } from "@fluentui/react";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../context/UserContextProvider";
import WrongUserCard from "./../../Components/WrongUserCard/WrongUserCard";

export default function Admin() {
  const navigate = useNavigate();
  const [textToDisplay, setTextToDisplay] = useState("");
  const { user, isLoggedIn, loadingUser } = useContext(UserContext);

  useEffect(() => {
    if (user && isLoggedIn && !loadingUser) {
      if (!user?.role?.includes("admin")) {
        setTextToDisplay("This page can be accessed only by admin");
      } else {
        setTextToDisplay("");
      }
    }
  }, [user, isLoggedIn, loadingUser]);
  return (
    <>
      {!textToDisplay && (
        <div className="admin">
          <div className="screen-header">
            <Text variant="xxLarge">ADMIN</Text>
          </div>
          <section className="admin-menu-section">
            <div
              className="admin-menu"
              onClick={() => navigate("/customers/manage")}
            >
              <ImageIcon
                imageProps={{
                  src: ManageCustomersIcon,
                  styles: { image: { width: "24px", height: "24px" } },
                }}
              />
              <Text
                variant="medium"
                style={{ marginLeft: "20px", color: "#0F5132" }}
              >
                Manage Customers
              </Text>
            </div>
            <div
              className="admin-menu"
              onClick={() => navigate("/security-guards/manage")}
            >
              <ImageIcon
                imageProps={{
                  src: ManageSecurityGuards,
                  styles: { image: { width: "24px", height: "24px" } },
                }}
              />
              <Text
                variant="medium"
                style={{ marginLeft: "20px", color: "#0F5132" }}
              >
                Manage Security Guards / Receptionists
              </Text>
            </div>
            <div
              className="admin-menu"
              onClick={() => navigate("/hosts/manage")}
            >
              <ImageIcon
                imageProps={{
                  src: ManageHosts,
                  styles: { image: { width: "24px", height: "24px" } },
                }}
              />
              <Text
                variant="medium"
                style={{ marginLeft: "20px", color: "#0F5132" }}
              >
                Manage Hosts
              </Text>
            </div>
          </section>
        </div>
      )}
      {textToDisplay && <WrongUserCard textToDisplay={textToDisplay} />}
    </>
  );
}
