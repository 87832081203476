import React, { useState, useEffect, useContext } from "react";
import "./loginPage.scss";
import { Image } from "@fluentui/react/lib/Image";
import OurVisitorsLogo from "../../assets/images/ourvisitors-logo.svg";
import LoginBackground1 from "../../assets/images/login-background-1.svg";
import LoginBackground2 from "../../assets/images/login-background-2.svg";
import {
  TextField,
  Text,
  DefaultButton,
  Spinner,
  ChoiceGroup,
  IChoiceGroupOption,
  MessageBar,
  MessageBarType,
} from "@fluentui/react";
import { PrimaryButton } from "@fluentui/react";
import OtpInput from "react-otp-input";
import { db, auth } from "../../firebase-config";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { formatTime } from "../../helpers/formatTime";
import { UserContext } from "./../../context/UserContextProvider";
import { useLocation, useSearchParams, Link } from "react-router-dom";
import {
  getInviteDetails,
  getRecentInvitesByPhoneNumber,
} from "../../services/inviteService";
import { hoursDifferenceFromNow } from "../../helpers/hour-difference-from-now";
import {
  UserRolesType,
  InviteType,
} from "../../types/CommonTypes/CommonTypes.types";

type TimerType = {
  id: any;
  timer: number;
};

export default function Login() {
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const inviteId = searchParams.get("inviteId");
  const [sentOtp, setSentOtp] = useState(false);
  const [appVerifier, setAppVerifier] = useState<any>(null);
  const [OtpConfirmationResult, setOtpConfirmationResult] = useState<any>(null);
  const [showLoader, setShowLoader] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState("");
  const [enteredWrongOtp, setEnteredWrongOtp] = useState(false);
  const [otpError, setOtpError] = useState("");
  const [invitedUserDetails, setInvitedUserDetails] =
    useState<InviteType | null>(null);
  const [selectedUserRole, setSelectedUserRole] =
    useState<UserRolesType>("visitor");
  const [ResendOtpTimer, setResendOtpTimer] = useState<TimerType>({
    id: null,
    timer: 60,
  });
  const [otp, setOtp] = useState("");
  const [userRoleError, setUserRoleError] = useState(
    location?.state?.errorMessage
  );
  const phoneNumberRegex = /^[0-9]{10}$/g;
  const { userErrorMessage } = useContext(UserContext);

  const inviteExpireTimeInHrs = 24;

  const choiceGroupOptions = [
    { key: "admin", text: "Admin" },
    { key: "host", text: "Host" },
    { key: "security_guard", text: "Security Guard / Receptionist" },
    { key: "visitor", text: "Visitor" },
  ];

  useEffect(() => {
    if (userErrorMessage) {
      setUserRoleError(userErrorMessage);
      handleGoBack();
    }
  }, [userErrorMessage]);

  useEffect(() => {
    if (location?.state) {
      if (location?.state?.from?.pathname === "/host") {
        setSelectedUserRole("host");
      }
    }
  }, [location]);

  useEffect(() => {
    if (inviteId && !sentOtp) {
      setSelectedUserRole("security_guard");
      getInviteDetails(inviteId).then((res) => {
        if (res) {
          if (
            inviteExpireTimeInHrs &&
            hoursDifferenceFromNow(res?.invitedDate.toDate()) <
              inviteExpireTimeInHrs
          ) {
            setInvitedUserDetails({ ...res });
          } else {
            setUserRoleError("Invite expired, Please contact your admin");
          }
        } else {
          setUserRoleError("Invalid invite, Please contact your admin");
        }
      });
    }
  }, [inviteId, sentOtp]);

  useEffect(() => {
    if (sentOtp && !inviteId && selectedUserRole === "security_guard") {
      getRecentInvitesByPhoneNumber(`+91${phoneNumber}`).then((res) => {
        if (res.length) {
          const invite = { ...res[0] };
          setInvitedUserDetails({ ...invite } as InviteType);
        }
      });
    }
  }, [sentOtp, inviteId, selectedUserRole, phoneNumber]);

  useEffect(() => {
    localStorage.setItem(
      "login_details",
      JSON.stringify({
        selectedUserRole,
        invite: invitedUserDetails,
      })
    );
    localStorage.setItem(
      "user_from",
      JSON.stringify({ from: location?.state?.from })
    );
  }, [invitedUserDetails, selectedUserRole, location]);

  const handlePhoneNumberChange = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ) => {
    setOtpError("");
    setPhoneNumberError("");
    setPhoneNumber(newValue as string);

    const { length } = newValue as string;

    if (length === 0) return;

    if (newValue && newValue.length >= 10) {
      const res = phoneNumberRegex.test(newValue);
      if (!res) {
        setPhoneNumberError("Please enter valid phone number");
      }
      return;
    }
    const res = /^([0-9]*)$/g.test(newValue as string);
    if (
      !res ||
      newValue?.includes("+") ||
      newValue?.includes("e") ||
      newValue?.includes("E") ||
      newValue?.includes(" ")
    ) {
      setPhoneNumberError("Please enter valid phone number");
    }
  };

  const generateRecaptchaVerifier = () => {
    if (!appVerifier) {
      const recaptchaVerifier = new RecaptchaVerifier(
        "recaptcha-container",
        {
          size: "invisible",
          callback: (response: any) => {
            console.log("reCaptcha Verified");
          },
        },
        auth
      );
      setAppVerifier(recaptchaVerifier);
      return recaptchaVerifier;
    }
  };

  const handleRequestOtp = () => {
    if (sentOtp && ResendOtpTimer.timer) return;
    if (phoneNumber == "" || phoneNumber.length !== 10) {
      setPhoneNumberError("Please enter valid phone number");
      return;
    }
    setOtpError("");
    if (phoneNumber.length === 10 && !phoneNumberError) {
      const recaptchaVerifier = appVerifier
        ? appVerifier
        : generateRecaptchaVerifier();

      setResendOtpTimer({ id: null, timer: 60 });
      setShowLoader(true);
      signInWithPhoneNumber(auth, "+91".concat(phoneNumber), recaptchaVerifier)
        .then((confirmationResult) => {
          console.log("otp sent");
          setUserRoleError("");
          setSentOtp(true);
          setOtpConfirmationResult(confirmationResult);
          const resendOtpInterval = setInterval(() => {
            setResendOtpTimer((prevState) => {
              if (prevState.timer <= 0) {
                clearInterval(prevState.id);
                return { id: null, timer: 0 };
              }
              return {
                id: resendOtpInterval,
                timer: prevState.timer - 1,
              };
            });
          }, 1000);
        })
        .catch((err) => {
          setOtpError(
            "There was an error in sending the OTP. Please try later"
          );
        })
        .finally(() => setShowLoader(false));
    }
  };

  const handleOtpChange = (otp: string) => {
    setOtp(otp);
    if (otp === "" || otp.length !== 6) return;
    setEnteredWrongOtp(false);
    OtpConfirmationResult?.confirm(otp)
      .then((res: any) => {
        clearInterval(ResendOtpTimer.id);
        setResendOtpTimer({ id: null, timer: 0 });
      })
      .catch((err: any) => {
        setEnteredWrongOtp(true);
      });
  };

  const handleChoiceGroupChange = (
    ev: React.FormEvent<HTMLElement | HTMLInputElement> | undefined,
    option: IChoiceGroupOption | undefined
  ) => {
    setSelectedUserRole(option?.key as UserRolesType);
  };

  const handleGoBack = () => {
    setSentOtp(false);
    setOtp("");
    setShowLoader(false);
    setOtpConfirmationResult(null);
    setEnteredWrongOtp(false);
    clearInterval(ResendOtpTimer.id);
  };

  return (
    <div className="login">
      <div className="login-wrapper">
        <div className="app-name-header">
          <Image
            src={OurVisitorsLogo}
            // styles={{
            //   image: { width: "120px", height: "100px", objectFit: "contain" },
            // }}
          />
        </div>
        {!sentOtp && (
          <div className="login-inner-container">
            <p className="app-subtitle">
              Simplified visitor tracking for <span>Offices,</span>{" "}
              <span>Institutions</span> and <span>Factories</span>
            </p>
            <div className="user-role-selection-container">
              <div style={{ textAlign: "center", marginBottom: "10px" }}>
                <Text variant="xxLarge">Login as</Text>
              </div>
              <ChoiceGroup
                options={choiceGroupOptions}
                selectedKey={selectedUserRole}
                onChange={handleChoiceGroupChange}
                className="user-role-choicegroup"
              />
            </div>
            <div className="sign-up-text">
              {/* <Text variant="medium">
                or,{" "}
                <Link to={"/register"} style={{ textDecoration: "none" }}>
                  <Text variant="mediumPlus" style={{ color: "#6C70C5" }}>
                    Register your business
                  </Text>
                </Link>
              </Text> */}
            </div>
            <div className="user-role-error-message-container">
              {userRoleError && (
                <MessageBar
                  isMultiline={!false}
                  messageBarType={MessageBarType.error}
                  styles={{ root: { maxWidth: "264px" } }}
                >
                  <Text variant="small">{userRoleError}</Text>
                </MessageBar>
              )}
            </div>
            <div className="login-input-container">
              <TextField
                onChange={handlePhoneNumberChange}
                placeholder="Enter your number"
                errorMessage={phoneNumberError}
                value={phoneNumber}
                className={`phone-input ${
                  phoneNumberError ? "input-error" : ""
                }`} //input-error
                styles={{
                  root: {
                    width: "264px",
                  },
                }}
              />
              {otpError && (
                <Text
                  className="error-message"
                  variant="medium"
                  style={{ textAlign: "center" }}
                >
                  {otpError}
                </Text>
              )}
              <div className="spinner-container">
                {showLoader && <Spinner />}
              </div>
              <div className="login-actions-container">
                <PrimaryButton onClick={handleRequestOtp}> OTP</PrimaryButton>
              </div>
            </div>
          </div>
        )}
        {sentOtp && (
          <div className="login-inner-container">
            <p className="app-subtitle">
              All your data is <span>encrypted</span> and safe as per the Govt
              safety regulation act
            </p>
            <div className="login-image-container" style={{ margin: "2px" }}>
              <Image height={250} src={LoginBackground2} loading="lazy" />
            </div>
            <div className="login-input-container">
              <OtpInput
                value={otp}
                isInputNum
                numInputs={6}
                hasErrored={enteredWrongOtp}
                onChange={handleOtpChange}
                shouldAutoFocus
                separator={<span style={{ width: "10px" }}></span>}
                errorStyle="otp-input-error-style"
                inputStyle="otp-input-style"
              />
              <div className="message-container">
                {sentOtp && !enteredWrongOtp && (
                  <Text variant="medium">
                    Enter the OTP sent to{" "}
                    <Text variant="mediumPlus">+91 {phoneNumber}</Text>
                  </Text>
                )}
                {enteredWrongOtp && (
                  <Text className="error-message" variant="medium">
                    Entered Wrong OTP!
                  </Text>
                )}
              </div>
              <div className="spinner-container">
                {showLoader && <Spinner />}
              </div>
              <div className="resend-container">
                {ResendOtpTimer.timer > 0 && (
                  <Text variant="medium" style={{ color: "#6C70C5" }}>
                    Resend in{" "}
                    <Text variant="mediumPlus" style={{ color: "#6C70C5" }}>
                      {formatTime(ResendOtpTimer.timer)}
                    </Text>
                  </Text>
                )}
              </div>
              <div className="login-actions-container">
                <DefaultButton
                  style={{ marginRight: "16px" }}
                  onClick={handleGoBack}
                >
                  Go Back
                </DefaultButton>
                <PrimaryButton onClick={handleRequestOtp}>Resend</PrimaryButton>
              </div>
            </div>
          </div>
        )}
      </div>
      <div id="recaptcha-container"></div>
    </div>
  );
}
