import React from "react";
import "./securityGuardDetails.scss";
import ScreenHeader from "../../../Components/ScreenHeader/ScreenHeader";
import { Text, PrimaryButton } from "@fluentui/react";
import { UserType } from "../../../types/CommonTypes/CommonTypes.types";
import { useNavigate } from "react-router-dom";

type SecurityGuardDetailsPropsType = {
  securityGuard: UserType;
};

export default function SecurityGuardDetails({
  securityGuard,
}: SecurityGuardDetailsPropsType) {
  const navigate = useNavigate();
  const handleEditBtnClick = () => {
    navigate(`/security-guards/${securityGuard?.id}/edit`);
  };
  return (
    <div className="security-guard-details">
      <ScreenHeader title="SECURITY GUARD / RECEPTIONIST DETAILS" />
      <section className="security-details-section">
        <div className="security-details-field-container">
          <Text variant="mediumPlus">Name :</Text>
          <Text variant="medium" className="field-value">
            {securityGuard?.name}
          </Text>
        </div>
        <div className="security-details-field-container">
          <Text variant="mediumPlus">Contact Number :</Text>
          <Text variant="medium" className="field-value">
            {securityGuard?.phoneNumber.substring(3)}
          </Text>
        </div>
        {securityGuard?.email && (
          <div className="security-details-field-container">
            <Text variant="mediumPlus"> Email :</Text>
            <Text variant="medium" className="field-value">
              {securityGuard?.email}
            </Text>
          </div>
        )}
        <div className="security-details-field-container">
          <Text variant="mediumPlus">Joined Date :</Text>
          <Text variant="medium" className="field-value">
            {new Date(securityGuard?.createdDate.toDate()).toDateString()}
          </Text>
        </div>
        <div className="security-details-field-container">
          <Text variant="mediumPlus"> Active :</Text>
          <Text variant="medium" className="field-value">
            {securityGuard?.isActive.toString()}
          </Text>
        </div>
      </section>
      <section className="action-button-section">
        <PrimaryButton
          iconProps={{ iconName: "Edit" }}
          styles={{
            textContainer: {
              marginLeft: "5px",
              flexGrow: "auto",
            },
          }}
          onClick={handleEditBtnClick}
        >
          Edit
        </PrimaryButton>
      </section>
    </div>
  );
}
