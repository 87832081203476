import { db } from "../firebase-config";
import {
  doc,
  getDoc,
  getDocs,
  collection,
  query,
  where,
  addDoc,
  deleteDoc,
} from "firebase/firestore";
import { InviteType } from "../types/CommonTypes/CommonTypes.types";

const invitesColRef = collection(db, "Invites");

const getInviteDetails = async (inviteId: string) => {
  const inviteDocRef = doc(db, "Invites", inviteId);
  return getDoc(inviteDocRef).then((inviteDocSnap) => {
    if (inviteDocSnap.exists()) {
      return { ...inviteDocSnap.data(), id: inviteDocSnap.id } as InviteType;
    }
    return null;
  });
};

const getRecentInvitesByPhoneNumber = async (phoneNumber: string) => {
  const currentDate = new Date().getDate();
  const oneDayLesserDate = new Date(new Date().setDate(currentDate - 1));
  const q = query(
    invitesColRef,
    where("phoneNumber", "==", phoneNumber),
    where("invitedDate", ">=", oneDayLesserDate)
  );
  return await getDocs(q).then((querySnapShot) => {
    return querySnapShot.docs.map((item) => ({
      ...item.data(),
      id: item.id,
    })) as InviteType[];
  });
};

const getAllActiveInvites = async (clientId: string) => {
  const currentDate = new Date().getDate();
  const oneDayLesserDate = new Date(new Date().setDate(currentDate - 1));
  const q = query(
    invitesColRef,
    where("clientId", "==", clientId),
    where("invitedDate", ">=", oneDayLesserDate)
  );
  return await getDocs(q).then((querySnapShot) => {
    return querySnapShot.docs.map((item) => ({
      ...item.data(),
      id: item.id,
    })) as InviteType[];
  });
};

const createInvite = async (data: InviteType) => {
  return addDoc(invitesColRef, data);
};

const deleteInvite = async (id: string) => {
  const inviteDocRef = doc(db, "Invites", id);
  return await deleteDoc(inviteDocRef);
};

export {
  getAllActiveInvites,
  getInviteDetails,
  createInvite,
  getRecentInvitesByPhoneNumber,
  deleteInvite,
};
