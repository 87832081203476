import React, { useState, useEffect, useContext } from "react";
import "./communication.scss";
import SoundWaveImage from "../../assets/images/sound-wave-image.svg";
import PlayIcon from "../../assets/images/play.svg";
import PauseIcon from "../../assets/images/pause.svg";
import SoundWaveGif from "../../assets/images/sound-wave.gif";
import SlidingButton from "../../Components/SlidingButton/SlidingButton";
import { Text, IconButton, Image, Spinner } from "@fluentui/react";
import { UserContext } from "../../context/UserContextProvider";
import { formatDate } from "../../helpers/formatDate";
import {
  getCommunicationData,
  getVisitData,
  updateVisitData,
} from "../../services/visitsService";
import {
  CommunicationObjType,
  VisitType,
} from "../../types/CommonTypes/CommonTypes.types";
import { serverTimestamp } from "firebase/firestore";
import { formatTime } from "./../../helpers/formatTime";
import relativeTime from "dayjs/plugin/relativeTime";
import calender from "dayjs/plugin/calendar";
import dayjs from "dayjs";

type AudioMessageType = {
  id: string;
  audioPlaying: boolean;
  audioPaused: boolean;
  audio: HTMLAudioElement;
  intervalId: any;
  timer: number;
};

type CommunicationPropsType = {
  visit: VisitType;
  onDismissBottomSheet: () => void;
};

export default function Communication({
  visit,
  onDismissBottomSheet,
}: CommunicationPropsType) {
  const [allCommunicationData, setAllCommunicationData] = useState(
    [] as CommunicationObjType[]
  );
  const [formattedCommunicationData, setFormattedCommunicationData] =
    useState<any>([]);
  const [visitData, setVisitData] = useState({} as VisitType);
  const [audioMessages, setAudioMessages] = useState([] as AudioMessageType[]);
  const [showDateInBadge, setShowDateInBadge] = useState(false);
  const [IsLoadingCommunicationData, setIsLoadingCommunicationData] =
    useState(false);

  const { isLoggedIn, user, loadingUser } = useContext(UserContext);

  const visitId = visit?.id;

  useEffect(() => {
    if (visitId) {
      getAllCommunicationData(visitId);
      setVisitData(visit);
    }
  }, [visitId]);

  useEffect(() => {
    if (allCommunicationData && allCommunicationData.length) {
      const audioArray = [...audioMessages];
      allCommunicationData
        .filter((communicationObj) => communicationObj.type === "audio")
        .forEach((communicationObj) => {
          getAudioElementFromSrc(communicationObj.data).then((audio) => {
            if (audio) {
              audioArray.push({
                id: communicationObj.id as string,
                audioPlaying: false,
                audioPaused: false,
                audio,
                timer: 0,
                intervalId: null,
              });
              setAudioMessages([...audioArray]);
            }
          });
        });
    }
  }, [allCommunicationData]);

  const getAudioElementFromSrc = async (src: string) => {
    return new Promise((resolve: (value: HTMLAudioElement) => void) => {
      const audio = new Audio(src);
      const handleAudioDurationChange = (audio: HTMLAudioElement) => {
        audio.currentTime = 1000000;
        if (audio.duration !== Infinity) {
          audio.removeEventListener("durationchange", () => {
            handleAudioDurationChange(audio);
          });
          audio.currentTime = 0;
          resolve(audio);
        }
      };

      audio.addEventListener("durationchange", () => {
        handleAudioDurationChange(audio);
      });
    });
  };

  const getDateLabelContent = (date: Date) => {
    dayjs.extend(relativeTime);
    dayjs.extend(calender);
    var fromNow = dayjs(date).fromNow();

    return showDateInBadge
      ? formatDate(date)
      : dayjs(date).calendar(null, {
          lastDay: "[Yesterday]",
          sameDay: "[Today]",
          lastWeek: `[${fromNow}]`,
          sameElse: `[${fromNow}]`,
        });
  };

  useEffect(() => {
    if (allCommunicationData && allCommunicationData.length) {
      const res = allCommunicationData.reduce(
        (obj: any, communicationObj: CommunicationObjType) => {
          const CommunicationObjDateString = new Date(
            communicationObj.createdOn.toDate()
          ).toDateString();
          if (obj[CommunicationObjDateString]) {
            obj[CommunicationObjDateString].push(communicationObj);
            return obj;
          }

          obj[CommunicationObjDateString] = [{ ...communicationObj }];
          return obj;
        },
        {}
      );
      setFormattedCommunicationData(res);
    }
  }, [allCommunicationData]);

  const getAllCommunicationData = (visitId: string) => {
    setIsLoadingCommunicationData(true);
    getCommunicationData(visitId)
      .then((res) => {
        if (res) {
          setAllCommunicationData(res as CommunicationObjType[]);
        }
      })
      .finally(() => setIsLoadingCommunicationData(false));
  };

  const handlePlayAudio = (id: string) => {
    const audioMessagesLocal = [...audioMessages];
    const audioMessageIndex = audioMessagesLocal?.findIndex(
      (audioMessage) => audioMessage.id === id
    );
    const otherPlayingAudioMessageIndex = audioMessagesLocal.findIndex(
      (audioMessage) => audioMessage.audioPlaying
    );
    if (otherPlayingAudioMessageIndex >= 0) {
      audioMessagesLocal[otherPlayingAudioMessageIndex].audioPlaying = false;
      audioMessagesLocal[otherPlayingAudioMessageIndex].audioPaused = true;
      setAudioMessages([...audioMessagesLocal]);
    }

    if (audioMessagesLocal[audioMessageIndex]?.audio) {
      audioMessagesLocal[audioMessageIndex].audioPlaying = true;
      audioMessagesLocal[audioMessageIndex].audioPaused = false;
      setAudioMessages([...audioMessagesLocal]);
      audioMessagesLocal[audioMessageIndex].audio.play();

      const intervalId = setInterval(() => {
        if (
          audioMessagesLocal[audioMessageIndex].audio.currentTime ===
          audioMessagesLocal[audioMessageIndex].audio.duration
        ) {
          clearInterval(intervalId);
          audioMessagesLocal[audioMessageIndex].audioPlaying = false;
          audioMessagesLocal[audioMessageIndex].audioPaused = false;
          audioMessagesLocal[audioMessageIndex].intervalId = null;
          audioMessagesLocal[audioMessageIndex].timer = 0;
          setAudioMessages([...audioMessagesLocal]);
          return;
        }

        audioMessagesLocal[audioMessageIndex].intervalId = intervalId;
        audioMessagesLocal[audioMessageIndex].timer =
          audioMessagesLocal[audioMessageIndex].audio?.currentTime;
        setAudioMessages([...audioMessagesLocal]);
      }, 1000);
    }
  };

  const handlePauseAudio = (id: string) => {
    const audioMessagesLocal = [...audioMessages];
    const audioMessageIndex = audioMessagesLocal?.findIndex(
      (audioMessage) => audioMessage.id === id
    );
    if (
      audioMessagesLocal[audioMessageIndex]?.audioPlaying &&
      audioMessagesLocal[audioMessageIndex]?.audio
    ) {
      audioMessagesLocal[audioMessageIndex]?.audio?.pause();
      audioMessagesLocal[audioMessageIndex].audioPlaying = false;
      audioMessagesLocal[audioMessageIndex].audioPaused = true;
      setAudioMessages([...audioMessagesLocal]);
      clearInterval(audioMessagesLocal[audioMessageIndex].intervalId);
    }
  };

  const getAudioControlsIcon = (id: string) => {
    const res = audioMessages?.find((audioMessage) => audioMessage.id === id);
    return res?.audioPlaying ? PauseIcon : PlayIcon;
  };

  const handleAudioPlayPause = (id: string) => {
    const res = audioMessages?.find((audioMessage) => audioMessage.id === id);
    if (res?.audioPlaying) {
      handlePauseAudio(id);
    } else {
      handlePlayAudio(id);
    }
  };

  const getFormattedMessageTime = (date: Date) => {
    const localeTimeString = date.toLocaleTimeString();
    const noon = localeTimeString.slice(-2);
    const timeArr = localeTimeString.split(" ")[0].split(":");
    return `${timeArr[0]}:${timeArr[1]} ${noon}`;
  };

  const handleDateClick = () => {
    setShowDateInBadge((prevstate: boolean) => !prevstate);
  };

  const getAudioDuration = (id: string) => {
    if (audioMessages && audioMessages.length) {
      const audioMessagesLocal = [...audioMessages];
      const audioMessageIndex = audioMessagesLocal?.findIndex(
        (audioMessage) => audioMessage.id === id
      );
      if (
        !(
          audioMessagesLocal[audioMessageIndex]?.audioPlaying ||
          audioMessagesLocal[audioMessageIndex]?.audioPaused
        ) &&
        audioMessagesLocal[audioMessageIndex]?.audio
      ) {
        return formatTime(
          Math.round(audioMessagesLocal[audioMessageIndex]?.audio?.duration)
        );
      }
      if (
        (audioMessagesLocal[audioMessageIndex]?.audioPlaying ||
          audioMessagesLocal[audioMessageIndex]?.audioPaused) &&
        audioMessagesLocal[audioMessageIndex]?.audio
      ) {
        return formatTime(
          Math.round(audioMessagesLocal[audioMessageIndex]?.timer)
        );
      }
    }
  };

  const getAudioAnimationSrc = (id: string) => {
    if (audioMessages && audioMessages.length) {
      const audioMessagesLocal = [...audioMessages];
      const audioMessageIndex = audioMessagesLocal?.findIndex(
        (audioMessage) => audioMessage.id === id
      );
      if (audioMessagesLocal[audioMessageIndex]?.audioPlaying) {
        return SoundWaveGif;
      }
      return SoundWaveImage;
    }
  };

  const handleEndVisit = () => {
    console.log("end");
    if (visitData.status === "approved") {
      updateVisitData(visitId as string, {
        visit_end_time: serverTimestamp(),
        status: "completed",
      }).then(() => onDismissBottomSheet());
    }
  };

  return (
    <div className="communication">
      <div className="screen-header">
        <Text variant="xxLarge">COMMUNICATION</Text>
      </div>
      <section className="communication-section">
        {!IsLoadingCommunicationData && (
          <>
            {formattedCommunicationData &&
              Object.keys(formattedCommunicationData).length > 0 &&
              Object.keys(formattedCommunicationData).map((key) => (
                <div
                  key={key}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-end",
                    width: "100%",
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                      marginBottom: "16px",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <div className="date-badge" onClick={handleDateClick}>
                      {getDateLabelContent(new Date(key))}
                    </div>
                  </div>
                  {formattedCommunicationData[key].length > 0 &&
                    formattedCommunicationData[key].map(
                      (communicationObj: CommunicationObjType) => (
                        <div
                          key={communicationObj.id}
                          className={`message ${communicationObj.created_person_role} `}
                        >
                          <div className="messager-details">
                            <Text variant="medium" style={{ fontWeight: 600 }}>
                              {communicationObj.created_person_name}
                            </Text>
                            <Text
                              variant="smallPlus"
                              style={{ marginLeft: "16px" }}
                            >
                              {getFormattedMessageTime(
                                new Date(communicationObj.createdOn.toDate())
                              )}
                            </Text>
                          </div>
                          <div className="message-box">
                            {communicationObj.type === "audio" &&
                              audioMessages?.length > 0 && (
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <IconButton
                                      iconProps={{
                                        imageProps: {
                                          src: getAudioControlsIcon(
                                            communicationObj.id as string
                                          ),
                                          styles: {
                                            image: {
                                              width: "24px",
                                              height: "24px",
                                            },
                                          },
                                        },
                                      }}
                                      styles={{
                                        root: {
                                          width: "24px",
                                          height: "24px",
                                        },
                                        icon: {
                                          width: "24px",
                                          height: "24px",
                                        },
                                      }}
                                      onClick={() =>
                                        handleAudioPlayPause(
                                          communicationObj.id as string
                                        )
                                      }
                                    />
                                    <div
                                      className="sound-wave-animation"
                                      style={{ paddingLeft: "8px" }}
                                    >
                                      <Image
                                        src={getAudioAnimationSrc(
                                          communicationObj.id as string
                                        )}
                                        styles={{
                                          root: {
                                            width: "180px",
                                            height: "30px",
                                          },
                                          image: {
                                            width: "180px",
                                            height: "30px",
                                          },
                                        }}
                                      />
                                    </div>
                                  </div>

                                  <Text
                                    variant="mediumPlus"
                                    style={{
                                      color: "rgba(96, 92, 92, 0.5)",
                                      paddingLeft: "24px",
                                    }}
                                  >
                                    {getAudioDuration(
                                      communicationObj.id as string
                                    )}
                                  </Text>
                                </div>
                              )}
                            {communicationObj.type === "notes" && (
                              <div style={{ wordBreak: "break-word" }}>
                                <Text variant="medium">
                                  {communicationObj.data}
                                </Text>
                              </div>
                            )}
                          </div>
                        </div>
                      )
                    )}
                </div>
              ))}
            {(visitData?.status === "approved" ||
              visitData?.status === "completed") && (
              <div className="start-end-time-container">
                <div className="start-end">
                  <Text variant="medium">Started :</Text>{" "}
                  <Text variant="mediumPlus">
                    {`${formatDate(
                      new Date(visitData?.visit_start_time?.toDate())
                    )} / ${getFormattedMessageTime(
                      new Date(visitData?.visit_start_time?.toDate())
                    )}`}
                  </Text>
                </div>
                {visitData?.status === "completed" &&
                  visitData?.visit_end_time && (
                    <div className="start-end" style={{ marginTop: "10px" }}>
                      <Text variant="medium">Ended :</Text>{" "}
                      <Text variant="mediumPlus">
                        {`${formatDate(
                          new Date(visitData?.visit_end_time?.toDate())
                        )} / ${getFormattedMessageTime(
                          new Date(visitData?.visit_end_time?.toDate())
                        )}`}
                      </Text>
                    </div>
                  )}
              </div>
            )}
          </>
        )}
        {IsLoadingCommunicationData && (
          <div
            style={{
              padding: "20px 0px",
            }}
          >
            <Spinner />
          </div>
        )}
      </section>
      {user &&
        isLoggedIn &&
        !loadingUser &&
        user?.role?.includes("security_guard") &&
        visitData?.status === "approved" && (
          <div className="action-button-container">
            <SlidingButton
              mainTextElelment={
                <Text
                  style={{
                    color: "#6C70C5",
                    fontSize: "16px",
                    fontWeight: "600",
                    marginLeft: "32px",
                  }}
                >
                  Slide to end visit
                </Text>
              }
              overlayTextElement={<Spinner />}
              onCompleteSlide={handleEndVisit}
            />
          </div>
        )}
    </div>
  );
}
